export var defaultAssemblyPageAi = {
    isShowAiSelectContainer: false,
    aiItems: {
        ai_motion: false,
        ai_color_detection: false,
        ai_color_tracking: false,
        ai_face_detection: false,
        ai_face_recognition: false,
        ai_face_tracking: false,
        ai_mic: false,
        ai_lidar: false,
        ai_LED: false,
    },
}
// export var defaultAiTrainList = {
//     speechTranList: [], //{ value: '' }
//     colorTranList: [],
//     faceTranList: [],
// }
export var aiItemsData = {
    ai_motion: {
        title: 'AI Motion',
        reduxCheck: 'ai_motion',
        type: 'input',
        min: 0,
        max: 1,
    },
    ai_color_detection: {
        title: 'AI Color Detection',
        reduxCheck: 'ai_color_detection',
        type: 'input',
        // min:0,
        // ma:1500,
    },
    ai_color_tracking_X: {
        title: 'AI Track Color Detection X',
        reduxCheck: 'ai_color_tracking',
        listKey: 'colorTranList',
        type: 'input',
        min: 0,
        max: 240,
    },
    ai_color_tracking_Y: {
        title: 'AI Track Color Detection Y',
        reduxCheck: 'ai_color_tracking',
        listKey: 'colorTranList',
        type: 'input',
        min: 0,
        max: 240,
    },
    ai_face_detection: {
        title: 'AI Face Detection',
        reduxCheck: 'ai_face_detection',
        type: 'input',
        min: 0,
        max: 1,
    },
    ai_face_recognition: {
        title: 'AI Face Recognition',
        reduxCheck: 'ai_face_recognition',
        type: 'input',
        // min:0,
        // ma:1500,
    },
    ai_face_tracking_X: {
        title: 'AI Track Face Detection X',
        reduxCheck: 'ai_face_tracking',
        listKey: 'faceTranList',
        type: 'input',
        min: 0,
        max: 240,
    },
    ai_face_tracking_Y: {
        title: 'AI Track Face Detection Y',
        reduxCheck: 'ai_face_tracking',
        listKey: 'faceTranList',
        type: 'input',
        min: 0,
        max: 240,
    },
    ai_mic: {
        title: 'AI Mic',
        reduxCheck: 'ai_mic',
        type: 'input',
        // min:0,
        // ma:1500,
    },
    ai_lidar: {
        title: 'AI Lidar',
        reduxCheck: 'ai_lidar',
        type: 'input',
        min: 0,
        max: 1500,
    },
    ai_LED: {
        title: 'AI LED',
        reduxCheck: 'ai_LED',
        type: 'output',
        min: 0,
        max: 100,
    },
}

//PA Bytes send to the board for AI
export const paBytesAi = {
    ai_mic: 'S',
    ai_lidar: 'L',
    ai_motion: 'M',
    ai_face_detection: 'F',
    ai_color_detection: 'C',
    ai_face_recognition: 'R',
    ai_face_tracking_X: 'W',
    ai_face_tracking_Y: 'W',
    ai_color_tracking_X: 'W',
    ai_color_tracking_Y: 'W',
}
