import React, { Component } from 'react'

import Colors from '../../Colors'
import { aiItemsData } from '../../../../source/assemblyPageAiData'

class Select extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    onChange = (e) => {
        this.props.onChange(e.target.value)
    }

    //for speech mood the drop down awake ward value is 101 set here
    getOptionValue = (index, optionLabel) => {
        if (this.props.aiType === 'ai_mic' && index == 1) {
            return 101
        } else return index
    }

    render() {
        let connectedDevice = sessionStorage.getItem('connectedDevice')
        var style = {
            color: '#000',
            background: '#FFF',
            outline: 'none',
            texAlign: 'center',
            fontFamily: 'Halcyon_Medium ',
            height: '100%',
            width: '100%',
            border: '1px solid white',
            borderRadius: '15px',
            cursor: 'pointer',
        }

        console.log(this.props, 'CONDITUOAN')
        if (this.props.disabled) style.background = Colors.disabledgrey
        // CHANGED NAMING OF FOUR IN SENSOR
        var customOptions = {}
        this.props.order.map((option, index) => {
            console.log('loser', option)
            if (option == 'battery') {
                customOptions[index] = 'Select Items'
            } else if (option == '4-IN-1 SENSOR  →  RED') {
                customOptions[index] = 'PC -> COLOUR SENSOR (RED)'
            } else if (option == '4-IN-1 SENSOR  →  BLUE') {
                customOptions[index] = 'PC -> COLOUR SENSOR (BLUE)'
            } else if (option == '4-IN-1 SENSOR  →  GREEN') {
                customOptions[index] = 'PC -> COLOUR SENSOR (GREEN)'
            } else if (option == '4-IN-1 SENSOR  →  DIST') {
                customOptions[index] = 'PC Distance Sensor'
            } else if (option == '4-IN-1 SENSOR  →  GESTURE') {
                customOptions[index] = 'PC Gesture Sensor'
            } else if (option == '4-IN-1 SENSOR  →  LIGHT') {
                customOptions[index] = 'PC Light Sensor'
            } else if (
                ['Switch_One', 'Switch_Two'].includes(option) &&
                ['Roboki'].includes(connectedDevice)
            ) {
                customOptions[index] = 'Roboki ' + option
            } else {
                if (this.props.options[option] != null) {
                    if (
                        option === 'C1' &&
                        ['Roboki'].includes(connectedDevice)
                    ) {
                        let removePortStr = this.props.options[option].replace(
                            / → .*/,
                            ''
                        )
                        customOptions[index] = removePortStr + ' \u2192 ' + 'B1'
                    } else if (
                        option === 'C2' &&
                        ['Roboki'].includes(connectedDevice)
                    ) {
                        let removePortStr = this.props.options[option].replace(
                            / → .*/,
                            ''
                        )
                        customOptions[index] = removePortStr + ' \u2192 ' + 'B2'
                    } else {
                        customOptions[index] = this.props.options[option]
                    }
                } else if (
                    [
                        'ai_motion',
                        'ai_color_detection',
                        'ai_face_detection',
                        'ai_face_recognition',
                        'ai_mic',
                        'ai_lidar',
                    ].includes(option)
                ) {
                    customOptions[index] = aiItemsData[option].title
                } else if (
                    option.includes('ai_face_tracking_X') ||
                    option.includes('ai_face_tracking_Y') ||
                    option.includes('ai_color_tracking_X') ||
                    option.includes('ai_color_tracking_Y')
                ) {
                    const regex = /^(ai_(color|face)_tracking_[XY])(.*?)(\d*)$/
                    const matches = option.match(regex)
                    if (matches) {
                        let optionLabel = aiItemsData[matches[1]]?.title
                        let optionLabelArr = optionLabel.split(' ')
                        optionLabelArr[2] = optionLabelArr[2] + matches[4]
                        optionLabel = optionLabelArr.join(' ')
                        customOptions[index] = optionLabel
                    }
                } else {
                    let deviceName = ''
                    switch (connectedDevice) {
                        case 'Ace':
                            deviceName = 'PC '
                            break
                        case 'Roboki':
                            deviceName = 'Roboki '
                            break
                        default:
                            deviceName = 'PC '
                    }
                    customOptions[index] = deviceName + option
                }
            }
        })
        console.log('loser', customOptions)
        if (this.props.typeDropDown == 'read') {
            return (
                <select
                    onChange={this.onChange}
                    style={style}
                    value={this.props.selected}
                    disabled={this.props.disabled}
                >
                    {this.props.order
                        ? this.props.order.map((value, index) => {
                              if (
                                  value.includes('ai_face_tracking_X') ||
                                  value.includes('ai_face_tracking_Y') ||
                                  value.includes('ai_color_tracking_X') ||
                                  value.includes('ai_color_tracking_Y')
                              ) {
                                  return
                              }
                              return (
                                  <option
                                      className="sensor-txt"
                                      key={index}
                                      value={value}
                                  >
                                      {customOptions[index]}
                                  </option>
                              )
                          })
                        : Object.keys(this.props.options).map(
                              (value, index) => (
                                  <option
                                      className="sensor-txt"
                                      key={index}
                                      value={value}
                                  >
                                      {this.props.options[value]}
                                  </option>
                              )
                          )}
                </select>
            )
        } else if (this.props.typeDropDown == 'trainList') {
            const { aiType } = this.props
            return (
                <select
                    onChange={this.onChange}
                    style={style}
                    value={this.props.selected}
                    disabled={this.props.disabled}
                >
                    {this.props.order.map((value, index) => {
                        if (!value.trim()) {
                            return
                        }
                        return (
                            <option
                                className="sensor-txt"
                                key={index}
                                value={this.getOptionValue(index, value)}
                            >
                                {value}
                            </option>
                        )
                    })}
                </select>
            )
        } else {
            return (
                <select
                    onChange={this.onChange}
                    style={style}
                    value={this.props.selected}
                    disabled={this.props.disabled}
                >
                    {this.props.order
                        ? this.props.order.map((value, index) => (
                              <option
                                  className="sensor-txt"
                                  key={index}
                                  value={value}
                              >
                                  {customOptions[index]}
                                  {/* {this.props.options[value] || value} */}
                              </option>
                          ))
                        : Object.keys(this.props.options).map(
                              (value, index) => (
                                  <option
                                      className="sensor-txt"
                                      key={index}
                                      value={value}
                                  >
                                      {this.props.options[value]}
                                  </option>
                              )
                          )}
                </select>
            )
        }
    }
}

export default Select
