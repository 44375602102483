import React, { useState } from 'react'
import { connect } from 'react-redux'
import Checkbox from '../helpers/Checkbox'
import IOComponents from '../../IOComponents'
import Select from '../../../../Components/flowchart_port/FlowchartConnections/logic/pannel/helpers/Select'
function SelectionRow(props) {
    const {
        assign,
        name,
        port,
        state,
        onChange,
        handlecheckbox,
        assembly,
        concept,
        logic,
    } = props
    var style = {
        color: '#000',
        background: '#FFF',
        margin: '0 0.5em',
        padding: '4px',
        ...props.style,
    }
    const flowchartBased =
        sessionStorage.getItem('programMode') === 'flowchartBased'
    const [flowLabel, setFlowLabel] = useState(
        JSON.parse(sessionStorage.getItem('optionLabels')) || []
    )
    /////// Donot delete clicked useState //////////////////
    const [clicked, setClicked] = useState(true)
    //////////////////Used to update the port options for OLED//////////////////////////////////////
    let sourceOptionsOrder = [],
        sourceOptions = {}
    let PortConnections = assembly.PortConnections,
        componentProps = concept.componentProps,
        internalAccessories = concept.internalaccessories,
        startState = logic.program[0].state

    sourceOptionsOrder.push('default')
    sourceOptions['default'] = 'default'

    function updateOptions(label, flow) {
        if (!label.every((val, i) => val === flow[i])) setFlowLabel(label)
    }
    Object.keys(PortConnections).forEach((port) => {
        if (port != 'undefined' && PortConnections[port]) {
            if (!PortConnections[port]) return
            var type = PortConnections[port].type
            if (type == '4_in_1_sensor' || type == 'pcAiTool') {
                //   var positionPorts = ["R", "G", "B", "D", "L", "M"];
                //   for (var i = 0; i < positionPorts.length; i++) {
                //     sourceOptionsOrder.push(positionPorts[i]);
                //     sourceOptions[positionPorts[i]] =
                //       componentProps[type].name + " \u2192 " + positionPorts[i];
                //   }
            } else {
                if (IOComponents[type].input && port.length != 1) {
                    sourceOptionsOrder.push(port)
                    sourceOptions[port] =
                        componentProps[type].name + ' \u2192 ' + port
                }
            }
        }
    })
    Object.keys(startState).forEach((port) => {
        if (
            startState[`${port}`] &&
            port != 'btRx' &&
            port != 'usbtx' &&
            port != 'btTx' &&
            port !== 'btRte'
        ) {
            console.log('gskk Selection', port)

            sourceOptionsOrder.push(port)
            sourceOptions[`${port}`] = port
        } else if (startState[`${port}`] && port == 'btRx') {
            for (let i = 1; i < 6; i++) {
                sourceOptionsOrder.push(`${port}${i}`)
                sourceOptions[`${port}${i}`] = `${port}${i}`
            }
        }
    })
    Object.keys(internalAccessories).forEach((port) => {
        var type = port
        if (type == 'Four_in_one_sensor') {
            var positionPorts = [
                'DistanceSensors',
                'GestureSensor',
                'LightSensor',
                'ColorSensor',
            ]
            for (var i = 0; i < positionPorts.length; i++) {
                if (
                    internalAccessories.Four_in_one_sensor[
                        `is${positionPorts[i]}`
                    ]
                ) {
                    if (positionPorts[i] == 'ColorSensor') {
                        sourceOptionsOrder.push('RED')
                        sourceOptions['RED'] =
                            '4_in_1_sensor' + ' \u2192 ' + 'RED'
                        sourceOptionsOrder.push('GREEN')
                        sourceOptions['GREEN'] =
                            '4_in_1_sensor' + ' \u2192 ' + 'GREEN'
                        sourceOptionsOrder.push('BLUE')
                        sourceOptions['BLUE'] =
                            '4_in_1_sensor' + ' \u2192 ' + 'BLUE'
                    } else {
                        sourceOptionsOrder.push(positionPorts[i])
                        sourceOptions[positionPorts[i]] =
                            '4_in_1_sensor' + ' \u2192 ' + positionPorts[i]
                    }
                }
            }
        } else if (
            type === 'isAccelerometer' &&
            internalAccessories[`${type}`]
        ) {
            sourceOptionsOrder.push('ACCELEROMETER_X')
            sourceOptions['isAccelerometerX'] = ' Accelerometer X'
            sourceOptionsOrder.push('ACCELEROMETER_Y')
            sourceOptions['isAccelerometerY'] = ' Accelerometer Y'
            sourceOptionsOrder.push('ACCELEROMETER_Z')
            sourceOptions['isAccelerometerZ'] = ' Accelerometer Z'
            sourceOptionsOrder.push('GYRO_X')
            sourceOptions['isGyroX'] = ' Gyro X'
            sourceOptionsOrder.push('GYRO_Y')
            sourceOptions['isGyroY'] = ' Gyro Y'
            sourceOptionsOrder.push('GYRO_Z')
            sourceOptions['isGyroZ'] = ' Gyro Z'
        } else if (type != 'isFour_in_one_sensor') {
            if (
                internalAccessories[`${type}`] &&
                type != 'isTouchZeroOutput' &&
                type != 'isTouchOneOutput' &&
                type != 'isTouchTwoOutput' &&
                type != 'isTouchThreeOutput' &&
                type != 'isbuzzer' &&
                type != 'isSmileOne' &&
                type != 'isSmileTwo' &&
                type != 'isSmileThree' &&
                type != 'isSmileFour' &&
                type != 'isEYES' &&
                type != 'isLeftEye' &&
                type != 'isRightEye'
            ) {
                sourceOptionsOrder.push(port)
                sourceOptions[port] = type.slice(2)
            }
        }
    })
    if (
        !sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
        flowchartBased
    ) {
        let internalAccessories = {
            Four_in_one_sensor: {
                isDistanceSensors:
                    JSON.parse(sessionStorage.getItem('isDistanceSensors')) ||
                    false,
                isGestureSensor:
                    JSON.parse(sessionStorage.getItem('isGestureSensor')) ||
                    false,
                isLightSensor:
                    JSON.parse(sessionStorage.getItem('isLightSensor')) ||
                    false,
                isColorSensor:
                    JSON.parse(sessionStorage.getItem('isColorSensor')) ||
                    false,
            },
            isFour_in_one_sensor: true,
            isTemperature:
                JSON.parse(sessionStorage.getItem('isTemperature')) || false,
            isMic: JSON.parse(sessionStorage.getItem('isMic')) || false,
            isTouchZero:
                JSON.parse(sessionStorage.getItem('isTouchZero')) || false,
            isTouchOne:
                JSON.parse(sessionStorage.getItem('isTouchOne')) || false,
            isTouchTwo:
                JSON.parse(sessionStorage.getItem('isTouchTwo')) || false,
        }
        Object.keys(internalAccessories).forEach((port) => {
            var type = port
            if (type == 'Four_in_one_sensor') {
                var positionPorts = [
                    'DistanceSensors',
                    'GestureSensor',
                    'LightSensor',
                    'ColorSensor',
                ]
                for (var i = 0; i < positionPorts.length; i++) {
                    if (
                        internalAccessories.Four_in_one_sensor[
                            `is${positionPorts[i]}`
                        ]
                    ) {
                        if (positionPorts[i] == 'ColorSensor') {
                            sourceOptionsOrder.push('RED')
                            sourceOptions['RED'] =
                                '4_in_1_sensor' + ' \u2192 ' + 'RED'
                            sourceOptionsOrder.push('GREEN')
                            sourceOptions['GREEN'] =
                                '4_in_1_sensor' + ' \u2192 ' + 'GREEN'
                            sourceOptionsOrder.push('BLUE')
                            sourceOptions['BLUE'] =
                                '4_in_1_sensor' + ' \u2192 ' + 'BLUE'
                        } else {
                            sourceOptionsOrder.push(positionPorts[i])
                            sourceOptions[positionPorts[i]] =
                                '4_in_1_sensor' + ' \u2192 ' + positionPorts[i]
                        }
                    }
                }
            } else if (type != 'isFour_in_one_sensor') {
                if (
                    internalAccessories[`${type}`] &&
                    type != 'isTouchZeroOutput' &&
                    type != 'isTouchOneOutput' &&
                    type != 'isTouchTwoOutput' &&
                    type != 'isTouchThreeOutput' &&
                    type != 'isbuzzer' &&
                    type != 'isSmileOne' &&
                    type != 'isSmileTwo' &&
                    type != 'isSmileThree' &&
                    type != 'isSmileFour' &&
                    type != 'isEYES' &&
                    type != 'isLeftEye' &&
                    type != 'isRightEye'
                ) {
                    sourceOptionsOrder.push(port)
                    sourceOptions[port] = type.slice(2)
                }
            }
        })
    }
    return (
        <div className={`hardwareInfo-section`}>
            <div className="portDetails-hw">
                <Checkbox
                    checked={assign || false}
                    onChange={() => {
                        handlecheckbox()
                    }}
                    label={props.label}
                    activePort={port}
                />
            </div>
            <div
                className={`portSlider-hw  isActivehardwareInfo${assign}`}
                style={{ position: 'relative' }}
            >
                {flowchartBased ? (
                    <div style={{ display: 'none' }}>
                        <Select
                            update={updateOptions}
                            flow={flowLabel}
                            componetName="flowchart"
                            selected={props.selected}
                        />
                    </div>
                ) : (
                    <></>
                )}
                <select
                    // onChange={onChange}
                    style={{
                        height: '100%',
                        width: '100%',
                        textAlign: 'center',
                        borderStyle: 'dotted',
                        borderColor: '#e0dede',
                        background: '#ebf6fa',
                        fontSize: '18px',
                    }}
                    value={props.selected}
                    onClick={(e) => {
                        if (flowchartBased) setClicked()
                    }}
                    onChange={(e) => {
                        props.handleTextChange(e)
                    }}
                >
                    {Object.keys(sourceOptions).map((value, index) => {
                        return (
                            <option key={index} value={value}>
                                {sourceOptions[value]}
                            </option>
                        )
                    })}
                    {flowchartBased ? (
                        flowLabel.map((value, index) => {
                            return (
                                <option key={index} value={value.slice(-2)}>
                                    {value}
                                </option>
                            )
                        })
                    ) : (
                        <></>
                    )}
                </select>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        PortConnections: (data) => {
            dispatch({ type: 'PORT_Connection', payload: data })
        },
    }
}
SelectionRow = connect(mapStateToProps, mapDispatchToProps)(SelectionRow)
export default SelectionRow
