import renderImage from '../../../../source/importImg'
import './AiModeFooter.css'

function AiModeFooter(props) {
    const {
        isNextShow,
        isAddShow,
        isBackShow,
        isUploadShow,
        nextClick,
        addClick,
        backClick,
        uploadClick,

        isUploadBtnDisabled,
    } = props
    return (
        <div>
            {isBackShow && (
                <img
                    src={renderImage('backBtnImg')}
                    draggable="false"
                    alt="back"
                    style={{
                        margin: 0,
                        position: 'fixed',
                        top: '92vh',
                        right: '92.7vw',
                        width: '3vw',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    // onClick={() => {
                    //     setSlideDirection(true)
                    //     sessionStorage.setItem('slideDirection', true)
                    //     if (
                    //         enable.s1.cam === true &&
                    //         enable.s1.mic === true &&
                    //         enable.s1.pc === true
                    //     ) {
                    //         if (enable.s2.face === true) {
                    //             history.push('/savedFaces')
                    //         } else if (enable.s2.emotion === true) {
                    //             history.push('/selectDetection')
                    //         } else if (enable.s2.object === true) {
                    //             history.push('/selectDetection')
                    //         }
                    //     } else if (
                    //         enable.s1.cam === true &&
                    //         enable.s1.mic === true
                    //     ) {
                    //         if (enable.s2.face === true) {
                    //             history.push('/savedFaces')
                    //         } else if (enable.s2.emotion === true) {
                    //             history.push('/selectDetection')
                    //         } else if (enable.s2.object === true) {
                    //             history.push('/selectDetection')
                    //         }
                    //     } else if (
                    //         enable.s1.cam === true &&
                    //         enable.s1.pc === true
                    //     ) {
                    //         if (enable.s2.face === true) {
                    //             history.push('/savedFaces')
                    //         } else if (enable.s2.emotion === true) {
                    //             history.push('/selectDetection')
                    //         } else if (enable.s2.object === true) {
                    //             history.push('/selectDetection')
                    //         }
                    //     } else if (
                    //         enable.s1.mic === true &&
                    //         enable.s1.pc === true
                    //     ) {
                    //         history.push('/chooseDevice')
                    //     } else if (enable.s1.cam === true) {
                    //         if (enable.s2.face === true) {
                    //             history.push('/savedFaces')
                    //         } else if (enable.s2.emotion === true) {
                    //             history.push('/selectDetection')
                    //         } else if (enable.s2.object === true) {
                    //             history.push('/selectDetection')
                    //         }
                    //     } else if (enable.s1.mic === true) {
                    //         history.push('/chooseDevice')
                    //     } else if (enable.s1.pc === true) {
                    //         history.push('/chooseDevice')
                    //     }
                    // }}
                    onClick={(e) => backClick()}
                />
            )}
            {/* Element to Move Dynamically */}
            {/* <h2
                style={{
                    position: 'relative',
                    top: '74vh',
                    // font: "Halcyon Regular",
                    fontFamily: 'Halcyon_SemiBold',
                    fontSize: '16px',
                    color: 'grey',
                    textAlign: 'center',
                }}
            >
                Tap + button to keep coding
            </h2> */}
            {isAddShow && (
                <img
                    src={renderImage('buttonPlusImg')}
                    draggable="false"
                    alt="add"
                    style={{
                        margin: 0,
                        position: 'fixed',
                        top: '92vh',
                        left: '50vw',
                        width: '3.5vw',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    onClick={(e) => addClick()}
                />
            )}
            {isUploadShow && (
                <img
                    src={renderImage('uploadButton')}
                    draggable="false"
                    alt="next"
                    style={{
                        margin: 0,
                        position: 'fixed',
                        marginBottom: 'auto',
                        top: '92vh',
                        left: '95.8vw',
                        width: '3.8vw',
                        cursor: isUploadBtnDisabled ? 'default' : 'pointer',
                        transform: 'translate(-50%, -50%)',
                        opacity: isUploadBtnDisabled ? 0.5 : 1,
                    }}
                    onClick={(e) => uploadClick()}
                />
            )}
            {isNextShow && (
                <img
                    src={renderImage('nextBtnImg')}
                    draggable="false"
                    alt="next"
                    style={{
                        margin: 0,
                        position: 'fixed',
                        marginBottom: 'auto',
                        top: '92vh',
                        left: '95.8vw',
                        width: '3vw',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    onClick={(e) => nextClick()}
                />
            )}
            {/* {popup ? <Popup trigger={popup} text={popupText} /> : ''} */}
        </div>
    )
}

export default AiModeFooter
