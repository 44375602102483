import { connect } from 'react-redux'
import SliderRow from './SliderRow'

function AiMode_output(props) {
    const { name, port, value, key, assign, onChange } = props
    return (
        <div>
            <SliderRow
                name={'AI LED'}
                port={port}
                value={value}
                key={key}
                assign={assign}
                onChange={onChange}
                // min={min}
                // max={max}
                min={0}
                max={100}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAssemblyPageAi: (data) => {
            dispatch({ type: 'ASSEMBLY_PAGE_AI', payload: data })
        },
    }
}
// export default DragSource("data", cardSource, collect1)(Sidebar);

export default connect(mapStateToProps, mapDispatchToProps)(AiMode_output)
