import MainHeader from '../ReusableComponents/Header/MainHeader'
import PrgmSelection from '../ReusableComponents/PrgmSlider/PrgmSelection/PrgmSelection'

import appMenu from '../../Assets/Bisoft_UI/AppMode/appMenu@2x.png'
import renderPrgImage from '../../source/programImg'
import { useHistory } from 'react-router-dom'

function SelectCategory() {
    const history = useHistory()

    const item1Styl = {
        backgroundImage: `url("${renderPrgImage('softwareMode')}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        pointerEvents: 'all',
        // cursor: 'pointer',

        height: '330px',
        width: '330px',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        position: 'relative',
    }
    const item2Styl = {
        backgroundImage: `url("${renderPrgImage('hardwareMode')}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        // cursor: 'pointer',

        height: '330px',
        width: '330px',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        position: 'relative',
    }

    const emptyDivStyle = {
        // backgroundColor: 'rgb(245, 211, 208, 0.5)',
        cursor: 'pointer',
        height: '174px',
        width: '193px',
        position: 'absolute',
        top: '54px',
        left: '50px',
    }

    return (
        <div>
            {/* Header */}
            <MainHeader
                help_btnPosition="right"
                helper={
                    <div
                        className="PS-S_slide"
                        style={{
                            left: '70%',
                            transform: 'translate(-50%)',
                        }}
                    >
                        <PrgmSelection />
                    </div>
                }
                goBack={() => {
                    // setSlideDirection(true)
                    // sessionStorage.setItem('slideDirection', true)
                    history.push('/Selection')
                }}
                isGoBack={true}
            />
            <img
                src={appMenu}
                style={{
                    position: 'absolute',
                    top: '8.5%',
                    left: '12%',
                    transform: 'translate(-50%,-50%)',
                    height: '22%',
                }}
            />

            <div
                style={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div style={item1Styl}>
                    <p
                        style={{
                            fontSize: '1.3rem',
                            paddingBottom: '120px',
                            paddingRight: '30px',
                        }}
                    >
                        Software
                    </p>
                    <div
                        style={emptyDivStyle}
                        onClick={() => {
                            history.push('/appMode/selection')
                        }}
                    ></div>
                </div>
                <div style={item2Styl}>
                    <p
                        style={{
                            fontSize: '1.3rem',
                            paddingBottom: '120px',
                            paddingRight: '30px',
                        }}
                    >
                        Hardware
                    </p>
                    <div
                        style={emptyDivStyle}
                        onClick={() => {
                            history.push('/appMode/selectHardware')
                        }}
                    ></div>
                </div>
            </div>
        </div>
    )
}
export default SelectCategory
