module.exports = {
    ACE1: [
        {
            type: 'led_1c',
            description:
                'Led 1c: This is a 1 channel LED, emits light when powered up, available in different colors, range value is [0 to 100]',

            color: '#15909d',
            name: 'led 1c',
            signalType: 'null',
        },
        {
            type: 'led',
            description:
                'Led 2c: This is a 2 channel LED,which emits light when powered up, available in different colors , range value is [0 to100]',
            color: '#15909d',
            name: 'led',
            signalType: 'analog',
        },
        {
            type: 'laser',
            description:
                'Laser: It emits a narrow and focussed LASER beam, which travels long distance (avoid eye contact), range value is [0 to 1]',
            color: '#15909d',
            name: 'laser',
            signalType: 'null',
        },
        {
            type: 'RGB',
            description:
                'RGB: It is a serially connectable RGB LED with individual LED controls, range value is [0 to 255]',
            color: '#15909d',
            name: 'RBG',
            signalType: 'null',
        },
        {
            type: 'OLED',
            description:
                'OLED display: This module can be used to display text or messages. upto 3 lines of text can be displayed',
            color: '#15909d',
            name: 'OLED Display',
        },
        {
            type: 'mp3',
            description:
                'Sound Card: This component helps your computer to produce and control sounds, range value is [0 to 255]',
            color: '#15909d',
            name: 'mp3',
        },
        // {
        //     type: 'geared_motor',
        //     description:
        //         'Geared Motor: It is similar to DC motor, but with gears So it has more power to pull loads but lacks speed',
        //     color: '#15909d',
        //     name: 'geared motor',
        // },
        {
            type: 'mini_geared_motor',
            description:
                'Mini Geared Motor: It is similar to Geared motor, smaller in size and efficient but with metal gears, range value is [-100 to 100]',
            color: '#15909d',
            name: 'mini geared motor',
        },
        {
            type: 'dc_motor',
            description:
                'DC Motor: It rotates when you power it up, speed and direction of rotation can be varied, range value is [-100 to 100]',
            color: '#15909d',
            name: 'dc motor',
        },
        {
            type: 'dynamex_motor',
            description:
                'Dynamex Motor: It rotates when you power it up, speed and direction of rotation can be varied, range value is [-100 to 100]',
            color: '#15909d',
            name: 'dynamex motor',
        },
        {
            type: 'servo_motor',
            description:
                'Servo Motor 180: It is a motor with angular control, you can control its rotation from 0 to 180 degree, range value is [0 to 180]',
            color: '#15909d',
            name: 'servo motor',
        },
        {
            type: 'servo_motor_270',
            description:
                'Servo Motor 270: It is a motor with angular control, you can control its rotation from 0 to 270 degree, range value is [0 to 270]',
            color: '#15909d',
            name: 'servo motor',
        },
        {
            type: 'servo_motor_360',
            description:
                'Servo Motor 360: It is a continous rotation motor, You can control its rotation in both direction, range value is [-100 to 100]',
            color: '#15909d',
            name: 'servo motor 360',
        },
        {
            type: 'dual_splitter',
            description:
                'Motor Bridge: This module is used to control DC motor or two Servo motors. It also splits a dual line port into two single line port, range value is [0 to 255]',
            color: '#15909d',
            name: 'dual splitter',
        },
        // {
        //     type: 'arm_shield',
        //     description:
        //         'Arm Shield: This module is an extender for robotic arm to connect digital servo motor and other accessories',
        //     color: '#15909d',
        //     name: 'Arm Shield',
        // },
        {
            type: 'play_shield_v1',
            description:
                'Play Shield: This module is an extender to enable to use of  more I/O lines of the Play Computer.',
            color: '#15909d',
            name: 'PlayShield',
            signalType: 'analog',
        },
        {
            type: 'peeCee_ATR',
            description:
                'PeeCee ATR: This module is an extender for Play Computer to connect digital servo motor and other accessories',
            color: '#15909d',
            name: 'PeeCee ATR',
        },
        {
            type: 'tact_switch',
            description:
                'Button 1c: It is a simple tactile switch used for toggle operation, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Button',
            signalType: 'analog',
        },

        {
            type: 'tact_switch_2c',
            description:
                'Button 2c: It is a simple tactile switch used for toggle operation, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Button 2c',
        },
        {
            type: 'dip_switch',
            description:
                'DIP Switch: It is an input switch module with 2 slide switches for setting On/Off , range value is [0 to 1] ',
            color: '#ff8c19',
            name: 'DIP switch',
            signalType: 'digital',
        },
        {
            type: 'dual_switch',
            description:
                'Dual Switch: It is simple On-Off Switch, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'dual switch',
            signalType: 'analog',
        },

        // {
        //     type: 'touch_sensor',
        //     description:
        //         'Touch Sensor: It is a touch enabled switch that works on capacitive touch interface',
        //     color: '#ff8c19',
        //     name: 'touch sensor',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'metal_detector',
        //     description:
        //         'Metal Sensor: This module can be used to detect metal objects around it. it detects at within 5cms range from the coil.',
        //     color: '#ff8c19',
        //     name: 'Metal detector',
        // },
        {
            type: 'pot',
            description:
                'Pot: It is a Pot sensor used for sensing amount of rotation , range value is [0 to 4095]',
            color: '#15909d',
            name: 'Pot',
            signalType: 'digital',
        },

        {
            type: 'light_sensor',
            description:
                'Light Sensor: It is used to measure various levels of visible light intensity(even darkness), range value is [0 to 225]',
            color: '#ff8c19',
            name: 'light sensor',
            signalType: 'analog',
        },
        {
            type: 'distance_sensor',
            description:
                'Range Sensor: It is an IR sensor used to measure the distance of the object from it (max range 3-6cm), range value is [0 to 4095]',
            color: '#ff8c19',
            name: 'distance sensor',
            signalType: 'analog',
        },
        {
            type: 'joystick',
            description:
                'Joystick: A 2-axis joystick sensor, range value is [0 to 4095]',
            color: '#15909d',
            name: 'joystick',
            signalType: 'analog',
        },
        // {
        //     type: 'temperature_sensor',
        //     description:
        //         'Temperature: This module can be used to detect temperature changes both hot and cold.',
        //     color: '#15909d',
        //     name: 'Temp',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'humidity',
        //     description:
        //         'Humidity: This module can detect increase in humidity in the atmosphere.',
        //     color: '#15909d',
        //     name: 'humidity',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'gas',
        //     description:
        //         'Gas: This module can detect alcohol, VOC in air, smoke and natural gas in air.',
        //     color: '#15909d',
        //     name: 'Gas',
        //     signalType: 'analog',
        // },
        {
            type: 'rain_sensor',
            description:
                'Rain Sensor: It is used to detect water drops on the sensor. Can also act as a bare hand touch sensor, range value is [0 to 4095]',
            color: '#15909d',
            name: 'Rain Sensor',
            signalType: 'analog',
        },
        // {
        //     type: 'linear_pot',
        //     description:
        //         'Linear pot: This is a linear motion detection sensor.',
        //     color: '#15909d',
        //     name: 'Linear Pot',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'extender',
        //     description:
        //         'Extender: This module can be used to connect any other 3v to 5v external sensor to the PC',
        //     color: '#15909d',
        //     name: 'Extender',
        //     signalType: 'analog',
        // },

        {
            type: 'ultrasonic_sensor',
            description:
                'Ultrasonic Sensor: It is an acoustic sensor used to measure distance of the object from it (range 0-400cm), range value is [0 to 400]',
            color: '#ff8c19',
            name: 'ultrasonic sensor',
            signalType: 'analog',
        },
        {
            type: 'pcAiTool',
            description:
                'AI-1: This device uses AI to recognize faces, track colors, detect motion, understand speech and measure distance.',
            color: '#15909d',
            name: 'AI-1',
        },
    ],
    ACE0: [
        {
            type: 'led_1c',
            description:
                'Led 1c : This is a 1 channel LED, emits light when powered up, available in different colors, range value is [0 to 100]',
            color: '#15909d',
            name: 'led 1c',
            signalType: 'null',
        },
        {
            type: 'led',
            description:
                'Led 2c: This is a 2 channel LED,which emits light when powered up, available in different colors , range value is [0 to 100]',
            color: '#15909d',
            name: 'led',
            signalType: 'analog',
        },
        {
            type: 'laser',
            description:
                'Laser: It emits a narrow and focussed LASER beam, which travels long distance (avoid eye contact), range value is [0 to 1]',
            color: '#15909d',
            name: 'laser',
            signalType: 'null',
        },
        {
            type: 'RGB',
            description:
                'RGB: It is a serially connectable RGB LED with individual LED controls, range value is [0 to 100]',
            color: '#15909d',
            name: 'RBG',
            signalType: 'null',
        },
        // laxmi
        // removed mp3 from external accessories
        // {
        //   type: "serial_servo_motor",
        //   description:
        //     "Serial Servo Motor: It is a digital servo motor that can be controlled in both directions",
        //   color: "#15909d",
        //   name: "Serial Servo Motor",
        // },
        // {
        // 	type: "speaker",
        // 	description:
        // 		"Speaker: This is used to play mp3 songs when connected to arm shield",
        // 	color: "#15909d",
        // 	name: "Speaker",
        // },
        {
            type: 'dual_splitter',
            description:
                'Motor Bridge: This module is used to control DC motor or two Servo motors. It also splits a dual line port into two single line port.',
            color: '#15909d',
            name: 'dual splitter',
        },
        // {
        //     type: 'arm_shield',
        //     description:
        //         'Arm Shield: This module is an extender for robotic arm to connect digital servo motor and other accessories',
        //     color: '#15909d',
        //     name: 'Arm Shield',
        // },
        // {
        //     type: 'pc_motor_driver',
        //     description:
        //         'Motor Driver: Splits a single two channel port into two single channel port',
        //     color: '#15909d',
        //     name: 'dual splitter',
        // },
        // {
        //     type: 'tact_switch',
        //     description:
        //         'Button 1c: It is a simple tactile switch used for toggle operation',
        //     color: '#ff8c19',
        //     name: 'Button',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'dip_switch',
        //     description:
        //         'DIP Switch: It is an input switch module with 2 slide switches for setting On/Off. ',
        //     color: '#ff8c19',
        //     name: 'DIP switch',
        //     signalType: 'digital',
        // },
        // {
        //   type: "tact_switch_2c",
        //   description:
        //     "Button 2c: It is a simple tactile switch used for toggle operation",
        //   color: "#ff8c19",
        //   name: "Button 2c",
        // },

        {
            type: 'servo_motor',
            description:
                'Servo Motor 180: It is a motor with angular control, you can control its rotation from 0 to 180 degree, range value is [0 to 180]',
            color: '#15909d',
            name: 'servo motor',
        },
        {
            type: 'servo_motor_270',
            description:
                'Servo Motor 270: It is a motor with angular control, you can control its rotation from 0 to 270 degree, range value is [0 to 270]',
            color: '#15909d',
            name: 'servo motor',
        },

        {
            type: 'servo_motor_360',
            description:
                'Servo Motor 360: It is a continous rotation motor, You can control its rotation in both direction, range value is [-100 to 100]',
            color: '#15909d',
            name: 'servo motor 360',
        },

        {
            type: 'serial_servo_motor',
            description:
                'Serial Servo Motor: It is a digital servo motor that can be controlled in both directions, range value is [0 to 4096]',
            color: '#15909d',
            name: 'Serial Servo Motor',
        },
        {
            type: 'speaker',
            description:
                'Speaker: This is used to play mp3 songs when connected to arm shield, range value is [0 to 255]',
            color: '#15909d',
            name: 'Speaker',
        },
        {
            type: 'play_shield',
            description:
                'Play Shield: This module is an extender to enable the use of more I/O lines of the Play Computer',
            color: '#15909d',
            name: 'Play Shield',
        },
        {
            type: 'peeCee_ATR',
            description:
                'PeeCee ATR: This module is an extender for Play Computer to connect digital servo motor and other accessories',
            color: '#15909d',
            name: 'PeeCee ATR',
        },
        {
            type: 'arm_shield',
            description:
                'Arm Shield: This module is an extender for robotic arm to connect digital servo motor and other accessories',
            color: '#15909d',
            name: 'Arm Shield',
        },
        // {
        //   type: "pc_motor_driver",
        //   description:
        //     "Motor Driver: Splits a single two channel port into two single channel port",
        //   color: "#15909d",
        //   name: "dual splitter",
        // },

        {
            type: 'tact_switch',
            description:
                'Button 1c: It is a simple tactile switch used for toggle operation, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Button',
            signalType: 'analog',
        },
        {
            type: 'dip_switch',
            description:
                'DIP Switch: It is an input switch module with 2 slide switches for setting On/Off , range value is [0 to 1] ',
            color: '#ff8c19',
            name: 'DIP switch',
            signalType: 'digital',
        },
        // {
        //   type: "tact_switch_2c",
        //   description:
        //     "Button 2c: It is a simple tactile switch used for toggle operation",
        //   color: "#ff8c19",
        //   name: "Button 2c",
        // },

        {
            type: 'dual_switch',
            description:
                'Dual Switch: It is simple On-Off Switch, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'dual switch',
            signalType: 'analog',
        },
        {
            type: 'touch_sensor',
            description:
                'Touch Sensor: It is a touch enabled switch that works on capacitive touch interface, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'touch sensor',
            signalType: 'analog',
        },
        {
            type: 'metal_detector',
            description:
                'Metal Sensor: This module can be used to detect metal objects around it. it detects at within 5cms range from the coil, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Metal detector',
        },
        // {
        //   type: "rotatory",
        //   description:
        //     "Rotatory: It is a Rotatory sensor used for sensing amount of rotation",
        //   color: "#15909d",
        //   name: "Rotatory",
        //   signalType: "analog",
        // },

        {
            type: 'pot',
            description:
                'Pot: It is a Pot sensor used for sensing amount of rotation , range value is [0 to 1024]',
            color: '#15909d',
            name: 'Pot',
            signalType: 'digital',
        },

        {
            type: 'light_sensor',
            description:
                'Light Sensor: It is used to measure various levels of visible light intensity(even darkness), range value is [0 to 1024]',
            color: '#ff8c19',
            name: 'light sensor',
            signalType: 'analog',
        },
        {
            type: 'distance_sensor',
            description:
                'Range Sensor: It is an IR sensor used to measure the distance of the object from it (max range 3-6cm), range value is [0 to 1024]',
            color: '#ff8c19',
            name: 'distance sensor',
            signalType: 'analog',
        },
        {
            type: 'joystick',
            description:
                'Joystick: A 2-axis joystick sensor, range value is [0 to 1024]',
            color: '#15909d',
            name: 'joystick',
            signalType: 'analog',
        },
        {
            type: 'temperature_sensor',
            description:
                'Temperature: This module can be used to detect temperature changes both hot and cold, range value is [0 to 1024]',
            color: '#15909d',
            name: 'Temp',
            signalType: 'analog',
        },
        {
            type: 'humidity',
            description:
                'Humidity: This module can detect increase in humidity in the atmosphere, range value is [0 to 1024]',
            color: '#15909d',
            name: 'humidity',
            signalType: 'analog',
        },
        {
            type: 'gas',
            description:
                'Gas: This module can detect alcohol, VOC in air, smoke and natural gas in air, range value is [0 to 1024]',
            color: '#15909d',
            name: 'Gas',
            signalType: 'analog',
        },

        {
            type: 'rain_sensor',
            description:
                'Rain Sensor: It is used to detect water drops on the sensor. Can also act as a bare hand touch sensor, range value is [0 to 1024]',
            color: '#15909d',
            name: 'Rain Sensor',
            signalType: 'analog',
        },
        {
            type: 'linear_pot',
            description:
                'Linear pot: This is a linear motion detection sensor, range value is [0 to 1024]',
            color: '#15909d',
            name: 'Linear Pot',
            signalType: 'analog',
        },
        {
            type: 'extender',
            description:
                'Extender: This module can be used to connect any other 3v to 5v external sensor to the PC, range value is [0 to 1024]',
            color: '#15909d',
            name: 'Extender',
            signalType: 'analog',
        },
        {
            type: 'ultrasonic_sensor',
            description:
                'Ultrasonic Sensor: It is an acoustic sensor used to measure distance of the object from it (range 0-400cm), range value is [0 to 400]',
            color: '#ff8c19',
            name: 'ultrasonic sensor',
            signalType: 'analog',
        },

        // {
        //   type: "6-axis",
        //   description: "6-axis description",
        //   color: "#15909d",
        //   name: "6 axis",
        //   signalType: "analog",
        // },
        // {
        //   type: "general",
        //   description: "general sensors description",
        //   color: "#15909d",
        //   name: "General Sensor",
        //   signalType: "analog",
        // },
        // {
        //   type: "play_shield",
        //   description: "Play Shield description goes here",
        //   color: "#15909d",
        //   name: "Play Shield",
        //   signalType: "null",
        // },
        // NEW UI DATA LASER,LED,RANGE,9-Axis,Rotatory,touchSwitch,Temp-dew,button,light,temp-gas,ultrasonice,RGBled,DOTmatrix

        // {
        //   type: "9Axis",
        //   description: "A triple axis Accelerometer + Gyro sensor",
        //   color: "#15909d",
        //   name: "9 Axis",
        // },

        // {
        //   type: "dot_matrix",
        //   description:
        //     "It's a 2 dimensional LED array(8x8) used to display text and symbols. Can be serially connected",
        //   color: "#15909d",
        //   name: "dot matrix",
        // },
        // {
        //   type: "2channel_relay",
        //   description: "A 2 channel RELAY unit, for controlling AC applicances",
        //   color: "#15909d",
        //   name: "2 channel relay",
        // },

        // {
        //   type: "stepper_motor",
        //   description: "A Motor which rotates in steps of 1 degree each",
        //   color: "#15909d",
        //   name: "stepper motor",
        // },

        //
        {
            type: 'OLED',
            description:
                'OLED display: This module can be used to display text or messages. upto 3 lines of text can be displayed',
            color: '#15909d',
            name: 'OLED Display',
        },
        {
            type: 'geared_motor',
            description:
                'Geared Motor: It is similar to DC motor, but with gears So it has more power to pull loads but lacks speed, range value is [-100 to 100]',
            color: '#15909d',
            name: 'geared motor',
        },
        {
            type: 'mini_geared_motor',
            description:
                'Mini Geared Motor: It is similar to Geared motor, smaller in size and efficient but with metal gears, range value is [-100 to 100]',
            color: '#15909d',
            name: 'mini geared motor',
        },

        {
            type: 'dc_motor',
            description:
                'DC Motor: It rotates when you power it up, speed and direction of rotation can be varied, range value is [-100 to 100]',
            color: '#15909d',
            name: 'dc motor',
        },

        // {
        //   type: "stepper_motor",
        //   description: "A Motor which rotates in steps of 1 degree each",
        //   color: "#15909d",
        //   name: "stepper motor",
        // },

        // {
        //   type: "pc_motor_driver",
        //   description: "It is motor driver for running DC motor",
        //   color: "#15909d",
        //   name: "motor driver",
        // },

        // { type: 'beeper', description: 'It emits sharp intensity tones, used for alarms', color: '#15909d', name: 'beeper' },
        // {
        //   type: "servo_extender",
        //   description:
        //     "It is an accessory used to connect servo motor to BIBOX board",
        //   color: "#15909d",
        //   name: "servo extender",
        // },
        // { type: '4_CH_relay', description: 'A 4 channel RELAY unit, for controlling AC applicances', color: '#15909d', name: '4 CH relay' },
        //{type: '4channel_relay', description: 'An electrically operated switch that can control high voltage devices',color: '#30A8AD',name: '4 channel relay'},
        // { type: 'electromagnet', description: 'Can turn into a magnet when electrified', color: '#15909d', name: 'electromagnet' },
        //{type: 'led_strip', description: 'An array of 8 lights stacked one after another',color: '#30A8AD',name: 'led strip'},
        // { type: 'dot_matrix', description: 'It\'s a 2 dimensional LED array(8x8) used to display text and symbols. Can be serially connected', color: '#15909d', name: 'dot matrix' },
        // { type: 'mp3', description: 'Play Mp3 songs', color: '#15909d', name: 'mp3' },

        //   { type: 'motor_driver', description: 'It is motor driver for running DC motor', color: '#15909d', name: 'motor driver' },

        // {
        //   type: "7segment_display",
        //   description:
        //     "It is used to display numbers from 0 to 9 and some alphabets",
        //   color: "#15909d",
        //   name: "7 segment display",
        // },

        // {
        //   type: "octa_splitter",
        //   description:
        //     "Converts B-C ports from pin type connector to audio jack type connector",
        //   color: "#15909d",
        //   name: "octa splitter",
        // },

        // {
        //   type: "heartbeat_sensor",
        //   description: "It detects heart beat through one's fingertip",
        //   color: "#ff8c19",
        //   name: "heartbeat_sensor",
        // },

        // { type: 'pir_sensor', description: 'This sensor that can be used as a motion detector', color: '#ff8c19', name: 'pir sensor' },
        // { type: 'joystick', description: 'A 2-axis joystick sensor', color: '#ff8c19', name: 'joystick' },

        //   type: "light_sensor",
        //   description:
        //     "It is used to measure various levels of visible light intensity  (even darkness)",
        //   color: "#ff8c19",
        //   name: "light sensor",
        // },

        // {
        //   type: "temperature_sensor",
        //   description:
        //     "It is used to measure various levels of heat/temperature (even cold) in its environment",
        //   color: "#ff8c19",
        //   name: "temperature sensor",
        // },
        // {
        //   type: "sound_sensor",
        //   description:
        //     "It is used to detect variations in sound levels (even air blowing/wind)",
        //   color: "#ff8c19",
        //   name: "sound sensor",
        // },
        // {
        //   type: "rain_sensor",
        //   description:
        //     "It is used to detect water drops on the sensor. Can also act as a bare hand touch sensor",
        //   color: "#ff8c19",
        //   name: "rain sensor",
        // },
        // {
        //   type: "gas_sensor",
        //   description:
        //     "It is used to detect variations in Alcohol and Carbon Monoxide levels in its environment",
        //   color: "#ff8c19",
        //   name: "gas sensor",
        // },
        // {
        //   type: "bend_sensor",
        //   description:
        //     "It is used to detect the bending angle or force/pressure applied on it",
        //   color: "#ff8c19",
        //   name: "bend sensor",
        // },
        // {
        //   type: "humidity_sensor",
        //   description:
        //     "It is used to detect the variations in water vapour(humidity) content in air",
        //   color: "#ff8c19",
        //   name: "humidity sensor",
        // },
        // { type: 'gyro_sensor', description: 'A triple axis Accelerometer + Gyro sensor', color: '#ff8c19', name: 'gyro sensor' },
        // { type: 'compass', description: '3-Axis Digital Compass', color: '#ff8c19', name: 'compass' },
        // {
        //   type: "hall_sensor",
        //   description: "It measures the magnetic field of an object",
        //   color: "#ff8c19",
        //   name: "hall_sensor",
        // },

        //  { type: '4_in_1_sensor', description: 'A special sensor, that can detect, gesture, colour, light intensity and distance from object', color: '#ff8c19', name: '4 in 1 sensor' },

        // { type: 'rotational_sensor', description: 'This can be used to determine the amount of rotation applied', color: '#ff8c19', name: 'rotational sensor' },
        // { type: 'accelerometer', description: 'This can determine acceleration/tilt angle in the x, y, z planes', color: '#ff8c19', name: 'accelerometer' },
        // { type: 'color_sensor', description: 'It is used to detect variations in 3 Primary colors of RED,GREEN and BLUE', color: '#ff8c19', name: 'color sensor' },
        // { type: 'gesture_sensor', description: 'A special sensor, that can detect, gesture, colour, light intensity and distance from object', color: '#ff8c19', name: 'gesture sensor' },
        // { type: 'rfid', description: 'It detects RFID cards and their values', color: '#ff8c19', name: 'rfid' },
        // { type: 'solar_panel', description: 'Measures the intensity of sunlight falling in the solar panel. ', color: '#ff8c19', name: 'solar panel' },
        /*{type: 'battery', description: 'Rechargeable power source for the BIBOX',color:'#DA1B1B',name: 'battery'},*/
    ],
    HUMANOID1: [
        {
            type: 'led_1c',
            description:
                'Led 1c: This is a 1 channel LED, emits light when powered up, available in different colors, range value is [0 to 100]',
            color: '#15909d',
            name: 'led 1c',
            signalType: 'null',
        },
        {
            type: 'led',
            description:
                'Led 2c: This is a 2 channel LED,which emits light when powered up, available in different colors, range value is [0 to 100]',
            color: '#15909d',
            name: 'led',
            signalType: 'analog',
        },
        {
            type: 'laser',
            description:
                'Laser: It emits a narrow and focussed LASER beam, which travels long distance (avoid eye contact), range value is [0 to 1]',
            color: '#15909d',
            name: 'laser',
            signalType: 'null',
        },
        // {
        //     type: 'RGB',
        //     description:
        //         'RGB: It is a serially connectable RGB LED with individual LED controls, range value is [0 to 255]',
        //     color: '#15909d',
        //     name: 'RBG',
        //     signalType: 'null',
        // },
        // {
        //     type: 'OLED',
        //     description:
        //         'OLED display: This module can be used to display text or messages. upto 3 lines of text can be displayed',
        //     color: '#15909d',
        //     name: 'OLED Display',
        // },
        {
            type: 'dc_motor',
            description:
                'DC Motor: It rotates when you power it up, speed and direction of rotation can be varied, range value is [-100 to 100]',
            color: '#15909d',
            name: 'dc motor',
        },
        // {
        //     type: 'geared_motor',
        //     description:
        //         'Geared Motor: It is similar to DC motor, but with gears So it has more power to pull loads but lacks speed',
        //     color: '#15909d',
        //     name: 'geared motor',
        // },
        {
            type: 'mini_geared_motor',
            description:
                'Mini Geared Motor: It is similar to Geared motor, smaller in size and efficient but with metal gears, range value is [-100 to 100]',
            color: '#15909d',
            name: 'mini geared motor',
        },
        {
            type: 'servo_motor',
            description:
                'Servo Motor 180: It is a motor with angular control, you can control its rotation from 0 to 180 degree, range value is [0 to 180]',
            color: '#15909d',
            name: 'servo motor',
        },
        {
            type: 'servo_motor_270',
            description:
                'Servo Motor 270: It is a motor with angular control, you can control its rotation from 0 to 270 degree, range value is [0 to 270]',
            color: '#15909d',
            name: 'servo motor',
        },

        {
            type: 'servo_motor_360',
            description:
                'Servo Motor 360: It is a continous rotation motor, You can control its rotation in both direction, range value is [-100 to 100]',
            color: '#15909d',
            name: 'servo motor 360',
        },
        {
            type: 'dual_splitter',
            description:
                'Motor Bridge: This module is used to control DC motor or two Servo motors. It also splits a dual line port into two single line port.',
            color: '#15909d',
            name: 'dual splitter',
        },

        {
            type: 'tact_switch',
            description:
                'Button 1c: It is a simple tactile switch used for toggle operation, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Button',
            signalType: 'analog',
        },
        {
            type: 'tact_switch_2c',
            description:
                'Button 2c: It is a simple tactile switch used for toggle operation, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Button 2c',
        },
        {
            type: 'dip_switch',
            description:
                'DIP Switch: It is an input switch module with 2 slide switches for setting On/Off , range value is [0 to 1] ',
            color: '#ff8c19',
            name: 'DIP switch',
            signalType: 'digital',
        },

        {
            type: 'dual_switch',
            description:
                'Dual Switch: It is simple On-Off Switch, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'dual switch',
            signalType: 'analog',
        },
        // {
        //     type: 'touch_sensor',
        //     description:
        //         'Touch Sensor: It is a touch enabled switch that works on capacitive touch interface, range value is [0 to 1000]',
        //     color: '#ff8c19',
        //     name: 'touch sensor',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'metal_detector',
        //     description:
        //         'Metal Sensor: This module can be used to detect metal objects around it. it detects at within 5cms range from the coil, range value is [0 to 1]',
        //     color: '#ff8c19',
        //     name: 'Metal detector',
        // },

        {
            type: 'pot',
            description:
                'Pot: It is a Pot sensor used for sensing amount of rotation , range value is [0 to 1024]',
            color: '#15909d',
            name: 'Pot',
            signalType: 'digital',
        },

        {
            type: 'light_sensor',
            description:
                'Light Sensor: It is used to measure various levels of visible light intensity(even darkness), range value is [0 to 1024]',
            color: '#ff8c19',
            name: 'light sensor',
            signalType: 'analog',
        },
        {
            type: 'distance_sensor',
            description:
                'Range Sensor: It is an IR sensor used to measure the distance of the object from it (max range 3-6cm), range value is [0 to 1024]',
            color: '#ff8c19',
            name: 'distance sensor',
            signalType: 'analog',
        },

        {
            type: 'joystick',
            description:
                'Joystick: A 2-axis joystick sensor, range value is [0 to 1024]',
            color: '#15909d',
            name: 'joystick',
            signalType: 'analog',
        },
        // {
        //     type: 'temperature_sensor',
        //     description:
        //         'Temperature: This module can be used to detect temperature changes both hot and cold, range value is [0 to 1]',
        //     color: '#15909d',
        //     name: 'Temp',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'humidity',
        //     description:
        //         'Humidity: This module can detect increase in humidity in the atmosphere, range value is [0 to 1024]',
        //     color: '#15909d',
        //     name: 'humidity',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'gas',
        //     description:
        //         'Gas: This module can detect alcohol, VOC in air, smoke and natural gas in air, range value is [0 to 1]',
        //     color: '#15909d',
        //     name: 'Gas',
        //     signalType: 'analog',
        // },

        {
            type: 'rain_sensor',
            description:
                'Rain Sensor: It is used to detect water drops on the sensor. Can also act as a bare hand touch sensor, range value is [0 to 1024]',
            color: '#15909d',
            name: 'Rain Sensor',
            signalType: 'analog',
        },
        {
            type: 'linear_pot',
            description:
                'Linear pot: This is a linear motion detection sensor, range value is [0 to 1024]',
            color: '#15909d',
            name: 'Linear Pot',
            signalType: 'analog',
        },
        // {
        //     type: 'extender',
        //     description:
        //         'Extender: This module can be used to connect any other 3v to 5v external sensor to the PC, range value is [0 to 1]',
        //     color: '#15909d',
        //     name: 'Extender',
        //     signalType: 'analog',
        // },
        {
            type: 'ultrasonic_sensor',
            description:
                'Ultrasonic Sensor: It is an acoustic sensor used to measure distance of the object from it (range 0-400cm), range value is [0 to 400]',
            color: '#ff8c19',
            name: 'ultrasonic sensor',
            signalType: 'analog',
        },
    ],
    HUMANOID2: [
        {
            type: 'led_1c',
            description:
                'Led 1c: This is a 1 channel LED, emits light when powered up, available in different colors, range value is [0 to 100]',
            color: '#15909d',
            name: 'led 1c',
            signalType: 'null',
        },
        {
            type: 'led',
            description:
                'Led 2c: This is a 2 channel LED,which emits light when powered up, available in different colors, range value is [0 to 100]',
            color: '#15909d',
            name: 'led',
            signalType: 'analog',
        },
        {
            type: 'laser',
            description:
                'Laser: It emits a narrow and focussed LASER beam, which travels long distance (avoid eye contact), range value is [0 to 1]',
            color: '#15909d',
            name: 'laser',
            signalType: 'null',
        },
        // {
        //     type: 'RGB',
        //     description:
        //         'RGB: It is a serially connectable RGB LED with individual LED controls, range value is [0 to 255]',
        //     color: '#15909d',
        //     name: 'RBG',
        //     signalType: 'null',
        // },
        // {
        //     type: 'OLED',
        //     description:
        //         'OLED display: This module can be used to display text or messages. upto 3 lines of text can be displayed',
        //     color: '#15909d',
        //     name: 'OLED Display',
        // },
        {
            type: 'dc_motor',
            description:
                'DC Motor: It rotates when you power it up, speed and direction of rotation can be varied, range value is [-100 to 100]',
            color: '#15909d',
            name: 'dc motor',
        },
        // {
        //     type: 'geared_motor',
        //     description:
        //         'Geared Motor: It is similar to DC motor, but with gears So it has more power to pull loads but lacks speed',
        //     color: '#15909d',
        //     name: 'geared motor',
        // },
        {
            type: 'mini_geared_motor',
            description:
                'Mini Geared Motor: It is similar to Geared motor, smaller in size and efficient but with metal gears, range value is [-100 to 100]',
            color: '#15909d',
            name: 'mini geared motor',
        },
        {
            type: 'servo_motor',
            description:
                'Servo Motor 180: It is a motor with angular control, you can control its rotation from 0 to 180 degree, range value is [0 to 180]',
            color: '#15909d',
            name: 'servo motor',
        },
        {
            type: 'servo_motor_270',
            description:
                'Servo Motor 270: It is a motor with angular control, you can control its rotation from 0 to 270 degree, range value is [0 to 270]',
            color: '#15909d',
            name: 'servo motor',
        },

        {
            type: 'servo_motor_360',
            description:
                'Servo Motor 360: It is a continous rotation motor, You can control its rotation in both direction, range value is [-100 to 100]',
            color: '#15909d',
            name: 'servo motor 360',
        },
        {
            type: 'dual_splitter',
            description:
                'Motor Bridge: This module is used to control DC motor or two Servo motors. It also splits a dual line port into two single line port.',
            color: '#15909d',
            name: 'dual splitter',
        },

        {
            type: 'tact_switch',
            description:
                'Button 1c: It is a simple tactile switch used for toggle operation, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Button',
            signalType: 'analog',
        },
        {
            type: 'tact_switch_2c',
            description:
                'Button 2c: It is a simple tactile switch used for toggle operation, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Button 2c',
        },
        {
            type: 'dip_switch',
            description:
                'DIP Switch: It is an input switch module with 2 slide switches for setting On/Off , range value is [0 to 1] ',
            color: '#ff8c19',
            name: 'DIP switch',
            signalType: 'digital',
        },

        {
            type: 'dual_switch',
            description:
                'Dual Switch: It is simple On-Off Switch, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'dual switch',
            signalType: 'analog',
        },
        // {
        //     type: 'touch_sensor',
        //     description:
        //         'Touch Sensor: It is a touch enabled switch that works on capacitive touch interface, range value is [0 to 1000]',
        //     color: '#ff8c19',
        //     name: 'touch sensor',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'metal_detector',
        //     description:
        //         'Metal Sensor: This module can be used to detect metal objects around it. it detects at within 5cms range from the coil, range value is [0 to 1]',
        //     color: '#ff8c19',
        //     name: 'Metal detector',
        // },

        {
            type: 'pot',
            description:
                'Pot: It is a Pot sensor used for sensing amount of rotation , range value is [0 to 1024]',
            color: '#15909d',
            name: 'Pot',
            signalType: 'digital',
        },

        {
            type: 'light_sensor',
            description:
                'Light Sensor: It is used to measure various levels of visible light intensity(even darkness), range value is [0 to 1024]',
            color: '#ff8c19',
            name: 'light sensor',
            signalType: 'analog',
        },
        {
            type: 'distance_sensor',
            description:
                'Range Sensor: It is an IR sensor used to measure the distance of the object from it (max range 3-6cm), range value is [0 to 1024]',
            color: '#ff8c19',
            name: 'distance sensor',
            signalType: 'analog',
        },

        {
            type: 'joystick',
            description:
                'Joystick: A 2-axis joystick sensor, range value is [0 to 1024]',
            color: '#15909d',
            name: 'joystick',
            signalType: 'analog',
        },
        // {
        //     type: 'temperature_sensor',
        //     description:
        //         'Temperature: This module can be used to detect temperature changes both hot and cold, range value is [0 to 1]',
        //     color: '#15909d',
        //     name: 'Temp',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'humidity',
        //     description:
        //         'Humidity: This module can detect increase in humidity in the atmosphere, range value is [0 to 1024]',
        //     color: '#15909d',
        //     name: 'humidity',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'gas',
        //     description:
        //         'Gas: This module can detect alcohol, VOC in air, smoke and natural gas in air, range value is [0 to 1]',
        //     color: '#15909d',
        //     name: 'Gas',
        //     signalType: 'analog',
        // },

        {
            type: 'rain_sensor',
            description:
                'Rain Sensor: It is used to detect water drops on the sensor. Can also act as a bare hand touch sensor, range value is [0 to 1024]',
            color: '#15909d',
            name: 'Rain Sensor',
            signalType: 'analog',
        },
        {
            type: 'linear_pot',
            description:
                'Linear pot: This is a linear motion detection sensor, range value is [0 to 1024]',
            color: '#15909d',
            name: 'Linear Pot',
            signalType: 'analog',
        },
        // {
        //     type: 'extender',
        //     description:
        //         'Extender: This module can be used to connect any other 3v to 5v external sensor to the PC, range value is [0 to 1]',
        //     color: '#15909d',
        //     name: 'Extender',
        //     signalType: 'analog',
        // },
        {
            type: 'ultrasonic_sensor',
            description:
                'Ultrasonic Sensor: It is an acoustic sensor used to measure distance of the object from it (range 0-400cm), range value is [0 to 400]',
            color: '#ff8c19',
            name: 'ultrasonic sensor',
            signalType: 'analog',
        },
    ],
    HUMANOID0: [
        {
            type: 'led_1c',
            description:
                'Led 1c: This is a 1 channel LED, emits light when powered up, available in different colors, range value is [0 to 100]',
            color: '#15909d',
            name: 'led 1c',
            signalType: 'null',
        },
        {
            type: 'led',
            description:
                'Led 2c: This is a 2 channel LED,which emits light when powered up, available in different colors, range value is [0 to 100]',
            color: '#15909d',
            name: 'led',
            signalType: 'analog',
        },
        {
            type: 'laser',
            description:
                'Laser: It emits a narrow and focussed LASER beam, which travels long distance (avoid eye contact), range value is [0 to 1]',
            color: '#15909d',
            name: 'laser',
            signalType: 'null',
        },
        // {
        //     type: 'OLED',
        //     description:
        //         'OLED display: This module can be used to display text or messages. upto 3 lines of text can be displayed',
        //     color: '#15909d',
        //     name: 'OLED Display',
        // },

        {
            type: 'geared_motor',
            description:
                'Geared Motor: It is similar to DC motor, but with gears So it has more power to pull loads but lacks speed, range value is [-100 to 100]',
            color: '#15909d',
            name: 'geared motor',
        },
        {
            type: 'mini_geared_motor',
            description:
                'Mini Geared Motor: It is similar to Geared motor, smaller in size and efficient but with metal gears, range value is [-100 to 100]',
            color: '#15909d',
            name: 'mini geared motor',
        },
        {
            type: 'dc_motor',
            description:
                'DC Motor: It rotates when you power it up, speed and direction of rotation can be varied, range value is [-100 to 100]',
            color: '#15909d',
            name: 'dc motor',
        },
        {
            type: 'servo_motor',
            description:
                'Servo Motor 180: It is a motor with angular control, you can control its rotation from 0 to 180 degree, range value is [0 to 180]',
            color: '#15909d',
            name: 'servo motor',
        },
        {
            type: 'servo_motor_270',
            description:
                'Servo Motor 270: It is a motor with angular control, you can control its rotation from 0 to 270 degree, range value is [0 to 270]',
            color: '#15909d',
            name: 'servo motor',
        },

        {
            type: 'servo_motor_360',
            description:
                'Servo Motor 360: It is a continous rotation motor, You can control its rotation in both direction, range value is [-100 to 100]',
            color: '#15909d',
            name: 'servo motor 360',
        },
        {
            type: 'dual_splitter',
            description:
                'Motor Bridge: This module is used to control DC motor or two Servo motors. It also splits a dual line port into two single line port.',
            color: '#15909d',
            name: 'dual splitter',
        },

        {
            type: 'tact_switch',
            description:
                'Button 1c: It is a simple tactile switch used for toggle operation, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Button',
            signalType: 'analog',
        },
        {
            type: 'dip_switch',
            description:
                'DIP Switch: It is an input switch module with 2 slide switches for setting On/Off , range value is [0 to 1] ',
            color: '#ff8c19',
            name: 'DIP switch',
            signalType: 'digital',
        },

        {
            type: 'dual_switch',
            description:
                'Dual Switch: It is simple On-Off Switch, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'dual switch',
            signalType: 'analog',
        },
        {
            type: 'touch_sensor',
            description:
                'Touch Sensor: It is a touch enabled switch that works on capacitive touch interface, range value is [0 to 1000]',
            color: '#ff8c19',
            name: 'touch sensor',
            signalType: 'analog',
        },
        {
            type: 'metal_detector',
            description:
                'Metal Sensor: This module can be used to detect metal objects around it. it detects at within 5cms range from the coil, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Metal detector',
        },

        {
            type: 'pot',
            description:
                'Pot: It is a Pot sensor used for sensing amount of rotation , range value is [0 to 1024]',
            color: '#15909d',
            name: 'Pot',
            signalType: 'digital',
        },

        {
            type: 'light_sensor',
            description:
                'Light Sensor: It is used to measure various levels of visible light intensity(even darkness), range value is [0 to 1024]',
            color: '#ff8c19',
            name: 'light sensor',
            signalType: 'analog',
        },
        {
            type: 'distance_sensor',
            description:
                'Range Sensor: It is an IR sensor used to measure the distance of the object from it (max range 3-6cm), range value is [0 to 1024]',
            color: '#ff8c19',
            name: 'distance sensor',
            signalType: 'analog',
        },

        {
            type: 'joystick',
            description:
                'Joystick: A 2-axis joystick sensor, range value is [0 to 1024]',
            color: '#15909d',
            name: 'joystick',
            signalType: 'analog',
        },
        {
            type: 'temperature_sensor',
            description:
                'Temperature: This module can be used to detect temperature changes both hot and cold, range value is [0 to 1]',
            color: '#15909d',
            name: 'Temp',
            signalType: 'analog',
        },
        {
            type: 'humidity',
            description:
                'Humidity: This module can detect increase in humidity in the atmosphere, range value is [0 to 1024]',
            color: '#15909d',
            name: 'humidity',
            signalType: 'analog',
        },
        {
            type: 'gas',
            description:
                'Gas: This module can detect alcohol, VOC in air, smoke and natural gas in air, range value is [0 to 1]',
            color: '#15909d',
            name: 'Gas',
            signalType: 'analog',
        },

        {
            type: 'rain_sensor',
            description:
                'Rain Sensor: It is used to detect water drops on the sensor. Can also act as a bare hand touch sensor, range value is [0 to 1024]',
            color: '#15909d',
            name: 'Rain Sensor',
            signalType: 'analog',
        },
        {
            type: 'linear_pot',
            description:
                'Linear pot: This is a linear motion detection sensor, range value is [0 to 1024]',
            color: '#15909d',
            name: 'Linear Pot',
            signalType: 'analog',
        },
        {
            type: 'extender',
            description:
                'Extender: This module can be used to connect any other 3v to 5v external sensor to the PC, range value is [0 to 1]',
            color: '#15909d',
            name: 'Extender',
            signalType: 'analog',
        },
        {
            type: 'ultrasonic_sensor',
            description:
                'Ultrasonic Sensor: It is an acoustic sensor used to measure distance of the object from it (range 0-400cm), range value is [0 to 400]',
            color: '#ff8c19',
            name: 'ultrasonic sensor',
            signalType: 'analog',
        },
    ],
    HEXAPOD1: [
        {
            type: 'led_1c',
            description:
                'Led 1c: This is a 1 channel LED, emits light when powered up, available in different colors',
            color: '#15909d',
            name: 'led 1c',
            signalType: 'null',
        },
        {
            type: 'led',
            description:
                'Led 2c: This is a 2 channel LED,which emits light when powered up, available in different colors',
            color: '#15909d',
            name: 'led',
            signalType: 'analog',
        },
        {
            type: 'laser',
            description:
                'Laser: It emits a narrow and focussed LASER beam, which travels long distance (avoid eye contact)',
            color: '#15909d',
            name: 'laser',
            signalType: 'null',
        },
        // {
        //     type: 'OLED',
        //     description:
        //         'OLED display: This module can be used to display text or messages. upto 3 lines of text can be displayed',
        //     color: '#15909d',
        //     name: 'OLED Display',
        // },

        // {
        //     type: 'geared_motor',
        //     description:
        //         'Geared Motor: It is similar to DC motor, but with gears So it has more power to pull loads but lacks speed',
        //     color: '#15909d',
        //     name: 'geared motor',
        // },
        {
            type: 'mini_geared_motor',
            description:
                'Mini Geared Motor: It is similar to Geared motor, smaller in size and efficient but with metal gears',
            color: '#15909d',
            name: 'mini geared motor',
        },
        {
            type: 'dc_motor',
            description:
                'DC Motor: It rotates when you power it up, speed and direction of rotation can be varied',
            color: '#15909d',
            name: 'dc motor',
        },
        {
            type: 'servo_motor',
            description:
                'Servo Motor 180: It is a motor with angular control, you can control its rotation from 0 to 180 degree.',
            color: '#15909d',
            name: 'servo motor',
        },
        {
            type: 'servo_motor_270',
            description:
                'Servo Motor 270: It is a motor with angular control, you can control its rotation from 0 to 270 degree, range value is [0 to 270]',
            color: '#15909d',
            name: 'servo motor',
        },

        {
            type: 'servo_motor_360',
            description:
                'Servo Motor 360: It is a continous rotation motor, You can control its rotation in both direction.',
            color: '#15909d',
            name: 'servo motor 360',
        },
        {
            type: 'dual_splitter',
            description:
                'Motor Bridge: This module is used to control DC motor or two Servo motors. It also splits a dual line port into two single line port.',
            color: '#15909d',
            name: 'dual splitter',
        },
        {
            type: 'tact_switch',
            description:
                'Button 1c: It is a simple tactile switch used for toggle operation',
            color: '#ff8c19',
            name: 'Button',
            signalType: 'analog',
        },
        {
            type: 'tact_switch_2c',
            description:
                'Button 2c: It is a simple tactile switch used for toggle operation, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Button 2c',
        },
        {
            type: 'dip_switch',
            description:
                'DIP Switch: It is an input switch module with 2 slide switches for setting On/Off. ',
            color: '#ff8c19',
            name: 'DIP switch',
            signalType: 'digital',
        },

        {
            type: 'dual_switch',
            description: 'Dual Switch: It is simple On-Off Switch',
            color: '#ff8c19',
            name: 'dual switch',
            signalType: 'analog',
        },
        // {
        //     type: 'touch_sensor',
        //     description:
        //         'Touch Sensor: It is a touch enabled switch that works on capacitive touch interface',
        //     color: '#ff8c19',
        //     name: 'touch sensor',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'metal_detector',
        //     description:
        //         'Metal Sensor: This module can be used to detect metal objects around it. it detects at within 5cms range from the coil.',
        //     color: '#ff8c19',
        //     name: 'Metal detector',
        // },

        {
            type: 'pot',
            description:
                'Pot: It is a Pot sensor used for sensing amount of rotation',
            color: '#15909d',
            name: 'Pot',
            signalType: 'digital',
        },

        {
            type: 'light_sensor',
            description:
                'Light Sensor: It is used to measure various levels of visible light intensity  (even darkness)',
            color: '#ff8c19',
            name: 'light sensor',
            signalType: 'analog',
        },
        {
            type: 'distance_sensor',
            description:
                'Range Sensor: It is an IR sensor used to measure the distance of the object from it (max range 3-6cm)',
            color: '#ff8c19',
            name: 'distance sensor',
            signalType: 'analog',
        },

        {
            type: 'joystick',
            description: 'Joystick: A 2-axis joystick sensor',
            color: '#15909d',
            name: 'joystick',
            signalType: 'analog',
        },
        // {
        //     type: 'temperature_sensor',
        //     description:
        //         'Temperature: This module can be used to detect temperature changes both hot and cold.',
        //     color: '#15909d',
        //     name: 'Temp',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'humidity',
        //     description:
        //         'Humidity: This module can detect increase in humidity in the atmosphere.',
        //     color: '#15909d',
        //     name: 'humidity',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'gas',
        //     description:
        //         'Gas: This module can detect alcohol, VOC in air, smoke and natural gas in air.',
        //     color: '#15909d',
        //     name: 'Gas',
        //     signalType: 'analog',
        // },

        {
            type: 'rain_sensor',
            description:
                'Rain Sensor: It is used to detect water drops on the sensor. Can also act as a bare hand touch sensor',
            color: '#15909d',
            name: 'Rain Sensor',
            signalType: 'analog',
        },
        // {
        //     type: 'linear_pot',
        //     description:
        //         'Linear pot: This is a linear motion detection sensor.',
        //     color: '#15909d',
        //     name: 'Linear Pot',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'extender',
        //     description:
        //         'Extender: This module can be used to connect any other 3v to 5v external sensor to the PC',
        //     color: '#15909d',
        //     name: 'Extender',
        //     signalType: 'analog',
        // },
        {
            type: 'ultrasonic_sensor',
            description:
                'Ultrasonic Sensor: It is an acoustic sensor used to measure distance of the object from it (range 0-400cm)',
            color: '#ff8c19',
            name: 'ultrasonic sensor',
            signalType: 'analog',
        },
    ],
    HEXAPOD2: [
        {
            type: 'led_1c',
            description:
                'Led 1c: This is a 1 channel LED, emits light when powered up, available in different colors',
            color: '#15909d',
            name: 'led 1c',
            signalType: 'null',
        },
        {
            type: 'led',
            description:
                'Led 2c: This is a 2 channel LED,which emits light when powered up, available in different colors',
            color: '#15909d',
            name: 'led',
            signalType: 'analog',
        },
        {
            type: 'laser',
            description:
                'Laser: It emits a narrow and focussed LASER beam, which travels long distance (avoid eye contact)',
            color: '#15909d',
            name: 'laser',
            signalType: 'null',
        },
        // {
        //     type: 'OLED',
        //     description:
        //         'OLED display: This module can be used to display text or messages. upto 3 lines of text can be displayed',
        //     color: '#15909d',
        //     name: 'OLED Display',
        // },

        // {
        //     type: 'geared_motor',
        //     description:
        //         'Geared Motor: It is similar to DC motor, but with gears So it has more power to pull loads but lacks speed',
        //     color: '#15909d',
        //     name: 'geared motor',
        // },
        {
            type: 'mini_geared_motor',
            description:
                'Mini Geared Motor: It is similar to Geared motor, smaller in size and efficient but with metal gears',
            color: '#15909d',
            name: 'mini geared motor',
        },
        {
            type: 'dc_motor',
            description:
                'DC Motor: It rotates when you power it up, speed and direction of rotation can be varied',
            color: '#15909d',
            name: 'dc motor',
        },
        {
            type: 'servo_motor',
            description:
                'Servo Motor 180: It is a motor with angular control, you can control its rotation from 0 to 180 degree.',
            color: '#15909d',
            name: 'servo motor',
        },
        {
            type: 'servo_motor_270',
            description:
                'Servo Motor 270: It is a motor with angular control, you can control its rotation from 0 to 270 degree, range value is [0 to 270]',
            color: '#15909d',
            name: 'servo motor',
        },

        {
            type: 'servo_motor_360',
            description:
                'Servo Motor 360: It is a continous rotation motor, You can control its rotation in both direction.',
            color: '#15909d',
            name: 'servo motor 360',
        },
        {
            type: 'dual_splitter',
            description:
                'Motor Bridge: This module is used to control DC motor or two Servo motors. It also splits a dual line port into two single line port.',
            color: '#15909d',
            name: 'dual splitter',
        },
        {
            type: 'tact_switch',
            description:
                'Button 1c: It is a simple tactile switch used for toggle operation',
            color: '#ff8c19',
            name: 'Button',
            signalType: 'analog',
        },
        {
            type: 'tact_switch_2c',
            description:
                'Button 2c: It is a simple tactile switch used for toggle operation, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Button 2c',
        },

        {
            type: 'dip_switch',
            description:
                'DIP Switch: It is an input switch module with 2 slide switches for setting On/Off. ',
            color: '#ff8c19',
            name: 'DIP switch',
            signalType: 'digital',
        },

        {
            type: 'dual_switch',
            description: 'Dual Switch: It is simple On-Off Switch',
            color: '#ff8c19',
            name: 'dual switch',
            signalType: 'analog',
        },
        // {
        //     type: 'touch_sensor',
        //     description:
        //         'Touch Sensor: It is a touch enabled switch that works on capacitive touch interface',
        //     color: '#ff8c19',
        //     name: 'touch sensor',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'metal_detector',
        //     description:
        //         'Metal Sensor: This module can be used to detect metal objects around it. it detects at within 5cms range from the coil.',
        //     color: '#ff8c19',
        //     name: 'Metal detector',
        // },

        {
            type: 'pot',
            description:
                'Pot: It is a Pot sensor used for sensing amount of rotation',
            color: '#15909d',
            name: 'Pot',
            signalType: 'digital',
        },

        {
            type: 'light_sensor',
            description:
                'Light Sensor: It is used to measure various levels of visible light intensity  (even darkness)',
            color: '#ff8c19',
            name: 'light sensor',
            signalType: 'analog',
        },
        {
            type: 'distance_sensor',
            description:
                'Range Sensor: It is an IR sensor used to measure the distance of the object from it (max range 3-6cm)',
            color: '#ff8c19',
            name: 'distance sensor',
            signalType: 'analog',
        },

        {
            type: 'joystick',
            description: 'Joystick: A 2-axis joystick sensor',
            color: '#15909d',
            name: 'joystick',
            signalType: 'analog',
        },
        // {
        //     type: 'temperature_sensor',
        //     description:
        //         'Temperature: This module can be used to detect temperature changes both hot and cold.',
        //     color: '#15909d',
        //     name: 'Temp',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'humidity',
        //     description:
        //         'Humidity: This module can detect increase in humidity in the atmosphere.',
        //     color: '#15909d',
        //     name: 'humidity',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'gas',
        //     description:
        //         'Gas: This module can detect alcohol, VOC in air, smoke and natural gas in air.',
        //     color: '#15909d',
        //     name: 'Gas',
        //     signalType: 'analog',
        // },

        {
            type: 'rain_sensor',
            description:
                'Rain Sensor: It is used to detect water drops on the sensor. Can also act as a bare hand touch sensor',
            color: '#15909d',
            name: 'Rain Sensor',
            signalType: 'analog',
        },
        // {
        //     type: 'linear_pot',
        //     description:
        //         'Linear pot: This is a linear motion detection sensor.',
        //     color: '#15909d',
        //     name: 'Linear Pot',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'extender',
        //     description:
        //         'Extender: This module can be used to connect any other 3v to 5v external sensor to the PC',
        //     color: '#15909d',
        //     name: 'Extender',
        //     signalType: 'analog',
        // },
        {
            type: 'ultrasonic_sensor',
            description:
                'Ultrasonic Sensor: It is an acoustic sensor used to measure distance of the object from it (range 0-400cm)',
            color: '#ff8c19',
            name: 'ultrasonic sensor',
            signalType: 'analog',
        },
    ],
    ROBOKI1: [
        {
            type: 'led_1c',
            description:
                'Led 1c: This is a 1 channel LED, emits light when powered up, available in different colors, range value is [0 to 100]',

            color: '#15909d',
            name: 'led 1c',
            signalType: 'null',
        },
        {
            type: 'led',
            description:
                'Led 2c: This is a 2 channel LED,which emits light when powered up, available in different colors , range value is [0 to100]',
            color: '#15909d',
            name: 'led',
            signalType: 'analog',
        },
        {
            type: 'laser',
            description:
                'Laser: It emits a narrow and focussed LASER beam, which travels long distance (avoid eye contact), range value is [0 to 1]',
            color: '#15909d',
            name: 'laser',
            signalType: 'null',
        },
        // {
        //     type: 'RGB',
        //     description:
        //         'RGB: It is a serially connectable RGB LED with individual LED controls, range value is [0 to 255]',
        //     color: '#15909d',
        //     name: 'RBG',
        //     signalType: 'null',
        // },
        // {
        //     type: 'OLED',
        //     description:
        //         'OLED display: This module can be used to display text or messages. upto 3 lines of text can be displayed',
        //     color: '#15909d',
        //     name: 'OLED Display',
        // },
        // {
        //     type: 'geared_motor',
        //     description:
        //         'Geared Motor: It is similar to DC motor, but with gears So it has more power to pull loads but lacks speed',
        //     color: '#15909d',
        //     name: 'geared motor',
        // },
        {
            type: 'mini_geared_motor',
            description:
                'Mini Geared Motor: It is similar to Geared motor, smaller in size and efficient but with metal gears, range value is [-100 to 100]',
            color: '#15909d',
            name: 'mini geared motor',
        },
        {
            type: 'dc_motor',
            description:
                'DC Motor: It rotates when you power it up, speed and direction of rotation can be varied, range value is [-100 to 100]',
            color: '#15909d',
            name: 'dc motor',
        },
        {
            type: 'dynamex_motor',
            description:
                'Dynamex Motor: It rotates when you power it up, speed and direction of rotation can be varied, range value is [-100 to 100]',
            color: '#15909d',
            name: 'dynamex motor',
        },
        {
            type: 'servo_motor',
            description:
                'Servo Motor 180: It is a motor with angular control, you can control its rotation from 0 to 180 degree, range value is [0 to 180]',
            color: '#15909d',
            name: 'servo motor',
        },
        {
            type: 'servo_motor_270',
            description:
                'Servo Motor 270: It is a motor with angular control, you can control its rotation from 0 to 270 degree, range value is [0 to 270]',
            color: '#15909d',
            name: 'servo motor',
        },
        {
            type: 'servo_motor_360',
            description:
                'Servo Motor 360: It is a continous rotation motor, You can control its rotation in both direction, range value is [-100 to 100]',
            color: '#15909d',
            name: 'servo motor 360',
        },
        {
            type: 'dual_splitter',
            description:
                'Motor Bridge: This module is used to control DC motor or two Servo motors. It also splits a dual line port into two single line port, range value is [0 to 255]',
            color: '#15909d',
            name: 'dual splitter',
        },
        // {
        //     type: 'arm_shield',
        //     description:
        //         'Arm Shield: This module is an extender for robotic arm to connect digital servo motor and other accessories',
        //     color: '#15909d',
        //     name: 'Arm Shield',
        // },
        // {
        //     type: 'mp3',
        //     description:
        //         'Sound Card: This component helps your computer to produce and control sounds, range value is [0 to 255]',
        //     color: '#15909d',
        //     name: 'mp3',
        // },
        // {
        //     type: 'play_shield_v1',
        //     description:
        //         'Play Shield: This module is an extender to enable to use of  more I/O lines of the Play Computer.',
        //     color: '#15909d',
        //     name: 'PlayShield',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'peeCee_ATR',
        //     description:
        //         'PeeCee ATR: This module is an extender for Play Computer to connect digital servo motor and other accessories',
        //     color: '#15909d',
        //     name: 'PeeCee ATR',
        // },
        {
            type: 'tact_switch',
            description:
                'Button 1c: It is a simple tactile switch used for toggle operation, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Button',
            signalType: 'analog',
        },

        {
            type: 'tact_switch_2c',
            description:
                'Button 2c: It is a simple tactile switch used for toggle operation, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Button 2c',
        },
        {
            type: 'dip_switch',
            description:
                'DIP Switch: It is an input switch module with 2 slide switches for setting On/Off , range value is [0 to 1] ',
            color: '#ff8c19',
            name: 'DIP switch',
            signalType: 'digital',
        },
        {
            type: 'dual_switch',
            description:
                'Dual Switch: It is simple On-Off Switch, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'dual switch',
            signalType: 'analog',
        },

        // {
        //     type: 'touch_sensor',
        //     description:
        //         'Touch Sensor: It is a touch enabled switch that works on capacitive touch interface',
        //     color: '#ff8c19',
        //     name: 'touch sensor',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'metal_detector',
        //     description:
        //         'Metal Sensor: This module can be used to detect metal objects around it. it detects at within 5cms range from the coil.',
        //     color: '#ff8c19',
        //     name: 'Metal detector',
        // },
        {
            type: 'pot',
            description:
                'Pot: It is a Pot sensor used for sensing amount of rotation , range value is [0 to 4095]',
            color: '#15909d',
            name: 'Pot',
            signalType: 'digital',
        },

        {
            type: 'light_sensor',
            description:
                'Light Sensor: It is used to measure various levels of visible light intensity(even darkness), range value is [0 to 225]',
            color: '#ff8c19',
            name: 'light sensor',
            signalType: 'analog',
        },
        {
            type: 'distance_sensor',
            description:
                'Range Sensor: It is an IR sensor used to measure the distance of the object from it (max range 3-6cm), range value is [0 to 4095]',
            color: '#ff8c19',
            name: 'distance sensor',
            signalType: 'analog',
        },
        {
            type: 'joystick',
            description:
                'Joystick: A 2-axis joystick sensor, range value is [0 to 4095]',
            color: '#15909d',
            name: 'joystick',
            signalType: 'analog',
        },
        // {
        //     type: 'temperature_sensor',
        //     description:
        //         'Temperature: This module can be used to detect temperature changes both hot and cold.',
        //     color: '#15909d',
        //     name: 'Temp',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'humidity',
        //     description:
        //         'Humidity: This module can detect increase in humidity in the atmosphere.',
        //     color: '#15909d',
        //     name: 'humidity',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'gas',
        //     description:
        //         'Gas: This module can detect alcohol, VOC in air, smoke and natural gas in air.',
        //     color: '#15909d',
        //     name: 'Gas',
        //     signalType: 'analog',
        // },
        {
            type: 'rain_sensor',
            description:
                'Rain Sensor: It is used to detect water drops on the sensor. Can also act as a bare hand touch sensor, range value is [0 to 4095]',
            color: '#15909d',
            name: 'Rain Sensor',
            signalType: 'analog',
        },
        // {
        //     type: 'linear_pot',
        //     description:
        //         'Linear pot: This is a linear motion detection sensor.',
        //     color: '#15909d',
        //     name: 'Linear Pot',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'extender',
        //     description:
        //         'Extender: This module can be used to connect any other 3v to 5v external sensor to the PC',
        //     color: '#15909d',
        //     name: 'Extender',
        //     signalType: 'analog',
        // },

        {
            type: 'ultrasonic_sensor',
            description:
                'Ultrasonic Sensor: It is an acoustic sensor used to measure distance of the object from it (range 0-400cm), range value is [0 to 400]',
            color: '#ff8c19',
            name: 'ultrasonic sensor',
            signalType: 'analog',
        },
    ],
    KLAW1: [
        {
            type: 'led_1c',
            description:
                'Led 1c: This is a 1 channel LED, emits light when powered up, available in different colors, range value is [0 to 100]',
            color: '#15909d',
            name: 'led 1c',
            signalType: 'null',
        },
        {
            type: 'led',
            description:
                'Led 2c: This is a 2 channel LED,which emits light when powered up, available in different colors, range value is [0 to 100]',
            color: '#15909d',
            name: 'led',
            signalType: 'analog',
        },
        {
            type: 'laser',
            description:
                'Laser: It emits a narrow and focussed LASER beam, which travels long distance (avoid eye contact), range value is [0 to 1]',
            color: '#15909d',
            name: 'laser',
            signalType: 'null',
        },
        // {
        //     type: 'geared_motor',
        //     description:
        //         'Geared Motor: It is similar to DC motor, but with gears So it has more power to pull loads but lacks speed, range value is [-100 to 100]',
        //     color: '#15909d',
        //     name: 'geared motor',
        // },
        {
            type: 'mini_geared_motor',
            description:
                'Mini Geared Motor: It is similar to Geared motor, smaller in size and efficient but with metal gears, range value is [-100 to 100]',
            color: '#15909d',
            name: 'mini geared motor',
        },
        {
            type: 'dc_motor',
            description:
                'DC Motor: It rotates when you power it up, speed and direction of rotation can be varied, range value is [-100 to 100]',
            color: '#15909d',
            name: 'dc motor',
        },
        {
            type: 'servo_motor',
            description:
                'Servo Motor 180: It is a motor with angular control, you can control its rotation from 0 to 180 degree, range value is [0 to 180]',
            color: '#15909d',
            name: 'servo motor',
        },
        {
            type: 'servo_motor_270',
            description:
                'Servo Motor 270: It is a motor with angular control, you can control its rotation from 0 to 270 degree, range value is [0 to 270]',
            color: '#15909d',
            name: 'servo motor',
        },
        {
            type: 'servo_motor_360',
            description:
                'Servo Motor 360: It is a continous rotation motor, You can control its rotation in both direction, range value is [-100 to 100]',
            color: '#15909d',
            name: 'servo motor 360',
        },
        {
            type: 'dual_splitter',
            description:
                'Motor Bridge: This module is used to control DC motor or two Servo motors. It also splits a dual line port into two single line port.',
            color: '#15909d',
            name: 'dual splitter',
        },
        {
            type: 'tact_switch',
            description:
                'Button 1c: It is a simple tactile switch used for toggle operation, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'Button',
            signalType: 'analog',
        },
        {
            type: 'dip_switch',
            description:
                'DIP Switch: It is an input switch module with 2 slide switches for setting On/Off , range value is [0 to 1] ',
            color: '#ff8c19',
            name: 'DIP switch',
            signalType: 'digital',
        },
        {
            type: 'dual_switch',
            description:
                'Dual Switch: It is simple On-Off Switch, range value is [0 to 1]',
            color: '#ff8c19',
            name: 'dual switch',
            signalType: 'analog',
        },
        // {
        //     type: 'touch_sensor',
        //     description:
        //         'Touch Sensor: It is a touch enabled switch that works on capacitive touch interface, range value is [0 to 1000]',
        //     color: '#ff8c19',
        //     name: 'touch sensor',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'metal_detector',
        //     description:
        //         'Metal Sensor: This module can be used to detect metal objects around it. it detects at within 5cms range from the coil, range value is [0 to 1]',
        //     color: '#ff8c19',
        //     name: 'Metal detector',
        // },
        {
            type: 'pot',
            description:
                'Pot: It is a Pot sensor used for sensing amount of rotation , range value is [0 to 1024]',
            color: '#15909d',
            name: 'Pot',
            signalType: 'digital',
        },
        {
            type: 'light_sensor',
            description:
                'Light Sensor: It is used to measure various levels of visible light intensity(even darkness), range value is [0 to 1024]',
            color: '#ff8c19',
            name: 'light sensor',
            signalType: 'analog',
        },
        {
            type: 'distance_sensor',
            description:
                'Range Sensor: It is an IR sensor used to measure the distance of the object from it (max range 3-6cm), range value is [0 to 1024]',
            color: '#ff8c19',
            name: 'distance sensor',
            signalType: 'analog',
        },
        {
            type: 'joystick',
            description:
                'Joystick: A 2-axis joystick sensor, range value is [0 to 1024]',
            color: '#15909d',
            name: 'joystick',
            signalType: 'analog',
        },
        // {
        //     type: 'temperature_sensor',
        //     description:
        //         'Temperature: This module can be used to detect temperature changes both hot and cold, range value is [0 to 1]',
        //     color: '#15909d',
        //     name: 'Temp',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'humidity',
        //     description:
        //         'Humidity: This module can detect increase in humidity in the atmosphere, range value is [0 to 1024]',
        //     color: '#15909d',
        //     name: 'humidity',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'gas',
        //     description:
        //         'Gas: This module can detect alcohol, VOC in air, smoke and natural gas in air, range value is [0 to 1]',
        //     color: '#15909d',
        //     name: 'Gas',
        //     signalType: 'analog',
        // },
        {
            type: 'rain_sensor',
            description:
                'Rain Sensor: It is used to detect water drops on the sensor. Can also act as a bare hand touch sensor, range value is [0 to 1024]',
            color: '#15909d',
            name: 'Rain Sensor',
            signalType: 'analog',
        },
        // {
        //     type: 'linear_pot',
        //     description:
        //         'Linear pot: This is a linear motion detection sensor, range value is [0 to 1024]',
        //     color: '#15909d',
        //     name: 'Linear Pot',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'extender',
        //     description:
        //         'Extender: This module can be used to connect any other 3v to 5v external sensor to the PC, range value is [0 to 1]',
        //     color: '#15909d',
        //     name: 'Extender',
        //     signalType: 'analog',
        // },
        // {
        //     type: 'serial_servo_motor',
        //     description:
        //         'Serial Servo Motor: It is a digital servo motor that can be controlled in both directions',
        //     color: '#15909d',
        //     name: 'Serial Servo Motor',
        // },
        // {
        //     type: 'PWM',
        //     description:
        //         'PWM Motor: It is a digital servo motor that can be controlled in both directions',
        //     color: '#15909d',
        //     name: 'BB Motor 180',
        // },
        {
            type: 'ultrasonic_sensor',
            description:
                'Ultrasonic Sensor: It is an acoustic sensor used to measure distance of the object from it (range 0-400cm), range value is [0 to 400]',
            color: '#ff8c19',
            name: 'ultrasonic sensor',
            signalType: 'analog',
        },
    ],
}
