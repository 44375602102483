import React, { Component } from 'react'
import HexTypes from '../HexTypes'
import Select from './helpers/Select'
import Slider from './helpers/Slider'
// import componentProps from '../../../componentProps'
import IOComponents from '../IOComponents'
import logicVariables from '../logicVariables'
import PortValuesRangeMapping from '../PortValuesRangeMapping'
import unicodeToChar from '../../../utils/unicodeToChar'
import { connect } from 'react-redux'
import { webSerialConnect } from '../../../redux/actions/index'
import { rangeStoreVal } from '../../Assembly/CheckboxData'
import renderPrgImage from '../../../source/programImg'
import { gestureValueToText } from '../../../utils'
// import DecisionPanel from '../../Humanoid/DecisionPanel'
import {
    sendBytes,
    continousPABytesReadToggle_v1,
    readBytesPa,
} from '../../ReusableComponents/ByteTransfer'
import {
    klawPABytesToSensorData,
    pcAiTool_PABytesToSensorData,
    playComputerPABytesToSensorData,
    robokiPABytesToSensorData,
} from '../../ReusableComponents/ByteTransfer/utils'
import DecisionPanel from '../../Humanoid/DecisionPanel'
import { aiItemsData, paBytesAi } from '../../../source/assemblyPageAiData'

// var componentType = localStorage.getItem("biboxTypes");
// setInterval(() => {
//     componentType = localStorage.getItem("biboxTypes");
// } 100);
const hours = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    17: 17,
    18: 18,
    19: 19,
    20: 20,
    21: 21,
    22: 22,
    23: 23,
}
const minutes = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    17: 17,
    18: 18,
    19: 19,
    20: 20,
    21: 21,
    22: 22,
    23: 23,
    24: 24,
    25: 25,
    26: 26,
    27: 27,
    28: 28,
    29: 29,
    30: 30,
    31: 31,
    32: 32,
    33: 33,
    34: 34,
    35: 35,
    36: 36,
    37: 37,
    38: 38,
    39: 39,
    40: 40,
    41: 41,
    42: 42,
    43: 43,
    44: 44,
    45: 45,
    46: 46,
    47: 47,
    48: 48,
    49: 49,
    50: 50,
    51: 51,
    52: 52,
    53: 53,
    54: 54,
    55: 55,
    56: 56,
    57: 57,
    58: 58,
    59: 59,
}

var reader

const conditionDisabledStyle = {
    cursor: 'default',
    opacity: '0.5',
}

class IfPanel extends Component {
    constructor(props) {
        super(props)
        const { state, onChange } = this.props
        if (Object.keys(state).length <= 0) {
            state['source'] = 'battery'
            state['value'] = 0
            state['value2'] = 0
            state['hour'] = 0
            state['hour2'] = 0
            state['minute'] = 0
            state['minute2'] = 0
        }
        onChange(state, 'sensor')
        try {
            this.aiTrainList = JSON.parse(localStorage.getItem('aiTrainList'))
        } catch {
            this.aiTrainList = {}
        }
        this.state = {
            recentGesture: 0,
            state,
            k: false,
            isGraterThan: false,
            isLessThan: false,
            isNotequalTo: false,
            isEqualTo: false,
            isInBtween: false,
            isRead: false,
            isRead_v1: false,
            Bytes: false,
            accelerometer_X: '',
            accelerometer_Y: '',
            accelerometer_Z: '',
            gyro_X: '',
            gyro_Y: '',
            gyro_Z: '',
            responceTp0: '',
            responceTp1: '',
            responceTp2: '',
            touch_pad: '',
            touch_pad2: '',
            rangeA1: '',
            rangeA2: '',
            rangeB1: '',
            rangeB2: '',
            rangeC1: '',
            rangeC2: '',
            rangeD1: '',
            rangeD2: '',
            rangeE1: '',
            rangeE2: '',
            rangeF1: '',
            rangeF2: '',
            tactswitch: '',
            tact_switch_2c: '',
            battery: '',
            zero: '',
            mic: '',
            temprature: '',
            temp: '',
            gas: '',
            one: '',
            two: '',
            red: '',
            green: '',
            blue: '',
            light: '',
            gesture: '',
            distance: '',
            switch1: '',
            switch2: '',
            pcAiTool_read_value: '',
            readToggel: false,
            showPopup: false,

            //klaw
            // joystick1_X: '',
            // joystick1_Y: '',
            // joystick2_X: '',
            // joystick2_Y: '',
            // joystick3_X: '',
            // joystick3_Y: '',
            // switch1: '',
            // switch2: '',
            // switch3: '',
            // pwm1: '',
            // pwm2: '',
            // pwm3: '',
            // pwm4: '',
            // pwm5: '',
            // pwm6: '',
        }
    }

    outputGesture = (val) => {
        // return val;
        if (val != 0) {
            this.state.recentGesture = val
        }
        return this.state.recentGesture
    }

    hexTypeCheck = () => {
        this.props.hexTypeCheck('sensor')
    }

    /////////////////////////  Read and write for version 0.1.13 ////////////////////////
    async readdata() {
        const port = this.props.webSerial.port
        // eslint-disable-next-line no-undef
        const textDecoder = new TextDecoderStream()
        try {
            // eslint-disable-next-line no-undef
            const readableStreamClosed = port.readable.pipeTo(
                textDecoder.writable
            )
        } catch {}

        // const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
        reader = textDecoder.readable.getReader()
        var i = 1
        var combiBytes = []
        while (true) {
            const { value, done } = await reader.read()
            // console.log("VALUES", value, value.length);

            try {
                combiBytes = [...combiBytes, ...value]
            } catch (e) {}

            if (combiBytes.includes(`\n`)) {
                this.state.Bytes = combiBytes.join('')
                console.log(combiBytes.join(''), 'comb')
                combiBytes = []
            }
            // i++;
            // console.log("lxlxl", value);
            console.log('lxlxl', combiBytes)
            if (this.state.k == true) {
                console.log('MAI CHAL GAYA')
                reader.releaseLock()
                break
            }

            if (done) {
                // Allow the serial port to be closed later.
                reader.releaseLock()
                break
            }
            // value is a string.
            console.log(value)
        }
        // try {
        //   const reader = port.readable.getReader();
        //   var i = 1;
        //   var combiBytes = [];
        //   // Listen to data coming from the serial device.
        //   while (true) {
        //     const { value, done } = await reader.read();
        //     combiBytes = [...combiBytes, ...value];
        //     i++;
        //     if (this.state.k === true) {
        //       console.log("MAI CHAL GAYA");
        //       reader.releaseLock();
        //       break;
        //     }
        //     if (i == 2) {
        //       console.log("PABYTES", unicodeToChar(combiBytes));
        //       reader.releaseLock();
        //       this.state.Bytes = unicodeToChar(combiBytes);
        //       break;
        //     }
        //     // value is a string.
        //     if (value.length == 32) {
        //       var v = unicodeToChar(value);
        //       console.log(v);
        //     }
        //     if (value.length == 7) {
        //       var vi = unicodeToChar(value);
        //       console.log(vi);
        //     }
        //     if (value.length == 9) {
        //       var vi = unicodeToChar(value);
        //       console.log(vi);
        //     }
        //     if (value.length == 14) {
        //       var vi = unicodeToChar(value);
        //       console.log(vi);
        //     }
        //     if (value.length == 17) {
        //       var vi = unicodeToChar(value);
        //       console.log(vi);
        //     }
        //     if (value.length == 12) {
        //       var vi = unicodeToChar(value);
        //       console.log(vi);
        //     }
        //     if ((value.lenght == 32 && value.lenght == 12) || value.lenght == 11) {
        //       var vae = v + " " + vi;
        //       console.log(vae, "ORRRR");
        //     }
        //     var vae = v + vi;

        //     console.log("ADDED I", vae);
        //     // this.state.Bytes = vae;
        //   }
        // } catch (e) {
        //   console.log(e);
        // }
    }
    MSBLSBToDecimal = (MSBInBinary, LSBInBinary) => {
        return parseInt(
            parseInt(MSBInBinary).toString(2).padStart(8, 0) +
                parseInt(LSBInBinary).toString(2).padStart(8, 0),
            2
        )
    }
    setPABytes_v01 = async (data) => {
        if (data === undefined) return
        data = data.filter((e) => e != 80 && e != 65)
        console.log(this.MSBLSBToDecimal(data[1], data[0]), 'gsk read')
        this.setState({
            zero: this.MSBLSBToDecimal(data[1], data[0]),
            one: this.MSBLSBToDecimal(data[5], data[4]),
            two: this.MSBLSBToDecimal(data[9], data[8]),
            rangeA1: this.MSBLSBToDecimal(data[1], data[0]),
            rangeA2: this.MSBLSBToDecimal(data[3], data[2]),
            rangeB1: this.MSBLSBToDecimal(data[5], data[4]),
            rangeB2: this.MSBLSBToDecimal(data[7], data[6]),
            rangeC1: this.MSBLSBToDecimal(data[9], data[8]),
            rangeC2: this.MSBLSBToDecimal(data[11], data[10]),
            rangeF1: data[12],
            rangeF2: data[13],
            red: data[14],
            green: data[15],
            blue: data[16],
            light: this.MSBLSBToDecimal(data[15], data[14]),
            distance: data[15],
            gesture: data[16],
            mic: this.MSBLSBToDecimal(data[18], data[17]),
            temprature: this.MSBLSBToDecimal(data[20], data[19]),
        })
    }
    getBytesToSend() {
        let bytesData = Array(11).fill('O'.charCodeAt())

        bytesData.unshift('A'.charCodeAt())
        bytesData.unshift('P'.charCodeAt())

        let sessionData = JSON.parse(sessionStorage.getItem('concept'))
        console.log(sessionData, 'sessiondatadatadatadat')
        console.log(sessionData.internalaccessories, 'Internal data')

        let portdata = JSON.parse(sessionStorage.getItem('assembly'))
        console.log(
            portdata.PortConnections,
            '<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<DATA>>>>>>>>>>>>>>>>>'
        )
        console.log(portdata.PortConnections, '>>???>>>>????<<<<')
        // console.log("TYPE:-", portdata.PortConnections);
        // Obect.keys and Object.values n

        Object.keys(portdata.PortConnections).map((port) => {
            if (portdata.PortConnections[port] != null) {
                if (port === 'A' || port === 'A1' || port === 'A2') {
                    let Type = portdata.PortConnections[port].type

                    if (Type == 'ultrasonic_sensor') {
                        bytesData[2] = 'U'.charCodeAt()
                        // bytesData[3] = "I".charCodeAt();
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch' ||
                        Type == 'metal_detector'
                    ) {
                        if (port == 'A') {
                            bytesData[2] = 'I'.charCodeAt()
                            bytesData[3] = 'I'.charCodeAt()
                        }
                        if (port == 'A1') bytesData[2] = 'I'.charCodeAt()
                        if (port == 'A2') bytesData[3] = 'I'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'A') {
                            bytesData[2] = 'A'.charCodeAt()
                            bytesData[3] = 'A'.charCodeAt()
                        }
                        if (port == 'A1') bytesData[2] = 'A'.charCodeAt()
                        if (port == 'A2') bytesData[3] = 'A'.charCodeAt()
                    } else if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        bytesData[2] = 'A'.charCodeAt()
                        bytesData[3] = 'A'.charCodeAt()
                    }
                } else if (port === 'B' || port === 'B1' || port === 'B2') {
                    let Type = portdata.PortConnections[port].type

                    if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        bytesData[4] = 'A'.charCodeAt()
                        bytesData[5] = 'A'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'B') {
                            bytesData[4] = 'A'.charCodeAt()
                            bytesData[5] = 'A'.charCodeAt()
                        }
                        if (port == 'B1') bytesData[4] = 'A'.charCodeAt()
                        if (port == 'B2') bytesData[5] = 'A'.charCodeAt()
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch' ||
                        Type == 'metal_detector'
                    ) {
                        if (port == 'B') {
                            bytesData[4] = 'I'.charCodeAt()
                            bytesData[5] = 'I'.charCodeAt()
                        }
                        if (port == 'B1') bytesData[4] = 'I'.charCodeAt()
                        if (port == 'B2') bytesData[5] = 'I'.charCodeAt()
                    }
                } else if (port === 'C' || port === 'C1' || port === 'C2') {
                    let Type = portdata.PortConnections[port].type

                    if (Type == 'ultrasonic_sensor') {
                        bytesData[6] = 'U'.charCodeAt()
                        // bytesData[7] = "A".charCodeAt();
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch' ||
                        Type == 'metal_detector'
                    ) {
                        if (port == 'C') {
                            bytesData[6] = 'I'.charCodeAt()
                            bytesData[7] = 'I'.charCodeAt()
                        }
                        if (port == 'C1') bytesData[6] = 'I'.charCodeAt()
                        if (port == 'C2') bytesData[7] = 'I'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'C') {
                            bytesData[6] = 'A'.charCodeAt()
                            bytesData[7] = 'A'.charCodeAt()
                        }
                        if (port == 'C1') bytesData[6] = 'A'.charCodeAt()
                        if (port == 'C2') bytesData[7] = 'A'.charCodeAt()
                    } else if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'metal_detector' ||
                        Type == 'extender'
                    ) {
                        bytesData[6] = 'A'.charCodeAt()
                        bytesData[7] = 'A'.charCodeAt()
                    }
                } else if (port === 'F' || port === 'F1' || port === 'F2') {
                    let Type = portdata.PortConnections[port].type

                    if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'metal_detector' ||
                        Type == 'dip_switch' ||
                        Type == 'tact_switch_2c'
                    ) {
                        if (port == 'F1') bytesData[8] = 'I'.charCodeAt()
                        if (port == 'F2') bytesData[9] = 'I'.charCodeAt()
                    }
                }
            }
        })

        if (sessionData.internalaccessories.isMic) {
            bytesData[11] = 'M'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTemperature) {
            bytesData[12] = 'T'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTouchZero) {
            bytesData[2] = 'T'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTouchOne) {
            bytesData[4] = 'T'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTouchTwo) {
            bytesData[6] = 'T'.charCodeAt()
        }

        if (
            sessionData.internalaccessories.Four_in_one_sensor.isDistanceSensors
        ) {
            bytesData[10] = 'D'.charCodeAt()
        }
        if (
            sessionData.internalaccessories.Four_in_one_sensor.isGestureSensor
        ) {
            bytesData[10] = 'G'.charCodeAt()
        }
        if (sessionData.internalaccessories.Four_in_one_sensor.isColorSensor) {
            bytesData[10] = 'C'.charCodeAt()
        }
        if (sessionData.internalaccessories.Four_in_one_sensor.isLightSensor) {
            bytesData[10] = 'L'.charCodeAt()
        }
        return bytesData
    }
    handleReadByte = async () => {
        this.props.worker.onmessage = (e) => {
            if (e.data.type == 'PAreadPCv0')
                this.recievedBytes_Pcv01(e.data.value)
        }
        // console.log('gsk sending', this.getBytesToSend())
        if (!this.props.webSerial.isConnected) {
            await this.setState({ isRead: false })
        }
        await this.setState({ isRead: !this.state.isRead })
        let flag = true
        while (true) {
            console.log('check read state', this.state.isRead)
            if (!this.state.isRead && this.props.webSerial.isConnected) {
                setTimeout(() => {
                    this.props.worker.postMessage({
                        type: 'write',
                        value: 'M8',
                    })
                }, 500)
                return
            }
            if (!this.state.isRead || !this.props.webSerial.isConnected) {
                this.recievedBytes_Pcv01([
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    0,
                ])

                return
            }
            if (flag) {
                // await sendBytes(
                //     [
                //         'M'.charCodeAt(0),
                //         '8'.charCodeAt(0),
                //     ],
                //     this.props.webSerial.port
                // )
                flag = false
                await new Promise((resolve) => setTimeout(resolve, 200))
            } else {
                //temp fix for page haulting problem
                this.props.worker.postMessage({
                    type: 'writeArray',
                    value: this.getBytesToSend(),
                })
                await new Promise((resolve) => setTimeout(resolve, 100))
                // if (
                //     !(await sendBytes(
                //         this.getBytesToSend(),
                //         this.props.webSerial.port
                //     ))
                // ) {
                //     window.location.reload()
                //     return
                // }
                // let read = await readBytesPa(
                //     this.props.webSerial.port,
                //     !this.state.isRead
                // )
                // setTimeout(() => this.recievedBytes_Pcv01(read), 320)
            }
        }
    }
    async writePort(data) {
        sendBytes(data, this.props.webSerial.port)
    }
    /////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////  Read and write for version 1.0.01 /////////////////////////
    setPABytes_V1() {
        let sendBytes = Array(25).fill('0')

        sendBytes[0] = 'P'
        sendBytes[1] = 'A'
        sendBytes[23] = 'E'
        sendBytes[24] = 'R'

        let portConnections = this.props.assembly.PortConnections
        let internalAccessories = this.props.concept.internalaccessories
        Object.keys(portConnections).map((port) => {
            if (
                port !== 'A' &&
                port !== 'B' &&
                port !== 'C' &&
                port !== 'D' &&
                portConnections[port] !== null
            ) {
                const getIndex = (port) => {
                    switch (port) {
                        case 'A1':
                            return 2
                        case 'A2':
                            return 3
                        case 'B1':
                            return 4
                        case 'B2':
                            return 5
                        case 'C1':
                            return 6
                        case 'C2':
                            return 7
                        case 'D1':
                            return 8
                        case 'D2':
                            return 9
                        case 'E1':
                            return 10
                        case 'E2':
                            return 11
                        case 'F1':
                            return 12
                        case 'F2':
                            return 13
                    }
                }
                let arrIndex = getIndex(port)
                let Type = portConnections[port].type
                if (Type == 'ultrasonic_sensor') {
                    sendBytes[arrIndex] = 'U'
                } else if (
                    Type == 'tact_switch' ||
                    Type == 'dual_switch' ||
                    Type == 'metal_detector' ||
                    Type == 'dip_switch' ||
                    Type == 'touch_sensor' ||
                    Type == 'tact_switch_2c'
                ) {
                    sendBytes[arrIndex] = 'I'
                    sendBytes[arrIndex] = 'I'
                } else if (
                    Type == 'distance_sensor' ||
                    Type == 'temperature_sensor' ||
                    Type == 'gas' ||
                    Type == 'light_sensor' ||
                    Type == 'linear_pot' ||
                    Type == 'pot' ||
                    Type == 'rain_sensor' ||
                    Type == 'humidity'
                ) {
                    sendBytes[arrIndex] = 'A'
                } else if (
                    Type == 'rotatory' ||
                    Type == 'joystick' ||
                    Type == 'extender'
                ) {
                    sendBytes[arrIndex] = 'A'
                    sendBytes[arrIndex] = 'A'
                } else if (['pcAiTool'].includes(Type)) {
                    sendBytes[arrIndex] = paBytesAi[this.state.readToggel]
                }
            }
        })

        if (internalAccessories.isMic) {
            sendBytes[14] = 'M'
        }
        if (internalAccessories.isTemperature) {
            sendBytes[15] = 'T'
        }
        if (internalAccessories.isTouchZero) {
            sendBytes[16] = 'T'
        }
        if (internalAccessories.isTouchOne) {
            sendBytes[17] = 'T'
        }
        if (internalAccessories.isTouchTwo) {
            sendBytes[18] = 'T'
        }
        if (internalAccessories.isTouchThree) {
            sendBytes[19] = 'T'
        }
        if (internalAccessories.Four_in_one_sensor.isDistanceSensors) {
            sendBytes[20] = 'D'
        }
        if (internalAccessories.Four_in_one_sensor.isGestureSensor) {
            sendBytes[20] = 'G'
        }
        if (
            internalAccessories.Four_in_one_sensor.isColorSensor &&
            (this.state.readToggel == '4-IN-1 SENSOR  →  RED' ||
                this.state.readToggel == '4-IN-1 SENSOR  →  GREEN' ||
                this.state.readToggel == '4-IN-1 SENSOR  →  BLUE')
        ) {
            sendBytes[20] = 'C'
        }
        if (internalAccessories.Four_in_one_sensor.isLightSensor) {
            sendBytes[20] = 'L'
        }
        if (internalAccessories.isAccelerometer) {
            sendBytes[21] = 'A'
        }
        if (internalAccessories.isBattery) {
            sendBytes[22] = 'B'
        }
        sendBytes = sendBytes.toString().replaceAll(',', '')
        return sendBytes
    }

    //set PA bytes for roboki
    setPABytes_Roboki() {
        let sendBytes = Array(10).fill('0')

        sendBytes[0] = 'P'
        sendBytes[1] = 'A'
        sendBytes[8] = 'E'
        sendBytes[9] = 'R'
        let internalAccessories = this.props.concept.internalaccessories
        let portConnections = this.props.assembly.PortConnections
        Object.keys(portConnections).map((port) => {
            if (
                port !== 'A' &&
                // port !== 'B' &&
                port !== 'C' &&
                // port !== 'D' &&
                portConnections[port] !== null
            ) {
                const getIndex = (port) => {
                    switch (port) {
                        case 'A1':
                            return 2
                        case 'A2':
                            return 3
                        case 'B1':
                            return 4
                        case 'B2':
                            return 5
                        // case 'C1':
                        //     return 4
                        // case 'C2':
                        //     return 5
                        // case 'D1':
                        //     return 8
                        // case 'D2':
                        //     return 9
                    }
                }

                let arrIndex = getIndex(port)
                let Type = portConnections[port].type
                if (Type == 'ultrasonic_sensor') {
                    sendBytes[arrIndex] = 'U'
                } else if (
                    Type == 'tact_switch' ||
                    Type == 'dual_switch' ||
                    Type == 'metal_detector' ||
                    Type == 'touch_sensor' ||
                    Type == 'dip_switch' ||
                    Type == 'tact_switch_2c'
                ) {
                    sendBytes[arrIndex] = 'I'
                    sendBytes[arrIndex] = 'I'
                } else if (
                    Type == 'distance_sensor' ||
                    Type == 'temperature_sensor' ||
                    Type == 'gas' ||
                    Type == 'light_sensor' ||
                    Type == 'linear_pot' ||
                    Type == 'pot' ||
                    Type == 'rain_sensor' ||
                    Type == 'humidity'
                ) {
                    sendBytes[arrIndex] = 'A'
                } else if (
                    Type == 'rotatory' ||
                    Type == 'joystick' ||
                    Type == 'extender'
                ) {
                    sendBytes[arrIndex] = 'A'
                    sendBytes[arrIndex] = 'A'
                }
            }
        })

        if (internalAccessories.isSwitch1) {
            sendBytes[6] = 'I'
        }
        if (internalAccessories.isSwitch2) {
            sendBytes[7] = 'I'
        }
        sendBytes = sendBytes.toString().replaceAll(',', '')
        return sendBytes
    }
    //set PA bytes for Klaw
    // setPABytes_Klaw() {
    //     let humanoidPortData = JSON.parse(
    //         sessionStorage.getItem('humanoidPortData')
    //     )
    //     let sendBytes = Array(17).fill('O')

    //     sendBytes[0] = 'P'
    //     sendBytes[1] = 'A'
    //     sendBytes[15] = 'E'
    //     sendBytes[16] = 'R'
    //     let internalAccessories = this.props.concept.internalaccessories
    //     let portConnections = this.props.assembly.PortConnections
    //     Object.keys(portConnections).map((port) => {
    //         if (
    //             port !== 'A' &&
    //             port !== 'B' &&
    //             port !== 'C' &&
    //             // port !== 'D' &&
    //             portConnections[port] !== null
    //         ) {
    //             const getIndex = (port) => {
    //                 switch (port) {
    //                     case 'A1':
    //                         return 2
    //                     case 'A2':
    //                         return 3
    //                     case 'B1':
    //                         return 4
    //                     case 'B2':
    //                         return 5
    //                     // case 'C1':
    //                     //     return 6
    //                     // case 'C2':
    //                     //     return 7
    //                     // case 'D1':
    //                     //     return 8
    //                     // case 'D2':
    //                     //     return 9
    //                     // case 'E1':
    //                     //     return 10
    //                     // case 'E2':
    //                     //     return 11
    //                     // case 'F1':
    //                     //     return 12
    //                     // case 'F2':
    //                     //     return 13
    //                 }
    //             }

    //             let arrIndex = getIndex(port)
    //             let Type = portConnections[port].type
    //             if (Type == 'ultrasonic_sensor') {
    //                 sendBytes[arrIndex] = 'U'
    //             } else if (
    //                 Type == 'tact_switch' ||
    //                 Type == 'dual_switch' ||
    //                 Type == 'metal_detector' ||
    //                 Type == 'touch_sensor' ||
    //                 Type == 'dip_switch' ||
    //                 Type == 'tact_switch_2c'
    //             ) {
    //                 sendBytes[arrIndex] = 'I'
    //                 sendBytes[arrIndex] = 'I'
    //             } else if (
    //                 Type == 'distance_sensor' ||
    //                 Type == 'temperature_sensor' ||
    //                 Type == 'gas' ||
    //                 Type == 'light_sensor' ||
    //                 Type == 'linear_pot' ||
    //                 Type == 'pot' ||
    //                 Type == 'rain_sensor' ||
    //                 Type == 'humidity'
    //             ) {
    //                 sendBytes[arrIndex] = 'A'
    //             } else if (
    //                 Type == 'rotatory' ||
    //                 Type == 'joystick' ||
    //                 Type == 'extender'
    //             ) {
    //                 sendBytes[arrIndex] = 'A'
    //                 sendBytes[arrIndex] = 'A'
    //             }
    //         }
    //     })
    //     if (sessionStorage.getItem('connectedDevice') == 'Klaw') {
    //         //joystick1
    //         sendBytes[6] = humanoidPortData['0'].isPortSelected ? 'A' : 'O'
    //         sendBytes[7] = humanoidPortData['0'].isPortSelected ? 'A' : 'O'

    //         //joystick1
    //         sendBytes[8] = humanoidPortData['1'].isPortSelected ? 'A' : 'O'
    //         sendBytes[9] = humanoidPortData['1'].isPortSelected ? 'A' : 'O'

    //         //joystick1
    //         sendBytes[10] = humanoidPortData['2'].isPortSelected ? 'A' : 'O'
    //         sendBytes[11] = humanoidPortData['2'].isPortSelected ? 'A' : 'O'

    //         //switch
    //         sendBytes[12] = humanoidPortData['3'].isPortSelected ? 'I' : 'O'
    //         sendBytes[13] = humanoidPortData['4'].isPortSelected ? 'I' : 'O'
    //         sendBytes[14] = humanoidPortData['5'].isPortSelected ? 'I' : 'O'
    //     } else {
    //         sendBytes[8] = humanoidPortData['22'].isPortSelected ? 'U' : 'O'
    //         // sendBytes[9] = humanoidPortData['4b'].isPortSelected
    //         //     ? 'S'.charCodeAt(0)
    //         //     : 'O'.charCodeAt(0)
    //         sendBytes[9] = 'A'
    //         // sendBytes[9] = 'O'.charCodeAt(0)
    //         sendBytes[10] = humanoidPortData['21'].isPortSelected ? 'B' : 'O'
    //     }

    //     sendBytes = sendBytes.toString().replaceAll(',', '')
    //     return sendBytes
    // }

    handleReadByte_v1 = async () => {
        this.props.worker.onmessage = (e) => {
            if (e.data.type == 'PAread') this.recievedBytes_v1(e.data.value)
            if (e.data.type == 'RobokiPAread')
                this.recievedBytes_roboki(e.data.value)
            // if (e.data.type == 'PAreadKlaw')
            //     this.recievedBytes_Klaw(e.data.value)
        }
        const M8 = new Uint8Array(['M'.charCodeAt(0), '8'.charCodeAt(0)])
        this.setState({ isRead_v1: !this.state.isRead_v1 })
        sessionStorage.setItem('isRead', !this.state.isRead_v1)
        if (!this.state.isRead_v1 === false) {
            return
        }
        while (true) {
            if (
                !JSON.parse(sessionStorage.getItem('isRead')) ||
                !this.props.webSerial.isConnected
            ) {
                this.props.worker.postMessage({
                    type: 'write',
                    value: 'M8',
                })
                sessionStorage.removeItem('isRead')
                return
            } else {
                let sendPAbytes = ''
                if (sessionStorage.getItem('connectedDevice') === 'Roboki') {
                    sendPAbytes = this.setPABytes_Roboki()
                } else if (
                    sessionStorage.getItem('connectedDevice') == 'Klaw'
                ) {
                    sendPAbytes = this.setPABytes_Klaw()
                    // console.log(sendPA_Byte)
                } else {
                    sendPAbytes = this.setPABytes_V1()
                }
                this.props.worker.postMessage({
                    type: 'write',
                    value: sendPAbytes,
                })
                await new Promise((resolve) => setTimeout(resolve, 100))
            }
        }
    }

    recievedBytes_Pcv01 = (data) => {
        if (data === undefined) return
        data = data.filter((e) => e != 80 && e != 65)
        console.log(this.MSBLSBToDecimal(data[1], data[0]), 'gsk read')
        this.setState({
            zero: this.MSBLSBToDecimal(data[1], data[0]),
            one: this.MSBLSBToDecimal(data[5], data[4]),
            two: this.MSBLSBToDecimal(data[9], data[8]),
            rangeA1: this.MSBLSBToDecimal(data[1], data[0]),
            rangeA2: this.MSBLSBToDecimal(data[3], data[2]),
            rangeB1: this.MSBLSBToDecimal(data[5], data[4]),
            rangeB2: this.MSBLSBToDecimal(data[7], data[6]),
            rangeC1: this.MSBLSBToDecimal(data[9], data[8]),
            rangeC2: this.MSBLSBToDecimal(data[11], data[10]),
            rangeF1: data[12],
            rangeF2: data[13],
            red: data[14],
            green: data[15],
            blue: data[16],
            light: this.MSBLSBToDecimal(data[15], data[14]),
            distance: data[15],
            gesture: data[16],
            mic: this.MSBLSBToDecimal(data[18], data[17]),
            temprature: this.MSBLSBToDecimal(data[20], data[19]),
        })
    }
    recievedBytes_v1 = (bytesRecieved) => {
        console.log(bytesRecieved, 'recievedData')
        this.setState({
            rangeA1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 2),
        })

        this.setState({
            rangeA2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 3),
        })

        this.setState({
            rangeB1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 4),
        })

        this.setState({
            rangeB2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 5),
        })

        this.setState({
            rangeC1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 6),
        })

        this.setState({
            rangeC2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 7),
        })

        this.setState({
            rangeD1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 8),
        })

        this.setState({
            rangeD2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 9),
        })

        this.setState({
            rangeE1: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                10
            ),
        })

        this.setState({
            rangeE2: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                11
            ),
        })

        this.setState({
            rangeF1: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                12
            ),
        })

        this.setState({
            rangeF2: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                13
            ),
        })

        this.setState({
            mic: playComputerPABytesToSensorData(bytesRecieved.slice(2), 14),
        })

        this.setState({
            temprature: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                15
            ),
        })

        this.setState({
            zero: playComputerPABytesToSensorData(bytesRecieved.slice(2), 16),
        })

        this.setState({
            one: playComputerPABytesToSensorData(bytesRecieved.slice(2), 17),
        })

        this.setState({
            temp: playComputerPABytesToSensorData(bytesRecieved.slice(2), 18),
        })

        this.setState({
            two: playComputerPABytesToSensorData(bytesRecieved.slice(2), 19),
        })

        this.setState({
            light: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20L'
            ),
        })

        this.setState({
            distance: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20D'
            ),
        })

        this.setState({
            gesture: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20G'
            ),
        })

        this.setState({
            red: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20C'
            )[0],
        })

        this.setState({
            green: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20C'
            )[1],
        })

        this.setState({
            blue: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20C'
            )[2],
        })

        this.setState({
            accelerometer_X:
                Number(
                    playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '21X'
                    )
                ) - 32768,
        })

        this.setState({
            accelerometer_Y:
                Number(
                    playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '21Y'
                    )
                ) - 32768,
        })

        this.setState({
            accelerometer_Z:
                Number(
                    playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '21Z'
                    )
                ) - 32768,
        })

        this.setState({
            gyro_X:
                Number(
                    playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '21GX'
                    )
                ) - 2500,
        })

        this.setState({
            gyro_Y:
                Number(
                    playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '21GY'
                    )
                ) - 2500,
        })

        this.setState({
            gyro_Z:
                Number(
                    playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '21GZ'
                    )
                ) - 2500,
        })

        this.setState({
            battery: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                22
            ),
        })

        //for Ai tool read value

        this.setState({
            pcAiTool_read_value: pcAiTool_PABytesToSensorData(
                bytesRecieved.slice(2),
                2,
                this.state.readToggel,
                this.aiTrainList
            ), //playComputerPABytesToSensorData(bytesRecieved.slice(2), 2),
        })
    }

    //resived PA BYETS for roboki
    recievedBytes_roboki = (bytesRecieved) => {
        this.setState({
            rangeA1: robokiPABytesToSensorData(bytesRecieved.slice(2), 2),
            rangeA2: robokiPABytesToSensorData(bytesRecieved.slice(2), 3),
            rangeB1: robokiPABytesToSensorData(bytesRecieved.slice(2), 6),
            rangeB2: robokiPABytesToSensorData(bytesRecieved.slice(2), 7),
            switch1: robokiPABytesToSensorData(bytesRecieved.slice(2), 23),
            switch2: robokiPABytesToSensorData(bytesRecieved.slice(2), 24),
        })

        // this.setState({
        //     rangeA2: robokiPABytesToSensorData(bytesRecieved.slice(2), 3),
        // })

        // this.setState({
        //     rangeB1: robokiPABytesToSensorData(bytesRecieved.slice(2), 6),
        // })

        // this.setState({
        //     rangeB2: robokiPABytesToSensorData(bytesRecieved.slice(2), 7),
        // })

        // this.setState({
        //     switch1: robokiPABytesToSensorData(bytesRecieved.slice(2), 23),
        // })
        // this.setState({
        //     switch2: robokiPABytesToSensorData(bytesRecieved.slice(2), 24),
        // })
    }

    // recievedBytes_Klaw = (bytesRecieved) => {
    //     console.log(bytesRecieved, 'recievedData')
    //     if (!this.props.webSerial.isConnected) return
    //     this.setState({
    //         rangeA1: klawPABytesToSensorData(bytesRecieved, 31),
    //     })

    //     this.setState({
    //         rangeA2: klawPABytesToSensorData(bytesRecieved, 32),
    //     })

    //     this.setState({
    //         rangeB1: klawPABytesToSensorData(bytesRecieved, 33),
    //     })

    //     this.setState({
    //         rangeB2: klawPABytesToSensorData(bytesRecieved, 34),
    //     })

    //     this.setState({
    //         joystick1_X: klawPABytesToSensorData(bytesRecieved, 0.1),
    //     })
    //     this.setState({
    //         joystick1_Y: klawPABytesToSensorData(bytesRecieved, 0.2),
    //     })

    //     this.setState({
    //         joystick2_X: klawPABytesToSensorData(bytesRecieved, 1.1),
    //     })
    //     this.setState({
    //         joystick2_Y: klawPABytesToSensorData(bytesRecieved, 1.2),
    //     })
    //     this.setState({
    //         joystick3_X: klawPABytesToSensorData(bytesRecieved, 2.1),
    //     })
    //     this.setState({
    //         joystick3_Y: klawPABytesToSensorData(bytesRecieved, 2.2),
    //     })

    //     this.setState({
    //         switch1: klawPABytesToSensorData(bytesRecieved, 3),
    //     })

    //     this.setState({
    //         switch2: klawPABytesToSensorData(bytesRecieved, 4),
    //     })

    //     this.setState({
    //         switch3: klawPABytesToSensorData(bytesRecieved, 5),
    //     })

    //     console.log('my updated state', this.state)
    // }

    /////////////////////////////////////////////////////////////////////////////////////
    //resived PA BYETS for Klaw
    componentDidMount() {
        // console.log("CALLING componentDidMount :");
    }

    // call just after components updates
    // STORING RANGE/SEEK bar value with this method
    static getDerivedStateFromProps(props, state) {
        console.log('propsIF', props)

        let mainProps = props.state

        let data = props.state.source

        if (typeof data == 'string') {
            // OLD UI DATA for 4-in-1-sensor
            if (data.split(' ')[0] == '4-IN-1') {
                let myObj = rangeStoreVal['FOUR_in_ONE_Sensor'][`${data}`]
                myObj.condition = mainProps.condition
                myObj.value = mainProps.value
            }

            // NEW UI DATA BELOW
            if (data.split(' ')[0] == 'MICROPHONE') {
                let myObj = rangeStoreVal['MICROPHONE']
                myObj.condition = mainProps.condition
                myObj.value = mainProps.value
            }
            if (data.split(' ')[0] == 'TEMPERATURE') {
                let myObj = rangeStoreVal['TEMPERATURE']
                myObj.condition = mainProps.condition
                myObj.value = mainProps.value
            }
            if (data.split(' ')[0] == 'TOUCH ZERO') {
                let myObj = rangeStoreVal['TOUCH ZERO']
                myObj.condition = mainProps.condition
                myObj.value = mainProps.value
            }
            if (data.split(' ')[0] == 'TOUCH ONE') {
                let myObj = rangeStoreVal['TOUCH ONE']
                myObj.condition = mainProps.condition
                myObj.value = mainProps.value
            }
            if (data.split(' ')[0] == 'TOUCH TWO') {
                let myObj = rangeStoreVal['TOUCH TWO']
                myObj.condition = mainProps.condition
                myObj.value = mainProps.value
            }
            if (data.split(' ')[0] == '4-IN-1 SENSOR  →  DIST') {
                let myObj = rangeStoreVal['4-IN-1 SENSOR  →  DIST']
                myObj.condition = mainProps.condition
                myObj.value = mainProps.value
            }
            if (data.split(' ')[0] == '4-IN-1 SENSOR  →  GESTURE') {
                let myObj = rangeStoreVal['4-IN-1 SENSOR  →  GESTURE']
                myObj.condition = mainProps.condition
                myObj.value = mainProps.value
            }

            if (data.split(' ')[0] == '4-IN-1 SENSOR  →  LIGHT') {
                let myObj = rangeStoreVal['4-IN-1 SENSOR  →  LIGHT']
                myObj.condition = mainProps.condition
                myObj.value = mainProps.value
            }
            if (data.split(' ')[0] == '4-IN-1 SENSOR  →  RED') {
                let myObj = rangeStoreVal['4-IN-1 SENSOR  →  RED']
                myObj.condition = mainProps.condition
                myObj.value = mainProps.value
            }
            if (data.split(' ')[0] == '4-IN-1 SENSOR  →  BLUE') {
                let myObj = rangeStoreVal['4-IN-1 SENSOR  →  BLUE']
                myObj.condition = mainProps.condition
                myObj.value = mainProps.value
            }
            if (data.split(' ')[0] == '4-IN-1 SENSOR  →  GREEN') {
                let myObj = rangeStoreVal['4-IN-1 SENSOR  →  GREEN']
                myObj.condition = mainProps.condition
                myObj.value = mainProps.value
            }
        }
    }

    onChange = (key, value, value2) => {
        // alert(value, "---- ", value);
        console.log('hii', key, value, this.state['source'])
        const { state, onChange } = this.props
        if (state == 'repeat') {
            return
        }
        state[key] = value

        if (key == 'source') {
            if (
                [
                    'ai_color_detection',
                    // 'ai_color_tracking',
                    // 'ai_face_tracking',
                    'ai_face_detection',
                    'ai_face_recognition',
                    'ai_mic',
                ].includes(value)
            ) {
                state['condition'] = 'eq'
            } else {
                state['condition'] = 'gt'
            }
        }
        onChange(state, 'sensor')
    }

    onChangeRead = async (key, value) => {
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        console.log('value=====>', value, key)
        if (
            connectedDevice === 'Ace' &&
            (value.includes('RED') ||
                value.includes('BLUE') ||
                value.includes('GREEN') ||
                value.includes('LIGHT'))
        ) {
            sessionStorage.setItem('isRead', false)
            this.setState({ isRead_v1: false })
            sendBytes(
                ['M'.charCodeAt(0), '8'.charCodeAt(0)],
                this.props.webSerial.port
            )
        }
        this.setState({ readToggel: value.trim() })
        // state[key] = value;
    }
    // laxmi handle

    handleOperators = (name) => {
        switch (name) {
            case 'greaterThan': {
                if (this.state.isGraterThan) {
                    this.setState(
                        {
                            isGraterThan: false,
                        },
                        () => {
                            this.onChange('condition', 'gt')
                        }
                    )
                } else {
                    this.setState(
                        {
                            isGraterThan: true,
                            isLessThan: false,
                            isEqualTo: false,
                            isNotequalTo: false,
                            isInBtween: false,
                        },
                        () => {
                            this.onChange('condition', 'gt')
                        }
                    )
                }
                break
            }

            case 'lessThan': {
                if (this.state.isLessThan) {
                    // this.onChange("condition", "lt");

                    this.setState(
                        {
                            isLessThan: false,
                        },
                        () => {
                            this.onChange('condition', 'lt')
                        }
                    )
                } else {
                    this.setState(
                        {
                            isGraterThan: false,
                            isLessThan: true,
                            isEqualTo: false,
                            isNotequalTo: false,
                            isInBtween: false,
                        },
                        () => {
                            this.onChange('condition', 'lt')
                        }
                    )
                }
                break
            }
            case 'equalTo': {
                if (this.state.isEqualTo) {
                    // this.onChange("condition", "eq");

                    this.setState(
                        {
                            isEqualTo: false,
                        },
                        () => {
                            this.onChange('condition', 'eq')
                        }
                    )
                } else {
                    this.setState(
                        {
                            isGraterThan: false,
                            isLessThan: false,
                            isEqualTo: true,
                            isNotequalTo: false,
                            isInBtween: false,
                        },
                        () => {
                            this.onChange('condition', 'eq')
                        }
                    )
                }
                break
            }
            case 'notEqualTo': {
                if (this.state.isNotequalTo) {
                    // this.onChange("condition", "ne");

                    this.setState(
                        {
                            isNotequalTo: false,
                        },
                        () => {
                            this.onChange('condition', 'ne')
                        }
                    )
                } else {
                    this.setState(
                        {
                            isGraterThan: false,
                            isLessThan: false,
                            isEqualTo: false,
                            isNotequalTo: true,
                            isInBtween: false,
                        },
                        () => {
                            this.onChange('condition', 'ne')
                        }
                    )
                }
                break
            }
            case 'inBetween': {
                if (this.state.isInBtween) {
                    // this.onChange("condition", "bw");

                    this.setState(
                        {
                            isInBtween: false,
                        },
                        () => {
                            this.onChange('condition', 'bw')
                        }
                    )
                } else {
                    this.setState(
                        {
                            isGraterThan: false,
                            isLessThan: false,
                            isEqualTo: false,
                            isNotequalTo: false,
                            isInBtween: true,
                        },
                        () => {
                            this.onChange('condition', 'bw')
                        }
                    )
                }
                break
            }
        }
    }

    handleRead = (e) => {
        this.setState({ isRead: !this.state.isRead })
        this.handleReadByte()
        // console.log('READ FRAUD', this.state.isRead)

        // if (this.state.isRead) {
        //     console.log('READER CANCELED')
        //     reader.cancel()
        // } else {
        //     this.readdata()
        // }
    }
    render() {
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        let aiTrainList = {}
        try {
            aiTrainList = JSON.parse(localStorage.getItem('aiTrainList'))
        } catch (e) {
            console.log('ERROR', e)
        }
        console.log('IF CONDITION PROPS', this.state, this.props.nodeId)
        if (['Humanoid', 'Hexapod', 'Klaw'].includes(connectedDevice)) {
            return (
                <>
                    {this.props.logic.bottomPanel === 'show' &&
                        this.props.nodeId && (
                            <DecisionPanel
                                check={this.props.nodeId}
                                showImage={this.props.showImage}
                            />
                        )}
                </>
            )
        }
        const { state, startState, PortConnections, componentProps } =
            this.props
        var portsConnectedArray = []
        for (var eachConnection in PortConnections) {
            portsConnectedArray.push(eachConnection)
        }

        for (var n = 0; n < portsConnectedArray.length; n++) {
            if (
                portsConnectedArray[n].length == 1 &&
                PortConnections[portsConnectedArray[n]]
            ) {
                if (
                    PortConnections[portsConnectedArray[n]].type !=
                    'dual_splitter'
                ) {
                    if (
                        PortConnections[portsConnectedArray[n]].type ==
                            'ultrasonic_sensor' ||
                        PortConnections[portsConnectedArray[n]].type ==
                            'rotatory'
                    ) {
                        PortConnections[portsConnectedArray[n] + '1'] =
                            PortConnections[portsConnectedArray[n]]
                    }
                    PortConnections[portsConnectedArray[n]] = null
                }
            }
        }

        var sourceOptionsOrder = [],
            readSourceOptionsOrder = [],
            sourceOptions = {}
        sourceOptionsOrder.push('battery')
        readSourceOptionsOrder.push('battery')
        sourceOptions.battery = 'Select Items'
        // if (this.props.state.source != "battery") {
        //   sourceOptionsOrder.pop("battery");
        // }
        Object.keys(PortConnections).forEach((port) => {
            if (port != 'undefined' && PortConnections[port]) {
                if (!PortConnections[port]) return
                var type = PortConnections[port].type

                if (type == 'pcAiTool') {
                    console.log(
                        'XXXXXXXXX aiItems',
                        this.props.assembly.assemblyPageAi.aiItems
                    )
                    let aiItems = this.props.assembly.assemblyPageAi.aiItems
                    // let aiTrainList = JSON.parse(
                    //     localStorage.getItem('aiTrainList')
                    // )
                    Object.keys(aiItemsData).map((objKey) => {
                        if (
                            aiItemsData[objKey].type === 'input' &&
                            aiItems[aiItemsData[objKey].reduxCheck]
                        ) {
                            if (
                                [
                                    'ai_face_tracking_X',
                                    'ai_face_tracking_Y',
                                    'ai_color_tracking_X',
                                    'ai_color_tracking_Y',
                                ].includes(objKey)
                            ) {
                                sourceOptionsOrder.push(objKey)
                                readSourceOptionsOrder.push(objKey)
                                aiTrainList?.[aiItemsData[objKey].listKey]?.map(
                                    (ele, ind) => {
                                        if (ele.isUpload)
                                            sourceOptionsOrder.push(
                                                `${objKey}${ind + 1}`
                                            )
                                    }
                                )
                            } else {
                                sourceOptionsOrder.push(objKey)
                                readSourceOptionsOrder.push(objKey)
                            }
                        }
                    })
                    //aiItemsData
                } else if (IOComponents[type].input) {
                    console.log('111', port)
                    console.log('111', componentProps[type].name)

                    if (type == 'temp_dew') {
                        sourceOptionsOrder.push(port)
                        readSourceOptionsOrder.push(port)
                        if (port == 'A1' || port == 'B1' || port == 'C1') {
                            sourceOptions[port] = 'TEMP' + ' \u2192 ' + port
                        } else if (
                            port == 'A2' ||
                            port == 'B2' ||
                            port == 'C2'
                        ) {
                            sourceOptions[port] = 'DEW' + ' \u2192 ' + port
                        } else {
                            sourceOptionsOrder.push(port)
                            readSourceOptionsOrder.push(port)
                            sourceOptions[port] =
                                componentProps[type].name + ' \u2192 ' + port
                        }
                    } else if (type == 'temp_gas') {
                        sourceOptionsOrder.push(port)
                        readSourceOptionsOrder.push(port)
                        if (port == 'A1' || port == 'B1' || port == 'C1') {
                            sourceOptions[port] = 'TEMP' + ' \u2192 ' + port
                        } else if (
                            port == 'A2' ||
                            port == 'B2' ||
                            port == 'C2'
                        ) {
                            sourceOptions[port] = 'GAS' + ' \u2192 ' + port
                        }
                    } else {
                        sourceOptionsOrder.push(port)
                        readSourceOptionsOrder.push(port)
                        sourceOptions[port] =
                            componentProps[type].name + ' \u2192 ' + port
                    }
                }
            }
        })

        // laxmi sessionstorage

        let sessiondataassemblyCheckbox = JSON.parse(
            sessionStorage.getItem('assemblyCheckbox')
        )
        let sessiondataLogic = JSON.parse(sessionStorage.getItem('logic'))
        let version = sessionStorage.getItem('deviceVersion')

        if (
            sessiondataLogic.bottomPanel == 'border' &&
            this.state.isRead_v1 == true &&
            (version?.startsWith('1') ||
                ['Roboki'].includes(sessionStorage.getItem('connectedDevice')))
        ) {
            this.handleReadByte_v1()
        }
        if (
            sessiondataLogic.bottomPanel == 'border' &&
            this.state.isRead == true &&
            !version?.startsWith('1')
        ) {
            console.log('read', this.state.isRead)
            this.setState({ isRead: !this.state.isRead })
            try {
                reader.cancel()
            } catch (e) {}
        }
        // NEW UI DATA
        let internalaccessoriesData = JSON.parse(
            sessionStorage.getItem('concept')
        ).internalaccessories
        console.log(
            '+++++++++++++++++++++++++++++++++++++',
            internalaccessoriesData
        )

        //NEW UI 4-in-1-sensor
        let internalaccessoriesData_Four_in_One_sensor = JSON.parse(
            sessionStorage.getItem('concept')
        ).internalaccessories.Four_in_one_sensor
        // console.log(
        //     'LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL',
        //     internalaccessoriesData
        // )

        // OLD UI DATA 4in1sensore
        if (sessiondataassemblyCheckbox != null) {
            Object.keys(sessiondataassemblyCheckbox).map((val, index) => {
                if (val == 'FOUR_in_ONE_Sensor') {
                    let isChecked = sessiondataassemblyCheckbox[val]

                    if (isChecked == true) {
                        var positionPorts = [
                            '4-IN-1 SENSOR  \u2192  RED',
                            '4-IN-1 SENSOR  \u2192  GREEN',
                            '4-IN-1 SENSOR  \u2192  BLUE',
                            '4-IN-1 SENSOR  \u2192  DIST',
                            '4-IN-1 SENSOR  \u2192  LIGHT',
                            '4-IN-1 SENSOR  \u2192  MOTION',
                        ]
                        for (var i = 0; i < positionPorts.length; i++) {
                            sourceOptionsOrder.push(positionPorts[i])
                            readSourceOptionsOrder.push(positionPorts[i])
                            // sourceOptions[positionPorts[i]] =
                            //   componentProps[type].name + " \u2192 " + positionPorts[i];
                        }
                    }
                }
            })
        }

        // NEW UI MENU DISPLAY
        Object.keys(internalaccessoriesData).map((val, index) => {
            if (val == 'isTouchZero') {
                if (internalaccessoriesData[val]) {
                    var positionPorts = ['TouchZero']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                        readSourceOptionsOrder.push(positionPorts[i])
                    }
                }
            }
            if (val == 'isTouchOne') {
                if (internalaccessoriesData[val]) {
                    var positionPorts = ['TouchOne']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                        readSourceOptionsOrder.push(positionPorts[i])
                    }
                }
            }
            if (val == 'isTouchTwo') {
                if (internalaccessoriesData[val]) {
                    var positionPorts = ['TouchTwo']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                        readSourceOptionsOrder.push(positionPorts[i])
                    }
                }
            }

            if (val == 'isTouchThree') {
                if (internalaccessoriesData[val]) {
                    var positionPorts = ['TouchThree']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                        readSourceOptionsOrder.push(positionPorts[i])
                    }
                }
            }

            if (val == 'isMic') {
                if (internalaccessoriesData[val]) {
                    var positionPorts = ['MICROPHONE']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                        readSourceOptionsOrder.push(positionPorts[i])
                    }
                }
            }

            if (val == 'isTemperature') {
                if (internalaccessoriesData[val]) {
                    var positionPorts = ['TEMPERATURE']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                        readSourceOptionsOrder.push(positionPorts[i])
                    }
                }
            }

            if (val == 'isAccelerometer') {
                if (internalaccessoriesData[val]) {
                    var positionPorts = [
                        'ACCELEROMETER_X',
                        'ACCELEROMETER_Y',
                        'ACCELEROMETER_Z',
                        'GYRO_X',
                        'GYRO_Y',
                        'GYRO_Z',
                    ]
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                        readSourceOptionsOrder.push(positionPorts[i])
                    }
                }
            }

            if (val == 'isBattery') {
                if (internalaccessoriesData[val]) {
                    var positionPorts = ['BATTERY']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                        readSourceOptionsOrder.push(positionPorts[i])
                    }
                }
            }
            if (val == 'isSwitch1') {
                if (internalaccessoriesData[val]) {
                    var positionPorts = ['Switch_One']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                        readSourceOptionsOrder.push(positionPorts[i])
                    }
                }
            }
            if (val == 'isSwitch2') {
                if (internalaccessoriesData[val]) {
                    var positionPorts = ['Switch_Two']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                        readSourceOptionsOrder.push(positionPorts[i])
                    }
                }
            }

            //for klaw
            // if (val == 'isJoystick1') {
            //     if (internalaccessoriesData[val]) {
            //         var positionPorts = ['Joystick1_X', 'Joystick1_Y']
            //         for (var i = 0; i < positionPorts.length; i++) {
            //             sourceOptionsOrder.push(positionPorts[i])
            //             readSourceOptionsOrder.push(positionPorts[i])
            //         }
            //     }
            // }
            // if (val == 'isJoystick2') {
            //     if (internalaccessoriesData[val]) {
            //         var positionPorts = ['Joystick2_X', 'Joystick2_Y']
            //         for (var i = 0; i < positionPorts.length; i++) {
            //             sourceOptionsOrder.push(positionPorts[i])
            //             readSourceOptionsOrder.push(positionPorts[i])
            //         }
            //     }
            // }
            // if (val == 'isJoystick2') {
            //     if (internalaccessoriesData[val]) {
            //         var positionPorts = ['Joystick3_X', 'Joystick3_Y']
            //         for (var i = 0; i < positionPorts.length; i++) {
            //             sourceOptionsOrder.push(positionPorts[i])
            //             readSourceOptionsOrder.push(positionPorts[i])
            //         }
            //     }
            // }
            // if (val == 'isSwitch1') {
            //     if (internalaccessoriesData[val]) {
            //         var positionPorts = ['Switch1']
            //         for (var i = 0; i < positionPorts.length; i++) {
            //             sourceOptionsOrder.push(positionPorts[i])
            //             readSourceOptionsOrder.push(positionPorts[i])
            //         }
            //     }
            // }
            // if (val == 'isSwitch2') {
            //     if (internalaccessoriesData[val]) {
            //         var positionPorts = ['Switch2']
            //         for (var i = 0; i < positionPorts.length; i++) {
            //             sourceOptionsOrder.push(positionPorts[i])
            //             readSourceOptionsOrder.push(positionPorts[i])
            //         }
            //     }
            // }
            // if (val == 'isSwitch3') {
            //     if (internalaccessoriesData[val]) {
            //         var positionPorts = ['Switch3']
            //         for (var i = 0; i < positionPorts.length; i++) {
            //             sourceOptionsOrder.push(positionPorts[i])
            //         }
            //     }
            // }
        })
        Object.keys(internalaccessoriesData_Four_in_One_sensor).map(
            (val, index) => {
                if (val == 'isDistanceSensors') {
                    if (internalaccessoriesData_Four_in_One_sensor[val]) {
                        var positionPorts = ['4-IN-1 SENSOR  \u2192  DIST']
                        for (var i = 0; i < positionPorts.length; i++) {
                            sourceOptionsOrder.push(positionPorts[i])
                            readSourceOptionsOrder.push(positionPorts[i])
                        }
                    }
                }
                // laxmi gesture
                if (val == 'isGestureSensor') {
                    if (internalaccessoriesData_Four_in_One_sensor[val]) {
                        var positionPorts = ['4-IN-1 SENSOR  \u2192  GESTURE']
                        for (var i = 0; i < positionPorts.length; i++) {
                            sourceOptionsOrder.push(positionPorts[i])
                            readSourceOptionsOrder.push(positionPorts[i])
                        }
                    }

                    //   this.state.gesture=="1"?<p>Up</p>:this.state.gesture=="2"?
                    // <p>Down</p>:this.state.gesture=="3"?<p>Left</p>:this.state.gesture=="4"?
                    // <p>Right</p>:<p></p>}
                }
                if (val == 'isLightSensor') {
                    if (internalaccessoriesData_Four_in_One_sensor[val]) {
                        var positionPorts = ['4-IN-1 SENSOR  \u2192  LIGHT']
                        for (var i = 0; i < positionPorts.length; i++) {
                            sourceOptionsOrder.push(positionPorts[i])
                            readSourceOptionsOrder.push(positionPorts[i])
                        }
                    }
                }

                if (val == 'isColorSensor') {
                    if (internalaccessoriesData_Four_in_One_sensor[val]) {
                        var positionPorts = [
                            '4-IN-1 SENSOR  \u2192  RED',
                            '4-IN-1 SENSOR  \u2192  BLUE',
                            '4-IN-1 SENSOR  \u2192  GREEN',
                        ]
                        for (var i = 0; i < positionPorts.length; i++) {
                            sourceOptionsOrder.push(positionPorts[i])
                            readSourceOptionsOrder.push(positionPorts[i])
                        }
                    }
                }
            }
        )

        //sourceOptionsOrder.push('irr');
        //sourceOptions.irr = 'IR Remote \u2192 IR';
        Object.keys(startState).forEach((name) => {
            if (name == 'usbrx') {
                if (startState[name]) {
                    var positionPorts = ['USB RX']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                    }
                }
            }
            if (name == 'btRx') {
                if (startState[name]) {
                    var positionPorts = ['BT RX1']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                    }
                }
                if (startState[name]) {
                    var positionPorts = ['BT RX2']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                    }
                }
                if (startState[name]) {
                    var positionPorts = ['BT RX3']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                    }
                }
                if (startState[name]) {
                    var positionPorts = ['BT RX4']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                    }
                }
                if (startState[name]) {
                    var positionPorts = ['BT RX5']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                    }
                }
            }

            if (name === 'btRte') {
                if (startState[name]) {
                    var positionPorts = ['BT Remote']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                    }
                }
            } else if (name == 'bic1') {
                if (startState[name]) {
                    var positionPorts = ['Bicounter1']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                    }
                }

                // sourceOptionsOrder.push("Bicounter1");
            } else if (name == 'bic2') {
                if (startState[name]) {
                    var positionPorts = ['Bicounter2']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                    }
                }
            } else if (name == 'bif1') {
                if (startState[name]) {
                    var positionPorts = ['Biflag1']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                    }
                }
            } else if (name == 'bif2') {
                if (startState[name]) {
                    var positionPorts = ['Biflag2']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                    }
                }
            } else if (name == 'bid1') {
                if (startState[name]) {
                    var positionPorts = ['BiData1']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                    }
                }
            } else if (name == 'bid2') {
                if (startState[name]) {
                    var positionPorts = ['BiData2']
                    for (var i = 0; i < positionPorts.length; i++) {
                        sourceOptionsOrder.push(positionPorts[i])
                    }
                }
            }
        })

        if (this.props.startState && this.props.startState.slider) {
            sourceOptions.slider = 'BT Slider'
        }

        if (this.props.startState && this.props.startState.bts) {
            sourceOptionsOrder.push('remote')
            sourceOptions.remote = 'BT Speech'
        }
        if (this.props.startState && this.props.startState.btr) {
            sourceOptionsOrder.push('remote')
            sourceOptions.remote = 'BT Remote'
        }

        // sourceOptionsOrder.push("battery");
        // sourceOptions.battery = "Select Items";
        // if (this.props.state.source != "battery") {
        //   sourceOptionsOrder.pop("battery");
        // }

        // sourceOptionsOrder.push('timeElapsed');
        // sourceOptions.timeElapsed = 'Time elapsed(sec)';
        // sourceOptionsOrder.push('time');
        // sourceOptions.time = 'Time';

        if (this.props.startState && this.props.startState.iot) {
            for (var i = 1; i <= 10; i++) {
                // sourceOptionsOrder.push('IOT' + i);
                sourceOptions['IOT' + i] = 'IOT' + i
            }
        }
        var conditions,
            max,
            min = 0,
            source = state.source || 'battery'

        conditions = {
            lt: 'Less than',
            gt: 'Greater than',
            eq: 'Equal to',
            ne: 'Not equal to',
        }

        if (source != 'timeElapsed') {
            conditions['bw'] = 'In between'
            //conditions['nbw']='Not in between';
        }
        // if (sourceOptionsOrder.indexOf(source) < 0) source = "slider";
        if (
            source === 'irr' ||
            source.startsWith('bic') ||
            source.startsWith('bid') ||
            source.startsWith('IOT')
        )
            max = 65535
        else if (
            source === 'slider' ||
            source === 'remote' ||
            source === 'time'
        )
            max = 255
        else if (source === 'timeElapsed') max = 2147483
        else if (source === 'battery') max = 0
        else if (source.startsWith('Bif')) max = 1
        else {
            var componentType = localStorage.getItem('biboxTypes')

            // console.log(componentType, "khushboo LOCAL STORAGE");

            var Originalport
            var compName

            //change it to R again
            if (
                source == 'R' ||
                source == 'G' ||
                source == 'B' ||
                source == 'D' ||
                source == 'M' ||
                source == 'L'
            ) {
                Originalport = 'G1'
            } else Originalport = source

            var range
            if (Originalport != undefined || Originalport != null) {
                // OLD UI DATA 4-in1-Sensor
                if (
                    // Originalport == "4-IN-1 SENSOR  →  RED" ||
                    // Originalport == "4-IN-1 SENSOR  →  GREEN" ||
                    // Originalport == "4-IN-1 SENSOR  →  BLUE" ||
                    Originalport == '4-IN-1 SENSOR  →  MOTION'
                ) {
                    let data = 'FOUR_in_ONE_Sensor'

                    const str = Originalport
                    const words = str.split(' ')
                    const ports = words[words.length - 1]

                    let activePort = `FOUR_in_ONE_Sensor_${ports}`

                    range = PortValuesRangeMapping[data][activePort]()

                    // let obj = (range = PortValuesRangeMapping[FOUR_in_ONE_Sensor][
                    //   Originalport
                    // ]());
                    // console.log(range);
                    // let comp = PortConnections[Originalport].type;
                } else if (Originalport == 'Switch_One') {
                    range = PortValuesRangeMapping[Originalport]()
                } else if (Originalport == 'Switch_Two') {
                    range = PortValuesRangeMapping[Originalport]()
                }
                // NEW UI 4-in-1Sensor data
                else if (Originalport == 'MICROPHONE') {
                    range = PortValuesRangeMapping[Originalport]()
                } else if (Originalport == 'TEMPERATURE') {
                    range = PortValuesRangeMapping[Originalport]()
                } else if (Originalport == 'BATTERY') {
                    range = PortValuesRangeMapping[Originalport]()
                } else if (Originalport == 'ACCELEROMETER_X') {
                    range = PortValuesRangeMapping[Originalport]()
                } else if (Originalport == 'ACCELEROMETER_Y') {
                    range = PortValuesRangeMapping[Originalport]()
                } else if (Originalport == 'ACCELEROMETER_Z') {
                    range = PortValuesRangeMapping[Originalport]()
                } else if (Originalport == 'GYRO_X') {
                    range = PortValuesRangeMapping[Originalport]()
                } else if (Originalport == 'GYRO_Y') {
                    range = PortValuesRangeMapping[Originalport]()
                } else if (Originalport == 'GYRO_Z') {
                    range = PortValuesRangeMapping[Originalport]()
                } else if (Originalport == 'TouchZero') {
                    range = PortValuesRangeMapping['TOUCHZERO']()
                } else if (Originalport == 'TouchOne') {
                    range = PortValuesRangeMapping['TOUCHONE']()
                } else if (Originalport == 'TouchTwo') {
                    range = PortValuesRangeMapping['TOUCHTWO']()
                } else if (Originalport == 'TouchThree') {
                    range = PortValuesRangeMapping['TOUCHTHREE']()
                } else if (Originalport == '4-IN-1 SENSOR  →  DIST') {
                    range = PortValuesRangeMapping['FOUR_in_ONE_Sensor_DIST_']()
                } else if (Originalport == '4-IN-1 SENSOR  →  GESTURE') {
                    range =
                        PortValuesRangeMapping['FOUR_in_ONE_Sensor_GESTURE_']()
                } else if (Originalport == '4-IN-1 SENSOR  →  LIGHT') {
                    range =
                        PortValuesRangeMapping['FOUR_in_ONE_Sensor_LIGHT_']()
                } else if (Originalport == '4-IN-1 SENSOR  →  RED') {
                    range = PortValuesRangeMapping['FOUR_in_ONE_Sensor_RED_']()
                } else if (Originalport == '4-IN-1 SENSOR  →  BLUE') {
                    range = PortValuesRangeMapping['FOUR_in_ONE_Sensor_BLUE_']()
                } else if (Originalport == '4-IN-1 SENSOR  →  GREEN') {
                    range =
                        PortValuesRangeMapping['FOUR_in_ONE_Sensor_GREEN_']()
                } else if (Originalport == 'USB RX') {
                    range = PortValuesRangeMapping['USBRX']()
                } else if (Originalport == 'BT RX1') {
                    range = PortValuesRangeMapping['BTRX1']()
                } else if (Originalport == 'BT RX2') {
                    range = PortValuesRangeMapping['BTRX2']()
                } else if (Originalport == 'BT RX3') {
                    range = PortValuesRangeMapping['BTRX3']()
                } else if (Originalport == 'BT RX4') {
                    range = PortValuesRangeMapping['BTRX4']()
                } else if (Originalport == 'BT RX5') {
                    range = PortValuesRangeMapping['BTRX5']()
                } else if (Originalport == 'Bicounter1') {
                    range = PortValuesRangeMapping['BIC1']()
                } else if (Originalport == 'Bicounter2') {
                    range = PortValuesRangeMapping['BIC2']()
                } else if (Originalport == 'Biflag1') {
                    range = PortValuesRangeMapping['BIF1']()
                } else if (Originalport == 'Biflag2') {
                    range = PortValuesRangeMapping['BIF2']()
                } else if (Originalport == 'BiData1') {
                    range = PortValuesRangeMapping['BID1']()
                } else if (Originalport == 'BiData2') {
                    range = PortValuesRangeMapping['BID2']()
                } else if (Originalport == 'BT Remote') {
                    range = { min: 0, max: 255 }
                } else if (
                    [
                        'ai_motion',
                        'ai_color_detection',
                        'ai_face_detection',
                        'ai_face_recognition',
                        'ai_mic',
                        'ai_lidar',
                    ].includes(Originalport) ||
                    Originalport.includes('ai_face_tracking_X') || //ai_face_tracking_X, ai_color_tracking_X
                    Originalport.includes('ai_face_tracking_Y') ||
                    Originalport.includes('ai_color_tracking_X') ||
                    Originalport.includes('ai_color_tracking_Y')
                ) {
                    if (
                        ['ai_motion', 'ai_face_detection', 'ai_lidar'].includes(
                            Originalport
                        ) ||
                        Originalport.includes('ai_face_tracking_X') ||
                        Originalport.includes('ai_face_tracking_Y') ||
                        Originalport.includes('ai_color_tracking_X') ||
                        Originalport.includes('ai_color_tracking_Y')
                    ) {
                        let newOriginalport = Originalport
                        if (Originalport.includes('ai_face_tracking_X')) {
                            newOriginalport = 'ai_face_tracking_X'
                        } else if (
                            Originalport.includes('ai_face_tracking_Y')
                        ) {
                            newOriginalport = 'ai_face_tracking_Y'
                        } else if (
                            Originalport.includes('ai_color_tracking_X')
                        ) {
                            newOriginalport = 'ai_color_tracking_X'
                        } else if (
                            Originalport.includes('ai_color_tracking_Y')
                        ) {
                            newOriginalport = 'ai_color_tracking_Y'
                        }
                        range = {
                            min: aiItemsData[newOriginalport].min,
                            max: aiItemsData[newOriginalport].max,
                        }
                        // range.max = aiItemsData[Originalport].max
                    }
                }
                // else if (
                //     Originalport.includes('Joystick1') ||
                //     Originalport.includes('Joystick2') ||
                //     Originalport.includes('Joystick3')
                // ) {
                //     range = { min: 0, max: 4095 }
                // } else if (
                //     Originalport == 'Switch1' ||
                //     Originalport == 'Switch2' ||
                //     Originalport == 'Switch3'
                // ) {
                //     range = { min: 0, max: 1 }
                // }
                else {
                    var comp = PortConnections[Originalport].type

                    var compName = comp.toLowerCase()
                    range =
                        PortValuesRangeMapping[Originalport][compName](
                            Originalport
                        )
                }
            } else {
                var comp = PortConnections[source].type
                var compName = comp.toLowerCase()
                var range = PortValuesRangeMapping[source][compName](source)
            }
            if (
                ![
                    'ai_color_detection',
                    'ai_face_recognition',
                    'ai_mic',
                ].includes(Originalport)
            ) {
                min = range.min
                max = range.max
            }
            // commented for tern+
            // if (source == "D" || source == "M" || source == "L") {
            //   min = 0;
            //   max = 4;
            // }

            // if (source == "B2" || source == "B3" || source == "B4") {
            //     if (PortConnections[source]) {
            //         if (PortConnections[source].type == "tact_switch" || PortConnections[source].type == "tact_switch" || PortConnections[source].type == "tact_switch") {
            //             min = 0;
            //             max = 1;
            //         }
            //     }

            // }
        }

        if (state.value > max) state.value = max

        const ganaretVal = () => {
            // let newVal = value
            if (state.source == 'ai_face_recognition') {
                // newVal = aiTrainList?.faceTranList[value]?.id
                return {
                    //finde index of the object in the faceTranList array
                    sliderVal1:
                        aiTrainList?.faceTranList?.findIndex(
                            (obj) => obj?.id === state.value
                        ) + 1 || 0,
                    sliderVal2: state.value2 || 0,
                    conditionValue: state.condition,
                }
            }
            if (
                connectedDevice === 'Ace' &&
                JSON.parse(sessionStorage.getItem('shield'))
            ) {
                if (
                    [
                        'TouchZero',
                        'TouchOne',
                        'TouchTwo',
                        'TouchThree',
                    ].includes(this.props.state.source)
                ) {
                    return {
                        sliderVal1: 0,
                        sliderVal2: 0,
                        conditionValue: 'gt',
                    }
                } else {
                    return {
                        sliderVal1: state.value || 0,
                        sliderVal2: state.value2 || 0,
                        conditionValue: state.condition,
                    }
                }
            } else {
                return {
                    sliderVal1: state.value || 0,
                    sliderVal2: state.value2 || 0,
                    conditionValue: state.condition,
                }
            }
        }
        var ifOutputRow1 = (
                <div>
                    <div
                        style={{
                            top: '50%',
                            width: '100%',
                            position: 'absolute',
                            transform: 'translate(0, -50%)',
                        }}
                    >
                        <Slider
                            // value={state.value || 0}
                            value={ganaretVal().sliderVal1}
                            onChange={(value) => {
                                this.onChange('value', value)
                            }}
                            max={max}
                            min={min}
                            renderIn="conditionPropertyPanel"
                            sensor={source}
                        />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: '57.5%',
                            left: '31%',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <p
                            style={{
                                position: 'absolute',
                                color: 'black',
                            }}
                        >
                            {min}
                        </p>
                    </div>

                    <div
                        style={{
                            position: 'absolute',
                            top: '57.5%',
                            left: '83%',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <p
                            style={{
                                position: 'absolute',
                                color: 'black',
                            }}
                        >
                            {max}
                        </p>
                    </div>
                </div>
            ),
            ifOutputRow2 = '',
            display = 'inline-block'

        var defaultCssStyle = {
            height: '50px',
            width: '90%',
            marginLeft: '20%',
        }
        if (
            ganaretVal().conditionValue == 'bw' ||
            ganaretVal().conditionValue == 'nbw'
        ) {
            ifOutputRow2 = (
                <>
                    <h5
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '16px',
                            color: 'black',
                            top: '60%',
                            left: '57%',
                            width: '100%',
                            position: 'absolute',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        and
                    </h5>

                    <div
                        style={{
                            top: '65%',
                            width: '100%',
                            position: 'absolute',
                            transform: 'translate(0, -50%)',
                        }}
                        // style={{ height: '5px', width: '90%' }}
                    >
                        <Slider
                            // value={state.value2 || 0}
                            value={ganaretVal().sliderVal2}
                            onChange={(value) => {
                                this.onChange('value2', value)
                            }}
                            max={max}
                            min={min}
                            renderIn="conditionPropertyPanel"
                            sensor={source}
                        />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: '73.3%',
                            left: '31%',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <p
                            style={{
                                position: 'absolute',
                                color: 'black',
                            }}
                        >
                            {min}
                        </p>
                    </div>

                    <div
                        style={{
                            position: 'absolute',
                            top: '73.3%',
                            left: '83%',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <p
                            style={{
                                position: 'absolute',
                                color: 'black',
                            }}
                        >
                            {max}
                        </p>
                    </div>
                </>
            )
        } else {
            ifOutputRow2 = ''
            // ifOutputRow1 = (
            //     <div>
            //         <Slider
            //             value={state.value || 0}
            //             onChange={(value) => this.onChange('value', value)}
            //             max={max}
            //             min={min}
            //             renderIn="conditionPropertyPanel"
            //             sensor={source}
            //         />
            //         <div
            //             style={{
            //                 position: 'absolute',
            //                 top: '67%',
            //                 left: '31%',
            //                 transform: 'translate(-50%,-50%)',
            //             }}
            //         >
            //             <p
            //                 style={{
            //                     position: 'absolute',

            //                     color: 'black',
            //                 }}
            //             >
            //                 {min}
            //             </p>
            //         </div>

            //         <div
            //             style={{
            //                 position: 'absolute',
            //                 top: '67%',
            //                 left: '83%',
            //                 transform: 'translate(-50%,-50%)',
            //             }}
            //         >
            //             {' '}
            //             <p
            //                 style={{
            //                     position: 'absolute',
            //                     color: 'black',
            //                 }}
            //             >
            //                 {max}
            //             </p>
            //         </div>
            //     </div>
            // )
        }

        if (source == 'time') {
            if (state.condition == 'bw' || state.condition == 'nbw') {
                ifOutputRow2 = ''
            } else {
                display = 'none'
                defaultCssStyle = {
                    height: '50px',
                    width: '90%',
                    marginLeft: '40%',
                }
            }
            ifOutputRow1 = (
                <div style={defaultCssStyle}>
                    <span
                        style={{
                            color: 'white',
                            fontWeight: 'bold',
                        }}
                    >
                        {'Hours'}
                    </span>
                    <Select
                        onChange={(value) => {
                            this.onChange('hour', value)
                        }}
                        color={HexTypes['if'].color}
                        options={hours}
                        selected={state.hour}
                    />
                    <span
                        style={{
                            color: 'white',
                            fontWeight: 'bold',
                        }}
                    >
                        {'Min'}
                    </span>
                    <Select
                        onChange={(value) => {
                            this.onChange('minute', value)
                        }}
                        color={HexTypes['if'].color}
                        options={minutes}
                        selected={state.minute}
                    />
                    <span
                        style={{
                            marginLeft: '20%',
                            color: 'white',
                            fontWeight: 'bold',
                            display: display,
                        }}
                    >
                        {'Hours'}
                    </span>
                    <Select
                        style={{ display: display }}
                        onChange={(value) => {
                            this.onChange('hour2', value)
                        }}
                        color={HexTypes['if'].color}
                        options={hours}
                        selected={state.hour2}
                    />
                    <span
                        style={{
                            color: 'white',
                            fontWeight: 'bold',
                            display: display,
                        }}
                    >
                        {'Min'}
                    </span>
                    <Select
                        style={{ display: display }}
                        onChange={(value) => {
                            this.onChange('minute2', value)
                        }}
                        color={HexTypes['if'].color}
                        options={minutes}
                        selected={state.minute2}
                    />
                </div>
            )
        }

        var defaultsourceOptions = ({} = sourceOptions)
        // console.log("KML@@@+++", this.state.readToggel);

        if (
            this.state.readToggel == false ||
            this.state.readToggel == 'battery'
        ) {
            if (
                this.state.readToggel == 'battery' &&
                this.state.isRead == true
            ) {
                this.state.isRead = false
                try {
                    reader.cancel()
                } catch (e) {}
            }
            setTimeout(() => {
                try {
                    document.getElementById('ID').style.pointerEvents = 'none'
                } catch (e) {}
            }, 100)

            console.log('FALLLLSSSE')
        } else {
            console.log('TRUUUUUUUUUUUUUUUUUUUEEEEE')
            setTimeout(() => {
                try {
                    document.getElementById('ID').style.pointerEvents = 'auto'
                } catch (e) {}
            }, 100)
        }

        //disable the condition img
        const getStyleConditionImg = () => {
            if (
                [
                    'ai_color_detection',
                    // 'ai_color_tracking',
                    'ai_face_detection',
                    'ai_face_recognition',
                    // 'ai_face_tracking',
                    'ai_mic',
                ].includes(state.source)
            ) {
                return conditionDisabledStyle
            } else {
                return {}
            }
        }

        let tranListOption = ['Select Items']
        try {
            switch (state.source) {
                case 'ai_mic':
                    aiTrainList?.speechTranList.map((ele) => {
                        tranListOption.push(ele.value)
                    })
                    break
                case 'ai_color_detection':
                    aiTrainList?.colorTranList.map((ele, ind) => {
                        if (ele.isUpload)
                            tranListOption.push(`${ele.title} ${ind + 1}`)
                    })
                    break
                case 'ai_face_recognition':
                    aiTrainList?.faceTranList.map((ele, ind) => {
                        tranListOption.push(`${ele.title}`)
                    })
                    break
                default:
                    tranListOption = ['Select Items']
            }
        } catch (e) {
            console.log('ERROR', e)
        }

        // const { state, onChange } = this.props
        // console.log('YYYYYYY222', tranListOption)
        return (
            <div className="HexDecisionPanel_Container">
                {/* done1 */}
                <div
                    style={{
                        position: 'relative',
                        gridTemplateColumns:
                            'ai_mic' == state.source
                                ? '23% 50% 27%'
                                : '30% 50% 20%',
                    }}
                    className="sub_Container1"
                >
                    <div
                        id="content1_text1"
                        style={{
                            color: 'black',
                        }}
                    >
                        <p
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: '7%',
                                transform: 'translate(0%, -50%)',
                            }}
                        >
                            {'ai_mic' == state.source
                                ? 'If'
                                : 'If the value of'}
                        </p>
                    </div>
                    <div
                        id="content1_text2"
                        style={{
                            color: 'black',
                        }}
                    >
                        <div
                            style={{
                                border: '2px solid black',
                                position: 'absolute',
                                top: '50%',
                                left: '0%',
                                transform: 'translate(0%, -50%)',
                                width: '100%',
                                height: '40%',
                                borderRadius: '15px',
                                fontFamily: 'Halcyon_Medium',
                            }}
                        >
                            <Select
                                onChange={(value) => {
                                    this.onChange('source', value) //17
                                    this.onChange('value', 0)
                                    this.onChange('value2', 0)
                                }}
                                color={HexTypes['if'].color}
                                options={sourceOptions}
                                order={sourceOptionsOrder}
                                selected={source}
                            />
                        </div>
                    </div>
                    <div
                        id="content1_text3"
                        style={{
                            color: 'black',
                        }}
                    >
                        <p
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '7%',
                                transform: 'translate(0, -50%)',
                            }}
                        >
                            {'ai_mic' == state.source ? ' listens to' : 'is'}
                        </p>
                    </div>
                </div>
                {/* done1 */}

                {/* done 2 */}
                <div className="sub_Container2">
                    {ganaretVal().conditionValue == 'gt' ? (
                        <>
                            <div id="content_div1">
                                <img
                                    style={getStyleConditionImg()}
                                    src={renderPrgImage('greaterthanActive')}
                                    onClick={() => {
                                        if (Originalport.includes('Switch'))
                                            return
                                        else this.handleOperators('greaterThan')
                                    }}
                                />
                            </div>
                            <div>
                                <p id="sensors-txt1">Greater Than</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div id="content_div1">
                                <img
                                    style={getStyleConditionImg()}
                                    src={renderPrgImage('greaterthanInActive')}
                                    onClick={() => {
                                        if (
                                            [
                                                'ai_color_detection',
                                                'ai_color_tracking',
                                                'ai_face_tracking',
                                                'ai_face_detection',
                                                'ai_face_recognition',
                                                'ai_mic',

                                                'Switch',
                                            ].includes(Originalport)
                                        )
                                            return
                                        else this.handleOperators('greaterThan')
                                    }}
                                />
                            </div>
                            <div>
                                <p id="sensors-txt1">Greater Than</p>
                            </div>
                        </>
                    )}

                    {ganaretVal().conditionValue == 'lt' ? (
                        <>
                            <div id="content_div2">
                                <img
                                    src={renderPrgImage('lessthanActive')}
                                    onClick={() => {
                                        if (Originalport.includes('Switch'))
                                            return
                                        else this.handleOperators('lessThan')
                                    }}
                                />
                            </div>
                            <div>
                                <p id="sensors-txt2">Less Than</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div id="content_div2">
                                <img
                                    style={getStyleConditionImg()}
                                    src={renderPrgImage('lessthanInActive')}
                                    onClick={() => {
                                        if (
                                            [
                                                'ai_color_detection',
                                                'ai_color_tracking',
                                                'ai_face_tracking',
                                                'ai_face_detection',
                                                'ai_face_recognition',
                                                'ai_mic',

                                                'Switch',
                                            ].includes(Originalport)
                                        )
                                            return
                                        else this.handleOperators('lessThan')
                                    }}
                                />
                            </div>
                            <div>
                                <p id="sensors-txt2">Less Than</p>
                            </div>
                        </>
                    )}

                    {ganaretVal().conditionValue == 'bw' ? (
                        <>
                            <div id="content_div3">
                                <img
                                    src={renderPrgImage('inbetweenActive')}
                                    onClick={() => {
                                        if (Originalport.includes('Switch'))
                                            return
                                        else this.handleOperators('inBetween')
                                    }}
                                />
                            </div>
                            <div>
                                <p id="sensors-txt3">In Between</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div id="content_div3">
                                <img
                                    style={getStyleConditionImg()}
                                    src={renderPrgImage('inbetweenInActive')}
                                    onClick={() => {
                                        if (
                                            [
                                                'ai_color_detection',
                                                'ai_color_tracking',
                                                'ai_face_tracking',
                                                'ai_face_detection',
                                                'ai_face_recognition',
                                                'ai_mic',

                                                'Switch',
                                            ].includes(Originalport)
                                        )
                                            return
                                        else this.handleOperators('inBetween')
                                    }}
                                />
                            </div>
                            <div>
                                <p id="sensors-txt3">In Between</p>
                            </div>
                        </>
                    )}

                    {ganaretVal().conditionValue == 'eq' ? (
                        <>
                            <div id="content_div4">
                                <img
                                    src={renderPrgImage('equaltoActive')}
                                    onClick={() =>
                                        this.handleOperators('equalTo')
                                    }
                                />
                            </div>
                            <div>
                                <p id="sensors-txt4">Equals To</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div id="content_div4">
                                <img
                                    src={renderPrgImage('equaltoInActive')}
                                    onClick={() =>
                                        this.handleOperators('equalTo')
                                    }
                                />
                            </div>
                            <div>
                                <p id="sensors-txt4">Equals To</p>
                            </div>
                        </>
                    )}

                    {ganaretVal().conditionValue == 'ne' ? (
                        <>
                            <div id="content_div5">
                                <img
                                    src={renderPrgImage('notequaltoActive')}
                                    onClick={() =>
                                        this.handleOperators('notEqualTo')
                                    }
                                />
                            </div>
                            <div>
                                <p id="sensors-txt5">Not Equals To</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div id="content_div5">
                                <img
                                    style={getStyleConditionImg()}
                                    src={renderPrgImage('notequaltoInActive')}
                                    onClick={() => {
                                        if (
                                            [
                                                'ai_color_detection',
                                                'ai_color_tracking_X',
                                                'ai_color_tracking_Y',
                                                'ai_face_tracking_X',
                                                'ai_face_tracking_Y',
                                                'ai_face_detection',
                                                'ai_face_recognition',
                                                'ai_mic',
                                            ].includes(Originalport)
                                        )
                                            return
                                        this.handleOperators('notEqualTo')
                                    }}
                                />
                            </div>
                            <div>
                                <p id="sensors-txt5">Not Equals To</p>
                            </div>
                        </>
                    )}
                </div>
                {/* done 2 */}

                {![
                    'ai_color_detection',
                    'ai_face_recognition',
                    'ai_mic',
                ].includes(state.source) && (
                    <div className="sub-container3">
                        {ifOutputRow1}
                        {ifOutputRow2}
                    </div>
                )}

                {[
                    'ai_color_detection',
                    'ai_face_recognition',
                    'ai_mic',
                ].includes(state.source) && (
                    <div className="sub-container3">
                        <div
                            style={{
                                border: '2px solid black',
                                position: 'absolute',
                                top: '61%',
                                left: '15%',
                                transform: 'translate(0%, -50%)',
                                width: '68%',
                                height: '11%',
                                borderRadius: '15px',
                            }}
                        >
                            <Select
                                selected={ganaretVal().sliderVal1}
                                onChange={(value) => {
                                    let newVal = value
                                    if (typeof newVal == 'string') {
                                        newVal = parseInt(newVal)
                                    }
                                    if (state.source == 'ai_face_recognition') {
                                        newVal =
                                            aiTrainList?.faceTranList?.[
                                                value - 1
                                            ]?.id
                                    }
                                    this.onChange('value', newVal)
                                }}
                                options={sourceOptions}
                                order={tranListOption}
                                typeDropDown="trainList"
                                aiType={state.source}
                            />
                        </div>
                    </div>
                )}

                {/* done4 */}

                <div className="sub_Container4">
                    <div id="content5_text1">
                        <p
                            style={{
                                // position: 'absolute',
                                // top: '50%',
                                // right: '7%',
                                // transform: 'translate(0%, -50%)',
                                marginBottom: '0px',
                                gap: '4%',
                            }}
                        >
                            Read the
                        </p>
                    </div>
                    <div id="content5_text2">
                        <div
                            style={{
                                border: '2px solid black',
                                // position: 'absolute',
                                // top: '50%',
                                // left: '0%',
                                // transform: 'translate(0%, -50%)',
                                // width: '100%',
                                height: '100%',
                                borderRadius: '15px',
                            }}
                        >
                            <Select
                                onChange={(value) =>
                                    this.onChangeRead('source', value)
                                }
                                options={sourceOptions}
                                order={readSourceOptionsOrder}
                                typeDropDown="read"
                            />
                        </div>
                    </div>
                    <div id="content5_text3">
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {sessionStorage
                                .getItem('deviceVersion')
                                ?.startsWith('1') ||
                            ['Roboki'].includes(
                                sessionStorage.getItem('connectedDevice')
                            ) ? (
                                <>
                                    {this.state.isRead_v1 ? (
                                        <div
                                            className={`${
                                                connectedDevice == 'Klaw'
                                                    ? 'sub1_content5_klaw'
                                                    : 'sub1_content5 center_div_items'
                                            }`}
                                        >
                                            <div
                                                className="center_div_items"
                                                style={{
                                                    // position: 'absolute',
                                                    // top: '55%',
                                                    // left: '31%',
                                                    // transform:
                                                    //     'translate(0, -50%)',
                                                    fontSize: '2vh',
                                                    color: 'black',
                                                    minWidth: '30px',
                                                    height: '20px',
                                                }}
                                                onClick={() => {
                                                    this.handleReadByte_v1()
                                                }}
                                            >
                                                {this.state.readToggel ===
                                                'A1' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeA1}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'A2' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeA2}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'B1' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeB1}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'B2' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeB2}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'C1' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeC1}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'C2' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeC2}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'D1' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeD1}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'D2' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeD2}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'E1' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeE1}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'E2' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeE2}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'F1' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {this.state.rangeF1}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'F2' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeF2}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'TouchZero' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.zero}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  'TouchOne' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.one}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'TouchTwo' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.two}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'TouchThree' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.temp}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'BATTERY' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.battery}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'MICROPHONE' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.mic}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'TEMPERATURE' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.temprature}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'ACCELEROMETER_X' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {
                                                            this.state
                                                                .accelerometer_X
                                                        }
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'ACCELEROMETER_Y' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {
                                                            this.state
                                                                .accelerometer_Y
                                                        }
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'ACCELEROMETER_Z' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {
                                                            this.state
                                                                .accelerometer_Z
                                                        }
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'GYRO_X' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.gyro_X}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'GYRO_Y' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.gyro_Y}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'GYRO_Z' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.gyro_Z}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  '4-IN-1 SENSOR  →  BLUE' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.blue}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  '4-IN-1 SENSOR  →  GREEN' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.green}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  '4-IN-1 SENSOR  →  RED' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.red}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  '4-IN-1 SENSOR  →  LIGHT' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.light}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  '4-IN-1 SENSOR  →  GESTURE' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.gesture}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  '4-IN-1 SENSOR  →  DIST' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.distance}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'Switch_One' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.switch1}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'Switch_Two' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.switch2}
                                                    </p>
                                                ) : [
                                                      'ai_color_detection',
                                                      'ai_color_tracking_X',
                                                      'ai_color_tracking_Y',
                                                      'ai_face_tracking_X',
                                                      'ai_face_tracking_Y',
                                                      'ai_face_detection',
                                                      'ai_face_recognition',
                                                      'ai_mic',
                                                      'ai_lidar',
                                                      'ai_motion',
                                                  ].includes(
                                                      this.state.readToggel
                                                  ) ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {
                                                            this.state
                                                                .pcAiTool_read_value
                                                        }
                                                    </p>
                                                ) : null}

                                                {/* this.state.readToggel ===
                                                  'Joystick1_X' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.joystick1_X}{' '}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'Joystick1_Y' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.joystick1_Y}{' '}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'Joystick2_X' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.joystick2_X}{' '}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'Joystick2_Y' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.joystick2_Y}{' '}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'Joystick3_X' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.joystick3_X}{' '}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'Joystick3_Y' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.joystick3_Y}{' '}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'Switch1' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.switch1}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'Switch2' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.switch2}
                                                    </p>
                                                ) : this.state.readToggel ===
                                                  'Switch3' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.switch3}
                                                    </p>
                                                ) */}
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={`${
                                                connectedDevice == 'Klaw'
                                                    ? 'sub2_content5_klaw'
                                                    : 'sub2_content5 center_div_items'
                                            }`}
                                        >
                                            <div
                                                id="ID"
                                                className="center_div_items"
                                                style={{
                                                    // position: 'absolute',
                                                    // top: '48%',
                                                    // left: '25%',
                                                    // transform:
                                                    //     'translate(0, -50%)',
                                                    fontSize: '2vh',
                                                }}
                                                onClick={() => {
                                                    if (
                                                        this.props.webSerial
                                                            .isConnected
                                                    )
                                                        this.handleReadByte_v1()
                                                }}
                                            >
                                                Read
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {' '}
                                    {this.state.isRead ? (
                                        <div className="sub1_content5 center_div_items">
                                            {' '}
                                            <div
                                                className="center_div_items"
                                                style={{
                                                    // position: 'absolute',
                                                    // top: '55%',
                                                    // left: '31%',
                                                    // transform:
                                                    //     'translate(0, -50%)',
                                                    fontSize: '2vh',
                                                    color: 'black',
                                                    minWidth: '30px',
                                                }}
                                                onClick={() => {
                                                    this.handleRead()
                                                }}
                                            >
                                                {this.state.readToggel ==
                                                'A1' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeA1}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  'A2' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeA2}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  'B1' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeB1}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  'B2' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeB2}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  'C1' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeC1}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  'C2' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeC2}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  'F1' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeF1}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  'F2' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.rangeF2}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  'TouchZero' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.zero}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  'TouchOne' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.one}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  'TouchTwo' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.two}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  'MICROPHONE' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.mic}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  'TEMPERATURE' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.temprature}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  '4-IN-1 SENSOR  →  BLUE' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.blue}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  '4-IN-1 SENSOR  →  GREEN' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.green}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  '4-IN-1 SENSOR  →  RED' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.red}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  '4-IN-1 SENSOR  →  LIGHT' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.light}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  '4-IN-1 SENSOR  →  GESTURE' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {gestureValueToText(
                                                            this.outputGesture(
                                                                this.state
                                                                    .gesture
                                                            )
                                                        )}
                                                    </p>
                                                ) : this.state.readToggel ==
                                                  '4-IN-1 SENSOR  →  DIST' ? (
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '0px ',
                                                        }}
                                                    >
                                                        {this.state.distance}
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="sub2_content5 center_div_items">
                                            <div
                                                id="ID"
                                                className="center_div_items"
                                                style={{
                                                    // position: 'absolute',
                                                    // top: '48%',
                                                    // left: '25%',
                                                    // transform:
                                                    //     'translate(0, -50%)',
                                                    fontSize: '2vh',
                                                }}
                                                // onMouseDown={async () => {
                                                //     const PLAY = [
                                                //         'P'.charCodeAt(),
                                                //         'L'.charCodeAt(),
                                                //         'A'.charCodeAt(),
                                                //         'Y'.charCodeAt(),
                                                //     ]
                                                //     await this.writePort(PLAY)
                                                // }}
                                                onClick={async (e) => {
                                                    const updateFW = JSON.parse(
                                                        sessionStorage.getItem(
                                                            'updateFW'
                                                        )
                                                    )
                                                    if (
                                                        updateFW === null ||
                                                        updateFW === false
                                                    ) {
                                                        if (
                                                            !this.props
                                                                .webSerial
                                                                .isConnected
                                                        )
                                                            return
                                                        await this.handleRead()
                                                        let ele = e.target

                                                        ele.style.pointerEvents =
                                                            'none'
                                                        setTimeout(() => {
                                                            ele.style.pointerEvents =
                                                                'all'
                                                        }, 2000)
                                                    } else {
                                                        this.props.popUp(true)
                                                    }
                                                }}
                                            >
                                                Read
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {/* done4 */}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    console.log('ASSEMBLY LOG', state)
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IfPanel)
