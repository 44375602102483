//all app ai mode prefix bytes
const aiBytesPrefix = {
    colorBytesPrefix: [
        'A'.charCodeAt(0),
        'I'.charCodeAt(0),
        6,
        'C'.charCodeAt(0),
    ],
    faceBytesPrefix: [
        'A'.charCodeAt(0),
        'I'.charCodeAt(0),
        0, // index 2 show the length of bytes
        'F'.charCodeAt(0),
    ],
    wakeBytesPrefix: [
        'A'.charCodeAt(0),
        'I'.charCodeAt(0),
        0, // index 2 show the length of bytes
        'W'.charCodeAt(0),
    ],
    speechBytesPrefix: [
        'A'.charCodeAt(0),
        'I'.charCodeAt(0),
        0, // index 2 show the length of bytes
        'F'.charCodeAt(0),
    ],
}

// generate the  CRC(Cyclic Redundancy Check)
const crc_create = (buf) => {
    let crc
    let temp = 0
    for (let i = 0; i < buf.length; i++) {
        console.log('GGGGGG', buf[i])
        temp += buf[i]
    }
    temp = temp & 0xff
    crc = ~temp
    return crc & 0xff
}

const appAiBytesGeneration = (byteType, ele, ind) => {
    try {
        let bytes = ['A'.charCodeAt(0), 'I'.charCodeAt(0)]
        let crc = ''
        // if (byteType === 'faceByte') {
        //     bytes = [...aiBytesPrefix.faceBytesPrefix]
        //     bytes[2] = ele.faceName.length + 3

        //     bytes.push(ind + 1) //face id
        //     let nameAsci = ele.faceName.split('').map((item) => item.charCodeAt(0))
        //     bytes = [...bytes, ...nameAsci]
        // } else if (byteType === 'colorByte') {
        //     bytes = [...aiBytesPrefix.colorBytesPrefix]
        //     bytes.push(ind + 1, ele.read, ele.green, ele.blue)
        // }//wakeByte
        switch (byteType) {
            case 'faceByte': {
                // bytes = [...aiBytesPrefix.faceBytesPrefix]
                let length = ele.faceName.length + 2
                bytes.push(length, 'F'.charCodeAt(0))

                // bytes.push(ind + 1) //face id
                let nameAsci = ele.faceName
                    .split('')
                    .map((item) => item.charCodeAt(0))
                bytes = [...bytes, ...nameAsci]

                crc = crc_create([length, 'F'.charCodeAt(0), ...nameAsci])

                break
            }
            case 'startColorByte': {
                bytes.push(2, 0xc0)
                crc = crc_create([2, 0xc0])
                break
            }
            case 'bondingBoxColorByte': {
                ele = parseInt(ele)
                bytes.push(3, 0xc2, ele)
                crc = crc_create([3, 0xc2, ele])
                break
            }
            case 'thresholdColorByte': {
                ele = parseInt(ele)
                bytes.push(3, 0xc3, ele)
                crc = crc_create([3, 0xc3, ele])
                break
            }
            case 'colorByte': {
                // bytes = [...aiBytesPrefix.colorBytesPrefix]
                // bytes.push(9, 'C'.charCodeAt(0))
                // bytes.push(
                //     ind + 1,
                //     ele.redMin,
                //     ele.redMax,
                //     ele.greenMin,
                //     ele.greenMax,
                //     ele.blueMin,
                //     ele.blueMax
                // )

                // crc = crc_create([
                //     9,
                //     'C'.charCodeAt(0),
                //     parseInt(ele.redMin),
                //     parseInt(ele.redMax),
                //     parseInt(ele.greenMin),
                //     parseInt(ele.greenMax),
                //     parseInt(ele.blueMin),
                //     parseInt(ele.blueMax),
                // ])
                bytes.push(3, 0xc1, ele)
                crc = crc_create([3, 0xc1, ele])
                break
            }
            case 'deleteColorByte': {
                bytes.push(3, 0xc4, ele)
                crc = crc_create([3, 0xc4, ele])
                break
            }
            case 'wakeByte': {
                let length = ele.length + 2
                bytes.push(length, 'W'.charCodeAt(0))

                let wakeAsci = ele.split('').map((item) => item.charCodeAt(0))
                bytes = [...bytes, ...wakeAsci]

                crc = crc_create([length, 'W'.charCodeAt(0), ...wakeAsci])
                break
            }
            case 'speechByte': {
                let length = ele.value.length + 3
                bytes.push(length, 'V'.charCodeAt(0))
                bytes.push(ind + 1) //speech id

                let speechAsci = ele.value
                    .split('')
                    .map((item) => item.charCodeAt(0))
                bytes = [...bytes, ...speechAsci]

                crc = crc_create([
                    length,
                    'V'.charCodeAt(0),
                    ind + 1,
                    ...speechAsci,
                ])
                break
            }
            case 'cleanByte': {
                let length = 3
                let commend = '0'
                if (ele == 'color') {
                    commend = 'C'
                } else if (ele == 'face') {
                    commend = 'F'
                } else {
                    commend = 'C'
                }

                bytes.push(length, 'D'.charCodeAt(0), commend.charCodeAt(0))

                crc = crc_create([
                    length,
                    'D'.charCodeAt(0),
                    commend.charCodeAt(0),
                ])
                break
            }
        }

        bytes.push(crc, 0xff, 0xf9)
        console.log('bytes', bytes)
        return bytes
    } catch (e) {
        console.log('ERROR', e)
        return [
            'R'.charCodeAt(0),
            'E'.charCodeAt(0),
            'S'.charCodeAt(0),
            'T'.charCodeAt(0),
        ]
    }
}

//generat the peeCee Ai Data in hex programming
const generateAidata = (assemblyPageAi) => {
    console.log('assemblyPageAi', assemblyPageAi)
    let bytes = ['A'.charCodeAt(0), 'I'.charCodeAt(0), 7, 'S'.charCodeAt(0)]
    bytes.push(0, 0, 0, 0, 0)

    Object.keys(assemblyPageAi.aiItems).map((objKey) => {
        switch (objKey) {
            case 'ai_motion':
                if (assemblyPageAi.aiItems[objKey]) {
                    bytes[4] = 'M'.charCodeAt(0)
                    bytes[5] = 1
                }
                break

            case 'ai_color_detection':
            case 'ai_color_tracking':
                if (assemblyPageAi.aiItems[objKey]) {
                    bytes[4] = 'C'.charCodeAt(0)
                    bytes[5] = 1
                }
                break

            case 'ai_face_detection':
            case 'ai_face_recognition':
            case 'ai_face_tracking':
                if (assemblyPageAi.aiItems[objKey]) {
                    bytes[4] = 'F'.charCodeAt(0)
                    bytes[5] = 1
                }
                break

            case 'ai_mic':
                if (assemblyPageAi.aiItems[objKey]) {
                    bytes[6] = 1
                }
                break

            case 'ai_lidar':
                if (assemblyPageAi.aiItems[objKey]) {
                    bytes[7] = 1
                }
                break

            case 'ai_LED':
                if (assemblyPageAi.aiItems[objKey]) {
                    bytes[8] = 1
                }
                break
        }
    })

    let spliceByte = [...bytes].splice(2, 8)
    let crc = crc_create(spliceByte)

    bytes.push(crc, 0xff, 0xf9)
    console.log('bytes', bytes)

    return bytes
}

//generat ai REST data
const generateAiresetdata = () => {
    let data = ['A'.charCodeAt(0), 'I'.charCodeAt(0), 2, 'X'.charCodeAt(0)]
    let crc = crc_create([2, 'X'.charCodeAt(0)])
    data.push(crc, 0xff, 0xf9)

    return data
}

export { appAiBytesGeneration, crc_create, generateAidata, generateAiresetdata }
