import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SliderRow from './SliderRow'
import AssignRow from './AssignRow'
import FlagRow from './FlagRow'
import CountRow from './CountRow'
import SwitchesRow from './SwitchesRow'
import TextRow from './TextRow'
import SelectionRow from './SelectionRow'
import IotRow from './IotRow'
import IOComponents from '../../IOComponents'
import PortValuesRangeMapping from '../../PortValuesRangeMapping'

import { rangeStoreVal } from '../../../Assembly/CheckboxData'
import { Controls } from 'react-flow-renderer'
import RadioBtn from '../../../ReusableComponents/RadioBtn/RadioBtn'
import renderImage from '../../../../source/importImg'
import { HumanoidAction, HumanoidActiveBtn } from '../../HumanoidActionData'
import LogicSwitchComp from '../helpers/SwitchComp/LogicSwitchComp'

import { TrainRounded } from '@material-ui/icons'
import ActionPanel from '../../../Humanoid/ActionPanel'
import AiMode_output from './AiMode_output'

const startStateOrder = [
    'bid1',
    'bid2',
    'bid3',
    'bif1',
    'bif2',
    'bif3',
    'bic1',
    'bic2',
    'bic3',
    'btr',
    'iot',
]
var SelectOptions, SelectOptionsOrder
var HumanoidactionName, HumanoidactionValue

var prevHumanoidAction = null

var currentHumanoidAction = null

class OutputPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // NEW UI STATE
            isClickTouchZeroOutput: false,
            isClickTouchOneOutput: false,
            isClickTouchTwoOutput: false,
            isClickTouchThreeOutput: false,

            isClickSmileOne: false,
            isClickSmileTwo: false,
            isClickSmileThree: false,
            isClickSmileFour: false,
            isClickBuzzer: false,
            isClickBuzzerFrequency: false,
            isClickBuzzerTone: false,

            isClickOLED1: false,
            isClickOLED2: false,
            isClickOLED3: false,
            isClickOLED4: false,

            isClickBif1: false,
            isClickBif2: false,
            isClickBic1: false,
            isClickBic2: false,
            isClickBid1: false,
            isClickBid2: false,
            isClickAsgn1: false,
            isClickAsgn2: false,
            isClickAsgn3: false,
            isClickAsgn4: false,
            isClickAsgn5: false,
            isClickUsbtx: false,
            isClickUsbrx: false,
            isClickbtTx1: false,
            isClickbtTx2: false,
            isClickbtTx3: false,
            isClickbtTx4: false,
            isClickbtTx5: false,

            setportName: '',

            isClickLeftEye: false,
            isClickRightEye: false,

            isClickEye1: false,
            isClickEye2: false,
            isClickEye3: false,
            isClickEye4: false,
            isClickEye5: false,
            isClickEye6: false,
            isClickEye7: false,
            isClickEye8: false,
            isClickEye9: false,
            isClickEye10: false,
            isClickEye11: false,
            isClickEye12: false,

            isClickLeftEyeR: false,
            isClickLeftEyeG: false,
            isClickLeftEyeB: false,

            isClickRightEyeR: false,
            isClickRightEyeG: false,
            isClickRightEyeB: false,

            isClickSTMP: false,
            isClickSTMP_SWITCH: false,

            isClickSTMP_SWITCH_A1: false,
            isClickSTMP_SWITCH_A2: false,
            isClickSTMP_SWITCH_B1: false,
            isClickSTMP_SWITCH_B2: false,
            isClickSTMP_SWITCH_C1: false,
            isClickSTMP_SWITCH_C2: false,
            isClickSTMP_SWITCH_D1: false,
            isClickSTMP_SWITCH_D2: false,
            isClickRGBComp: {
                isClickRGBComp1: false,
                isClickRGBComp2: false,
                isClickRGBComp3: false,
                isClickRGBComp4: false,
                isClickRGBComp5: false,
                isClickRGBComp6: false,
                isClickRGBComp7: false,
                isClickRGBComp8: false,
                isClickRGBComp9: false,
                isClickRGBComp10: false,
            },

            curValRGBComp: {
                RGBComp1: { R: 0, G: 0, B: 0 },
                RGBComp2: { R: 0, G: 0, B: 0 },
                RGBComp3: { R: 0, G: 0, B: 0 },
                RGBComp4: { R: 0, G: 0, B: 0 },
                RGBComp5: { R: 0, G: 0, B: 0 },
                RGBComp6: { R: 0, G: 0, B: 0 },
                RGBComp7: { R: 0, G: 0, B: 0 },
                RGBComp8: { R: 0, G: 0, B: 0 },
                RGBComp9: { R: 0, G: 0, B: 0 },
                RGBComp10: { R: 0, G: 0, B: 0 },
            },
            countRGBComp: 1,

            isClickRadio: 'false',
            name: 'asd',

            // NEW UI DATA

            curValTouchZeroOutput: 0,
            curValTouchOneOutput: 0,
            curValTouchTwoOutput: 0,
            curValTouchThreeOutput: 0,

            curValBic1: 0,
            curValBic2: 0,
            curValBid1: 0,
            curValAsgn1: 0,
            curValAsgn2: 0,
            curValAsgn3: 0,
            curValAsgn4: 0,
            curValAsgn5: 0,
            curValBif1: 0,
            curValBif2: 0,
            curValUsbtx: 0,
            curValUsbrx: 0,
            curValbtTx1: 0,
            curValbtTx2: 0,
            curValbtTx3: 0,
            curValbtTx4: 0,
            curValbtTx5: 0,

            curValSmileOne: 0,
            curValSmileTwo: 0,
            curValSmileThree: 0,
            curValSmileFour: 0,

            curValBuzzer: 0,
            curValBuzzerFrequency: 0,
            curValBuzzerTone: 0,

            curValLeftEyeR: 0,
            curValLeftEyeG: 0,
            curValLeftEyeB: 0,

            curValRightEyeR: 0,
            curValRightEyeG: 0,
            curValRightEyeB: 0,

            curValEyeR1: 0,
            curValEyeG1: 0,
            curValEyeB1: 0,

            curValEyeR2: 0,
            curValEyeG2: 0,
            curValEyeB2: 0,

            curValEyeR3: 0,
            curValEyeG3: 0,
            curValEyeB3: 0,

            curValEyeR4: 0,
            curValEyeG4: 0,
            curValEyeB4: 0,

            curValEyeR5: 0,
            curValEyeG5: 0,
            curValEyeB5: 0,

            curValEyeR6: 0,
            curValEyeG6: 0,
            curValEyeB6: 0,

            curValEyeR7: 0,
            curValEyeG7: 0,
            curValEyeB7: 0,

            curValEyeR8: 0,
            curValEyeG8: 0,
            curValEyeB8: 0,

            curValEyeR9: 0,
            curValEyeG9: 0,
            curValEyeB9: 0,

            curValEyeR10: 0,
            curValEyeG10: 0,
            curValEyeB10: 0,

            curValEyeR11: 0,
            curValEyeG11: 0,
            curValEyeB11: 0,

            curValEyeR12: 0,
            curValEyeG12: 0,
            curValEyeB12: 0,

            curValOLED1: ' ',
            curValOLED2: ' ',
            curValOLED3: ' ',
            curValOLED4: ' ',
            HumanoidactionName: null,
            HumanoidactionValue: null,

            //humanoid
            isClickMp3: false,
            curValMp3: 0,
            isClickHeadServo: false,
            curValHeadServo: false,
            isClickLeftRGB: false,
            curValLeftRGB: 0,
            isClickRightRGB: false,
            curValRightRGB: 0,
            isClickIndivdualServo: false,
            curValIndivdualServo: 0,
            isClickActionGroup: false,
            curValActionGroup: 0,
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            // New Ui Data for VALUE
            curValTouchZeroOutput: props.state.valueTouchZeroOutput,
            curValTouchOneOutput: props.state.valueTouchOneOutput,
            curValTouchTwoOutput: props.state.valueTouchTwoOutput,
            curValTouchThreeOutput: props.state.valueTouchThreeOutput,

            curValBic1: props.state.valueBic1,
            curValBic2: props.state.valueBic2,
            curValBif1: props.state.valueBif1,
            curValBif2: props.state.valueBif2,
            curValBid1: props.state.valueBid1,
            curValBid2: props.state.valueBid2,
            curValAsgn1: props.state.valueAsgn1,
            curValAsgn2: props.state.valueAsgn2,
            curValAsgn3: props.state.valueAsgn3,
            curValAsgn4: props.state.valueAsgn4,
            curValAsgn5: props.state.valueAsgn5,
            curValUsbtx: props.state.valueUsbtx,
            curValUsbrx: props.state.valueUsbrx,
            curValbtTx1: props.state.valuebtTx,
            curValbtTx2: props.state.valuebtTx,
            curValbtTx3: props.state.valuebtTx,
            curValbtTx4: props.state.valuebtTx,
            curValbtTx5: props.state.valuebtTx,

            curValSmileOne: props.state.valueSmileOne,
            curValSmileTwo: props.state.valueSmileTwo,
            curValSmileThree: props.state.valueSmileThree,
            curValSmileFour: props.state.valueSmileFour,

            curValBuzzer: props.state.valueBuzzer,
            curValBuzzerFrequency: props.state.valueBuzzerFrequency,
            curValBuzzerTone: props.state.valueBuzzerTone,

            curValLeftEyeR: props.state.valueLeftEyeR,
            curValLeftEyeG: props.state.valueLeftEyeG,
            curValLeftEyeB: props.state.valueLeftEyeB,

            curValRightEyeR: props.state.valueRightEyeR,
            curValRightEyeG: props.state.valueRightEyeG,
            curValRightEyeB: props.state.valueRightEyeB,

            curValOLED1: props.state.valueOLEDOne,
            curValOLED2: props.state.valueOLEDTwo,
            curValOLED3: props.state.valueOLEDThree,
            curValOLED4: props.state.valueOLEDFour,

            curValEyeR1: props.state.valueEyeR1,
            curValEyeG1: props.state.valueEyeG1,
            curValEyeB1: props.state.valueEyeB1,

            curValEyeR2: props.state.valueEyeR2,
            curValEyeG2: props.state.valueEyeG2,
            curValEyeB2: props.state.valueEyeB2,

            curValEyeR3: props.state.valueEyeR3,
            curValEyeG3: props.state.valueEyeG3,
            curValEyeB3: props.state.valueEyeB3,

            curValEyeR4: props.state.valueEyeR4,
            curValEyeG4: props.state.valueEyeG4,
            curValEyeB4: props.state.valueEyeB4,

            curValEyeR5: props.state.valueEyeR5,
            curValEyeG5: props.state.valueEyeG5,
            curValEyeB5: props.state.valueEyeB5,

            curValEyeR6: props.state.valueEyeR6,
            curValEyeG6: props.state.valueEyeG6,
            curValEyeB6: props.state.valueEyeB6,

            curValEyeR7: props.state.valueEyeR7,
            curValEyeG7: props.state.valueEyeG7,
            curValEyeB7: props.state.valueEyeB7,

            curValEyeR8: props.state.valueEyeR8,
            curValEyeG8: props.state.valueEyeG8,
            curValEyeB8: props.state.valueEyeB8,

            curValEyeR9: props.state.valueEyeR9,
            curValEyeG9: props.state.valueEyeG9,
            curValEyeB9: props.state.valueEyeB9,

            curValEyeR10: props.state.valueEyeR10,
            curValEyeG10: props.state.valueEyeG10,
            curValEyeB10: props.state.valueEyeB10,

            curValEyeR11: props.state.valueEyeR11,
            curValEyeG11: props.state.valueEyeG11,
            curValEyeB11: props.state.valueEyeB11,

            curValEyeR12: props.state.valueEyeR12,
            curValEyeG12: props.state.valueEyeG12,
            curValEyeB12: props.state.valueEyeB12,

            // New UI Data isCLick
            isClickTouchZeroOutput: props.state.assignTouchZeroOutput,
            isClickTouchOneOutput: props.state.assignTouchOneOutput,
            isClickTouchTwoOutput: props.state.assignTouchTwoOutput,
            isClickTouchThreeOutput: props.state.assignTouchThreeOutput,

            isClickSmileOne: props.state.assignSmileOne,
            isClickSmileTwo: props.state.assignSmileTwo,
            isClickSmileThree: props.state.assignSmileThree,
            isClickSmileFour: props.state.assignSmileFour,

            isClickBuzzer: props.state.assignBuzzer,
            isClickBuzzerFrequency: props.state.assignBuzzerFrequency,
            isClickBuzzerTone: props.state.assignBuzzerTone,

            isClickLeftEye: props.state.assignLeftEye,
            isClickRightEye: props.state.assignRightEye,

            isClickEye1: props.state.assignEye1,
            isClickEye2: props.state.assignEye2,
            isClickEye3: props.state.assignEye3,
            isClickEye4: props.state.assignEye4,
            isClickEye5: props.state.assignEye5,
            isClickEye6: props.state.assignEye6,
            isClickEye7: props.state.assignEye7,
            isClickEye8: props.state.assignEye8,
            isClickEye9: props.state.assignEye9,
            isClickEye10: props.state.assignEye10,
            isClickEye11: props.state.assignEye11,
            isClickEye12: props.state.assignEye12,

            isClickLeftEyeR: props.state.assignLeftEyeR,
            isClickLeftEyeB: props.state.assignLeftEyeB,
            isClickLeftEyeG: props.state.assignLeftEyeG,

            isClickRightEyeR: props.state.assignRightEyeR,
            isClickRightEyeB: props.state.assignRightEyeB,
            isClickRightEyeG: props.state.assignRightEyeG,

            isClickOLED1: props.state.assignOLEDOne,
            isClickOLED2: props.state.assignOLEDTwo,
            isClickOLED3: props.state.assignOLEDThree,
            isClickOLED4: props.state.assignOLEDFour,

            isClickRGBComp: {
                isClickRGBComp1: props.state.assignRGBComp1 || false,
                isClickRGBComp2: props.state.assignRGBComp2 || false,
                isClickRGBComp3: props.state.assignRGBComp3 || false,
                isClickRGBComp4: props.state.assignRGBComp4 || false,
                isClickRGBComp5: props.state.assignRGBComp5 || false,
                isClickRGBComp6: props.state.assignRGBComp6 || false,
                isClickRGBComp7: props.state.assignRGBComp7 || false,
                isClickRGBComp8: props.state.assignRGBComp8 || false,
                isClickRGBComp9: props.state.assignRGBComp9 || false,
                isClickRGBComp10: props.state.assignRGBComp10 || false,
            },

            curValRGBComp: {
                RGBComp1: {
                    R: props.state.valueRGBComp1R,
                    G: props.state.valueRGBComp1G,
                    B: props.state.valueRGBComp1B,
                },
                RGBComp2: {
                    R: props.state.valueRGBComp2R,
                    G: props.state.valueRGBComp2G,
                    B: props.state.valueRGBComp2B,
                },
                RGBComp3: {
                    R: props.state.valueRGBComp3R,
                    G: props.state.valueRGBComp3G,
                    B: props.state.valueRGBComp3B,
                },
                RGBComp4: {
                    R: props.state.valueRGBComp4R,
                    G: props.state.valueRGBComp4G,
                    B: props.state.valueRGBComp4B,
                },
                RGBComp5: {
                    R: props.state.valueRGBComp5R,
                    G: props.state.valueRGBComp5G,
                    B: props.state.valueRGBComp5B,
                },
                RGBComp6: {
                    R: props.state.valueRGBComp6R,
                    G: props.state.valueRGBComp6G,
                    B: props.state.valueRGBComp6B,
                },
                RGBComp7: {
                    R: props.state.valueRGBComp7R,
                    G: props.state.valueRGBComp7G,
                    B: props.state.valueRGBComp7B,
                },
                RGBComp8: {
                    R: props.state.valueRGBComp8R,
                    G: props.state.valueRGBComp8G,
                    B: props.state.valueRGBComp8B,
                },
                RGBComp9: {
                    R: props.state.valueRGBComp9R,
                    G: props.state.valueRGBComp9G,
                    B: props.state.valueRGBComp9B,
                },
                RGBComp10: {
                    R: props.state.valueRGBComp10R,
                    G: props.state.valueRGBComp10G,
                    B: props.state.valueRGBComp10B,
                },
            },
            countRGBComp: props.state.countRGBComp || 1,

            // DRIVER MOTOR
            isClickSTMP: props.state.assignSTMP,

            // HUMANOID
            isClickMp3: props.state.assignMp3,
            // isClickHeadServo: props.state.assignHeadServo,
        }
    }

    onChange = (key, value) => {
        console.log(key, value)
        if (JSON.parse(sessionStorage.getItem('peeCeeATR'))) {
            if (
                ['S1', 'S2'].includes(key.slice(-2)) &&
                !sessionStorage.getItem('deviceVersion')?.startsWith('1')
            ) {
                if (key.slice(-2) == 'S1') {
                    key = key.replace('S1', 'A1')
                } else {
                    key = key.replace('S2', 'C1')
                }
            }
        }
        if (sessionStorage.getItem('connectedDevice') === 'Roboki') {
            if (['S1', 'S2'].includes(key.slice(-2))) {
                if (key.slice(-2) == 'S1') {
                    key = key.replace('S1', 'servo_motor1')
                } else {
                    key = key.replace('S2', 'servo_motor2')
                }
            }
        }
        //state = activeRef.state is coming from programTodraw file by props
        const { state, onChange } = this.props
        state[key] = value
        onChange(state, 'hardware') //Important for Type change for hardware
    }

    handleSTPM = () => {
        const { state, onChange } = this.props

        // isPC_DRIVER_MOTOR is connected if conneted get the connectTo port;
        let isPC_DRIVER_MOTOR = JSON.parse(sessionStorage.getItem('assembly'))[
            'workspace'
        ]['components']['pc_motor_driver']

        // use checkboxData.js file to store the value of STPM
        if (this.state.isClickSTMP) {
            console.log('TRUE TRUE')
            this.setState({
                isClickSTMP: false,
            })

            state[`assignSTMP`] = false

            rangeStoreVal['STPM'].isChecked = false

            // WHEN STPM is OFF passing data as false as assign value
            if (isPC_DRIVER_MOTOR !== undefined) {
                let isConnected = isPC_DRIVER_MOTOR[0]['connectedTo']

                if (isConnected == 'B' || isConnected == 'D') {
                    state[`assignB1`] = false
                    state[`valueB1`] = 0
                    state[`assignB2`] = false
                    state[`valueB2`] = 0
                    state[`assignD1`] = false
                    state[`valueD1`] = 0
                    state[`assignD2`] = false
                    state[`valueD2`] = 0
                }
                if (isConnected == 'A' || isConnected == 'C') {
                    state[`assignA1`] = false
                    state[`valueA1`] = 0
                    state[`assignA2`] = false
                    state[`valueA2`] = 0
                    state[`assignC1`] = false
                    state[`valueC1`] = 0
                    state[`assignC2`] = false
                    state[`valueC2`] = 0
                }
            }
        } else {
            this.setState({
                isClickSTMP: true,
            })

            state[`assignSTMP`] = true
            rangeStoreVal['STPM'].isChecked = true

            console.log(
                'session data 002',
                JSON.parse(sessionStorage.getItem('assembly'))['workspace'][
                    'components'
                ]['pc_motor_driver']
            )

            // SETTING INITIAL VALUE SWITCH BTN value to backend byets as 0

            if (isPC_DRIVER_MOTOR !== undefined) {
                let isConnected = isPC_DRIVER_MOTOR[0]['connectedTo']

                if (isConnected == 'B' || isConnected == 'D') {
                    state[`assignB1`] = true
                    state[`valueB1`] = 0
                    state[`assignB2`] = true
                    state[`valueB2`] = 0
                    state[`assignD1`] = true
                    state[`valueD1`] = 0
                    state[`assignD2`] = true
                    state[`valueD2`] = 0
                }
                if (isConnected == 'A' || isConnected == 'C') {
                    state[`assignA1`] = true
                    state[`valueA1`] = 0
                    state[`assignA2`] = true
                    state[`valueA2`] = 0
                    state[`assignC1`] = true
                    state[`valueC1`] = 0
                    state[`assignC2`] = true
                    state[`valueC2`] = 0
                }
            }
        }

        console.log(rangeStoreVal, '/***********/')

        onChange(state, 'hardware')
    }

    // toggel btn
    handleSTMPswitch = (e) => {
        const { state, onChange } = this.props

        console.log(this.state.isClickSTMP)

        console.log('handleSTMPswitch', e.target.value)
        console.log('handleSTMPswitch', e.target.checked)
        console.log('props/////', this.props)

        if (this.state.isClickSTMP) {
            if (e.target.checked) {
                state[`value${e.target.value}`] = 1
                state[`assign${e.target.value}`] = true

                rangeStoreVal['STPM_SWITCH'][e.target.value].isChecked = true
                rangeStoreVal['STPM_SWITCH'][e.target.value].value = 1

                console.log('HAVE SOME DATA', rangeStoreVal.STPM_SWITCH)

                this.setState({
                    isClickSTMP_SWITCH: true,
                })
            }
            if (!e.target.checked) {
                state[`value${e.target.value}`] = 0
                state[`assign${e.target.value}`] = true

                rangeStoreVal['STPM_SWITCH'][e.target.value].isChecked = false
                rangeStoreVal['STPM_SWITCH'][e.target.value].value = 0

                console.log('HAVE SOME DATA', rangeStoreVal.STPM_SWITCH)

                this.setState({
                    isClickSTMP_SWITCH: false,
                })
            }
        }

        onChange(state, 'hardware')
    }

    onChangehandleRadioBtn = (e) => {
        const { state, onChange } = this.props

        console.log(e.target, 'element')
        console.log(e.target.value, 'radio Value')
        console.log(e.target.checked, 'radio checked')

        HumanoidActiveBtn.isCheckRadioAction = `${e.target.value}#${e.target.checked}`

        sessionStorage.setItem(
            'HumanoidActiveBtn',
            `${e.target.value}#${e.target.checked}`
        )

        let actionName = e.target.value.replace(/\s+/g, '_')
        console.log(actionName, 'actionName')

        let portNumber = HumanoidAction(actionName)

        currentHumanoidAction = actionName

        console.log('actionName: ', actionName, ' actionValue: ', portNumber)

        // HumanoidactionName = actionName;
        // HumanoidactionValue = portNumber;

        // console.log(HumanoidactionName);

        // console.log(HumanoidactionValue);
        // this.setState({
        //   HumanoidactionName: actionName,
        //   HumanoidactionValue: portNumber,
        // });

        // get the data in second render

        console.log(this.state, 'DATA STATEs')

        console.log(prevHumanoidAction, 'prevHumanoidAction')

        // here setting previous to false
        if (
            prevHumanoidAction != null &&
            prevHumanoidAction != currentHumanoidAction
        ) {
            state[`assign${prevHumanoidAction}`] = false
        }
        state[`assign${actionName}`] = true

        state[`value${actionName}`] = portNumber
        onChange(state, 'hardware') //Important for Type change for hardware

        this.setState({
            isClickRadio: `${e.target.value}#${e.target.checked}`,
        })
        prevHumanoidAction = currentHumanoidAction
        console.log(
            'prevHumanoidAction',
            prevHumanoidAction,
            ' currentHumanoidAction',
            currentHumanoidAction
        )

        // console.log(this.props.state, "DATA2..0 STATEs");

        // let myKey = Object.keys(this.props.state);
        // console.log(myKey);
    }

    // NEW UI
    // SMILE ONE , TWO, Three, Four
    onOLED1Handle = () => {
        const { state, onChange } = this.props
        if (this.state.isClickOLED1) {
            this.setState({
                isClickOLED1: false,
            })
            state[`assignOLEDOne`] = false

            // rangeStoreVal["SmileOne"].isChecked = false;
        } else {
            this.setState({
                isClickOLED1: true,
            })
            state[`assignOLEDOne`] = true
            // rangeStoreVal["SmileOne"].isChecked = true;
        }

        onChange(state, 'hardware')
    }
    onOLED2Handle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickOLED2) {
            this.setState({
                isClickOLED2: false,
            })
            state[`assignOLEDTwo`] = false

            rangeStoreVal['OLEDTwo'].isChecked = false
        } else {
            this.setState({
                isClickOLED2: true,
            })
            state[`assignOLEDTwo`] = true
            rangeStoreVal['OLEDTwo'].isChecked = true
        }

        onChange(state, 'hardware')
    }
    onOLED3Handle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickOLED3) {
            this.setState({
                isClickOLED3: false,
            })
            state[`assignOLEDThree`] = false

            // rangeStoreVal["SmileOne"].isChecked = false;
        } else {
            this.setState({
                isClickOLED3: true,
            })
            state[`assignOLEDThree`] = true
            // rangeStoreVal["SmileOne"].isChecked = true;
        }

        onChange(state, 'hardware')
    }
    onOLED4Handle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickOLED4) {
            this.setState({
                isClickOLED4: false,
            })
            state[`assignOLEDFour`] = false

            // rangeStoreVal["SmileOne"].isChecked = false;
        } else {
            this.setState({
                isClickOLED4: true,
            })
            state[`assignOLEDFour`] = true
            // rangeStoreVal["SmileOne"].isChecked = true;
        }

        onChange(state, 'hardware')
        this.onOLED4HandleText()
    }

    onRGBCompIncrease = () => {
        const { state, onChange } = this.props
        if (this.state.countRGBComp < 10) {
            this.setState({
                countRGBComp: this.state.countRGBComp + 1,
            })
            state[`countRGBComp`] = this.state.countRGBComp + 1
            onChange(state, 'hardware')
        }
    }
    onRGBCompDecrease = () => {
        const { state, onChange } = this.props
        var isClickRGBComp = this.state.isClickRGBComp

        if (this.state.countRGBComp > 1) {
            isClickRGBComp[this.state.countRGBComp] = false
            this.setState({
                countRGBComp: this.state.countRGBComp - 1,
                isClickRGBComp: isClickRGBComp,
            })
            state[`countRGBComp`] = this.state.countRGBComp - 1
            state[`valueRGBComp${this.state.countRGBComp}R`] = 0
            state[`valueRGBComp${this.state.countRGBComp}G`] = 0
            state[`valueRGBComp${this.state.countRGBComp}B`] = 0
            state[`assignRGBComp${this.state.countRGBComp}`] = false
            onChange(state, 'hardware')
        }
    }
    onRGBCompHandle = (i) => {
        const { state, onChange } = this.props
        var isClickRGBComp = this.state.isClickRGBComp
        if (this.state.isClickRGBComp[`isClickRGBComp${i}`]) {
            isClickRGBComp[`isClickRGBComp${i}`] = false
            this.setState({
                isClickRGBComp: isClickRGBComp,
            })

            state[`assignRGBComp${i}`] = false

            // rangeStoreVal["SmileOne"].isChecked = false;
        } else {
            isClickRGBComp[`isClickRGBComp${i}`] = true
            this.setState({
                isClickRGBComp: isClickRGBComp,
            })
            state[`assignRGBComp${i}`] = true
            // rangeStoreVal["SmileOne"].isChecked = true;
        }
        onChange(state, 'hardware')
    }
    onRangeValueRGBComp = (i, value, color) => {
        const { state, onChange } = this.props
        state[`valueRGBComp${i}${color}`] = value
        onChange(state, 'hardware')
    }
    onSmileOneHandle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickSmileOne) {
            state[`assignSmileOne`] = false
            state['valueSmileOne'] = 0

            rangeStoreVal['SmileOne'].isChecked = false
            rangeStoreVal['SmileOne'].value = 0
            this.setState({
                isClickSmileOne: false,
                curValSmileOne: 0,
            })
        } else {
            this.setState({
                isClickSmileOne: true,
            })
            state[`assignSmileOne`] = true
            rangeStoreVal['SmileOne'].isChecked = true
        }

        console.log(this.state.isClickSmileOne, 'SMILE ONE CLICK')
        console.log(this.state.isClickSmileOne, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }

    onBic1Handle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickBic1) {
            this.setState({
                isClickBic1: false,
            })
            state[`assignBic1`] = false

            rangeStoreVal['BICOUNTER1'].isChecked = false
        } else {
            this.setState({
                isClickBic1: true,
            })
            state[`assignBic1`] = true
            rangeStoreVal['BICOUNTER1'].isChecked = true
        }

        console.log(this.state.isClickBic1, 'SMILE ONE CLICK')
        console.log(this.state.isClickBic1, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }
    onBid1Handle = () => {
        const { state, onChange } = this.props
        if (this.state.isClickBid1) {
            this.setState({
                isClickBid1: false,
            })
            state[`assignBid1`] = false
        } else {
            this.setState({
                isClickBid1: true,
            })
            state[`assignBid1`] = true
        }
        onChange(state, 'hardware')
    }
    onBid2Handle = () => {
        const { state, onChange } = this.props
        if (this.state.isClickBid2) {
            this.setState({
                isClickBid2: false,
            })
            state[`assignBid2`] = false
        } else {
            this.setState({
                isClickBid2: true,
            })
            state[`assignBid2`] = true
        }
        onChange(state, 'hardware')
    }

    onBic2Handle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickBic2) {
            this.setState({
                isClickBic2: false,
            })
            state[`assignBic2`] = false

            rangeStoreVal['BICOUNTER2'].isChecked = false
        } else {
            this.setState({
                isClickBic2: true,
            })
            state[`assignBic2`] = true
            rangeStoreVal['BICOUNTER2'].isChecked = true
        }

        console.log(this.state.isClickBic2, 'SMILE ONE CLICK')
        console.log(this.state.isClickBic2, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }

    onBif1Handle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickBif1) {
            this.setState({
                isClickBif1: false,
            })
            state[`assignBif1`] = false

            rangeStoreVal['BIFLAG1'].isChecked = false
        } else {
            this.setState({
                isClickBif1: true,
            })
            state[`assignBif1`] = true
            rangeStoreVal['BIFLAG1'].isChecked = true
        }

        console.log(this.state.isClickBif1, 'SMILE ONE CLICK')
        console.log(this.state.isClickBif1, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }

    onBif2Handle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickBif2) {
            this.setState({
                isClickBif2: false,
            })
            state[`assignBif2`] = false

            rangeStoreVal['BIFLAG2'].isChecked = false
        } else {
            this.setState({
                isClickBif2: true,
            })
            state[`assignBif2`] = true
            rangeStoreVal['BIFLAG2'].isChecked = true
        }

        console.log(this.state.isClickBif2, 'SMILE ONE CLICK')
        console.log(this.state.isClickBif2, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }

    onUsbtxHandle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickUsbtx) {
            this.setState({
                isClickUsbtx: false,
            })
            state[`assignUsbtx`] = false

            rangeStoreVal['USBTX'].isChecked = false
        } else {
            this.setState({
                isClickUsbtx: true,
            })
            state[`assignUsbtx`] = true
            rangeStoreVal['USBTX'].isChecked = true
        }

        console.log(this.state.isClickUsbtx, 'SMILE ONE CLICK')
        console.log(this.state.isClickUsbtx, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }
    onbtTxHandle = () => {
        const { state, onChange } = this.props
        if (this.state.isClickbtTx1) {
            this.setState({
                isClickbtTx1: false,
            })
            state[`assignBtTx1`] = false

            rangeStoreVal['BTTX'].isChecked = false
        } else {
            this.setState({
                isClickbtTx1: true,
            })
            state[`assignBtTx1`] = true
            rangeStoreVal['BTTX'].isChecked = true
        }

        console.log(this.state.isClickbtTx1, 'SMILE ONE CLICK')
        console.log(this.state.isClickbtTx1, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }
    onbtTx2Handle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickbtTx2) {
            this.setState({
                isClickbtTx2: false,
            })
            state[`assignBtTx2`] = false

            rangeStoreVal['BTTX'].isChecked = false
        } else {
            this.setState({
                isClickbtTx2: true,
            })
            state[`assignBtTx2`] = true
            rangeStoreVal['BTTX'].isChecked = true
        }

        console.log(this.state.isClickbtTx2, 'SMILE ONE CLICK')
        console.log(this.state.isClickbtTx2, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }
    onbtTx3Handle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickbtTx3) {
            this.setState({
                isClickbtTx3: false,
            })
            state[`assignBtTx3`] = false

            rangeStoreVal['BTTX'].isChecked = false
        } else {
            this.setState({
                isClickbtTx3: true,
            })
            state[`assignBtTx3`] = true
            rangeStoreVal['BTTX'].isChecked = true
        }

        console.log(this.state.isClickbtTx3, 'SMILE ONE CLICK')
        console.log(this.state.isClickbtTx3, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }
    onbtTx4Handle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickbtTx4) {
            this.setState({
                isClickbtTx4: false,
            })
            state[`assignBtTx4`] = false

            rangeStoreVal['BTTX'].isChecked = false
        } else {
            this.setState({
                isClickbtTx4: true,
            })
            state[`assignBtTx4`] = true
            rangeStoreVal['BTTX'].isChecked = true
        }

        console.log(this.state.isClickbtTx4, 'SMILE ONE CLICK')
        console.log(this.state.isClickbtTx4, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }
    onbtTx5Handle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickbtTx5) {
            this.setState({
                isClickbtTx5: false,
            })
            state[`assignBtTx5`] = false

            rangeStoreVal['BTTX'].isChecked = false
        } else {
            this.setState({
                isClickbtTx5: true,
            })
            state[`assignBtTx5`] = true
            rangeStoreVal['BTTX'].isChecked = true
        }

        console.log(this.state.isClickbtTx5, 'SMILE ONE CLICK')
        console.log(this.state.isClickbtTx5, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }

    onSmileTwoHandle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickSmileTwo) {
            state[`assignSmileTwo`] = false
            state['valueSmileTwo'] = 0

            rangeStoreVal['SmileTwo'].isChecked = false
            rangeStoreVal['SmileTwo'].value = 0
            this.setState({
                isClickSmileTwo: false,
                curValSmileTwo: 0,
            })
        } else {
            this.setState({
                isClickSmileTwo: true,
            })
            state[`assignSmileTwo`] = true
            rangeStoreVal['SmileTwo'].isChecked = true
        }

        console.log(this.state.isClickSmileTwo, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }
    onSmileThreeHandle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickSmileThree) {
            state[`assignSmileThree`] = false
            state['valueSmileThree'] = 0

            rangeStoreVal['SmileThree'].isChecked = false
            rangeStoreVal['SmileThree'].value = 0
            this.setState({
                isClickSmileThree: false,
                curValSmileThree: 0,
            })
        } else {
            this.setState({
                isClickSmileThree: true,
            })
            state[`assignSmileThree`] = true
            rangeStoreVal['SmileThree'].isChecked = true
        }

        console.log(this.state.isClickSmileThree, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }
    onSmileFourHandle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickSmileFour) {
            state[`assignSmileFour`] = false
            state['valueSmileFour'] = 0

            rangeStoreVal['SmileFour'].isChecked = false
            rangeStoreVal['SmileFour'].value = 0
            this.setState({
                isClickSmileFour: false,
                curValSmileFour: 0,
            })
        } else {
            this.setState({
                isClickSmileFour: true,
            })
            state[`assignSmileFour`] = true
            rangeStoreVal['SmileFour'].isChecked = true
        }

        console.log(this.state.isClickSmileFour, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }

    onLeftEeyHandle = () => {
        const { state, onChange } = this.props

        console.log('left EYE CLICK')
        if (this.state.isClickLeftEye) {
            state[`assignLeftEye`] = false
            state[`assignLeftEyeR`] = false
            state[`assignLeftEyeG`] = false
            state[`assignLeftEyeB`] = false

            state[`valueLeftEye`] = 0
            state[`valueLeftEyeR`] = 0
            state[`valueLeftEyeG`] = 0
            state[`valueLeftEyeB`] = 0

            rangeStoreVal['LeftEye'].isChecked = false
            rangeStoreVal['LeftEye'].value = 0
            this.setState({
                isClickLeftEye: false,
                curValLeftEyeR: 0,
                curValLeftEyeG: 0,
                curValLeftEyeB: 0,
            })
        } else {
            this.setState({
                isClickLeftEye: true,
            })
            state[`assignLeftEye`] = true

            state[`assignLeftEyeR`] = true
            state[`assignLeftEyeG`] = true
            state[`assignLeftEyeB`] = true

            rangeStoreVal['LeftEye'].isChecked = true
        }

        onChange(state, 'hardware')
    }

    onHandleEye = (value = null) => {
        const { state, onChange } = this.props
        if (this.state[`isClickEye${value}`]) {
            state[`assignEye${value}`] = false
            state[`valueEye${value}`] = 0

            rangeStoreVal['RGB' + String(value)].isChecked = false
            rangeStoreVal['RGB' + String(value)].value = false
            this.setState({
                [`isClickEye${value}`]: false,

                [` curValEye${value}`]: 0,
            })
        } else {
            this.setState({
                [`isClickEye${value}`]: true,
            })

            state[`assignEye${value}`] = true
            rangeStoreVal['RGB' + String(value)].isChecked = true
        }
        onChange(state, 'hardware')
    }

    onRightEeyHandle = () => {
        const { state, onChange } = this.props
        console.log('Right Eye clicked', state)

        if (this.state.isClickRightEye) {
            state[`assignRightEye`] = false
            state[`assignRightEyeR`] = false
            state[`assignRightEyeG`] = false
            state[`assignRightEyeB`] = false

            state[`valueRightEye`] = 0
            state[`valueRightEyeR`] = 0
            state[`valueRightEyeG`] = 0
            state[`valueRightEyeB`] = 0

            rangeStoreVal['RightEye'].isChecked = false
            rangeStoreVal['RightEye'].value = 0
            this.setState({
                isClickRightEye: false,
                curValRightEyeR: 0,
                curValRightEyeG: 0,
                curValRightEyeB: 0,
            })
        } else {
            this.setState({
                isClickRightEye: true,
            })
            state[`assignRightEye`] = true
            state[`assignRightEyeR`] = true
            state[`assignRightEyeG`] = true
            state[`assignRightEyeB`] = true
            rangeStoreVal['RightEye'].isChecked = true
        }
        onChange(state, 'hardware')
    }

    onRightEye_B_Handle = () => {
        const { state, onChange } = this.props

        if (this.state.isClickRightEyeB) {
            state[`assignRightEyeB`] = false
            state['valueRightEyeB'] = 0

            rangeStoreVal['RightEye']['B'].isChecked = false
            rangeStoreVal['RightEye']['B'].value = false

            this.setState({
                isClickRightEyeB: false,
                curValRightEyeB: 0,
            })
        } else {
            this.setState({
                isClickRightEyeB: true,
            })
            state[`assignRightEyeB`] = true
            rangeStoreVal['RightEye']['B'].isChecked = true
        }

        console.log(this.state.isClickRightEyeB, 'SMILE ONE CLICK')

        onChange(state, 'hardware')
    }

    // New UI Buzzer
    onChangeCheckboxBuzzer = () => {
        // const { state } = this.props;

        const { state, onChange } = this.props

        if (this.state.isClickBuzzer) {
            state[`assignBuzzer`] = false

            state[`assignBuzzerFrequency`] = false
            state[`assignBuzzerTone`] = false
            state['valueBuzzerFrequency'] = 0
            state['valueBuzzerTone'] = 0
            state['valueBuzzer'] = 0

            rangeStoreVal['BuzzerFrequency'].isChecked = false
            rangeStoreVal['BuzzerTone'].isChecked = false

            rangeStoreVal['Buzzer'].isChecked = false
            rangeStoreVal['BuzzerFrequency'].value = 0
            rangeStoreVal['BuzzerTone'].value = 0

            rangeStoreVal['Buzzer'].value = 0
            this.setState({
                isClickBuzzer: false,
                curValBuzzer: 0,
            })
        } else {
            this.setState({
                isClickBuzzer: true,
            })
            state[`assignBuzzer`] = true
            rangeStoreVal['Buzzer'].isChecked = true

            state[`assignBuzzerFrequency`] = true
            state[`assignBuzzerTone`] = false
            rangeStoreVal['BuzzerFrequency'].isChecked = true
            rangeStoreVal['BuzzerTone'].isChecked = false
        }

        onChange(state, 'hardware')
    }
    onChangeCheckboxBidata1 = () => {
        const { state, onChange } = this.props
        if (this.state.isClickBid1) {
            state[`assignBid1`] = false

            state[`assignBid1Value`] = false
            state[`assignBid1Assign`] = false
            state['valueBid1value'] = 0
            state['valueBid1Assign'] = 0
            this.setState({
                isClickBid1: false,
            })
        } else {
            state[`assignBid1`] = true
            state[`assignBid1Value`] = true
            this.setState({
                isClickBid1: true,
            })
        }
        onChange(state, 'hardware')
    }
    //playC 1058 - 0.1 PeeCee - Start Panel - Web Bugs;
    onChangeCheckboxBiTx1 = () => {
        const { state, onChange } = this.props
        if (state.assignBtTx1) {
            state[`assignBtTx1`] = false

            state[`assignBtTx1Value`] = false
            state[`assignBtTx1Assign`] = false
            state['valueBtTx1value'] = 0
            state['valueBtTx1Assign'] = 0
            this.setState({
                isClickbtTx1: false,
            })
        } else {
            state[`assignBtTx1`] = true
            state[`assignBtTx1Value`] = true
            this.setState({
                isClickbtTx1: true,
            })
        }
        onChange(state, 'hardware')
    }
    onChangeCheckboxBiTx2 = () => {
        console.log('selected biTx2')
        const { state, onChange } = this.props

        if (state.assignBtTx2) {
            state[`assignBtTx2`] = false

            state[`assignBtTx2Value`] = false
            state[`assignBtTx2Assign`] = false
            state['valueBtTx2value'] = 0
            state['valueBtTx2Assign'] = 0
            this.setState({
                isClickbtTx2: false,
            })
        } else {
            state[`assignBtTx2`] = true
            state[`assignBtTx2Value`] = true
            this.setState({
                isClickbtTx2: true,
            })
        }
        onChange(state, 'hardware')
    }
    onChangeCheckboxBiTx3 = () => {
        console.log('selected biTx3')
        const { state, onChange } = this.props

        if (state.assignBtTx3) {
            state[`assignBtTx3`] = false

            state[`assignBtTx3Value`] = false
            state[`assignBtTx3Assign`] = false
            state['valueBtTx3value'] = 0
            state['valueBtTx3Assign'] = 0
            this.setState({
                isClickbtTx3: false,
            })
        } else {
            state[`assignBtTx3`] = true
            state[`assignBtTx3Value`] = true
            this.setState({
                isClickbtTx3: true,
            })
        }
        onChange(state, 'hardware')
    }
    onChangeCheckboxBiTx4 = () => {
        console.log('selected biTx4')
        const { state, onChange } = this.props

        if (state.assignBtTx4) {
            state[`assignBtTx4`] = false

            state[`assignBtTx4Value`] = false
            state[`assignBtTx4Assign`] = false
            state['valueBtTx4value'] = 0
            state['valueBtTx4Assign'] = 0
            this.setState({
                isClickbtTx4: false,
            })
        } else {
            state[`assignBtTx4`] = true
            state[`assignBtTx4Value`] = true
            this.setState({
                isClickbtTx4: true,
            })
        }
        onChange(state, 'hardware')
    }
    onChangeCheckboxBiTx5 = () => {
        console.log('selected biTx5')
        const { state, onChange } = this.props

        if (state.assignBtTx5) {
            state[`assignBtTx5`] = false

            state[`assignBtTx5Value`] = false
            state[`assignBtTx5Assign`] = false
            state['valueBtTx5value'] = 0
            state['valueBtTx5Assign'] = 0
            this.setState({
                isClickbtTx5: false,
            })
        } else {
            state[`assignBtTx5`] = true
            state[`assignBtTx5Value`] = true
            this.setState({
                isClickbtTx5: true,
            })
        }
        onChange(state, 'hardware')
    }
    onChangeCheckboxAsgn = (n) => {
        const { state, onChange } = this.props

        if (eval(`this.state.isClickAsgn${n}`)) {
            state[`assignAsgn${n}`] = false
            state[`valueAsgn${n}Input`] = 'default'
            state[`valueAsgn${n}Output`] = 'default'
            eval(
                ` this.setState({
                    isClickAsgn${n}: false,
                })`
            )
        } else {
            state[`assignAsgn${n}`] = true
            eval(
                ` this.setState({
                    isClickAsgn${n}: true,
                })`
            )
        }
        onChange(state, 'hardware')
    }
    onChangeCheckboxBidata2 = () => {
        const { state, onChange } = this.props

        if (this.state.isClickBid2) {
            state[`assignBid2`] = false

            state[`assignBid2Value`] = false
            state[`assignBid2Assign`] = false
            state['valueBid2value'] = 0
            state['valueBid2Assign'] = 0
            this.setState({
                isClickBid2: false,
            })
        } else {
            state[`assignBid2`] = true
            state[`assignBid2Value`] = true
            this.setState({
                isClickBid2: true,
            })
        }
        onChange(state, 'hardware')
    }
    onChangeCheckboxTouchZeroOutput = () => {
        const { state, onChange } = this.props

        if (this.state.isClickTouchZeroOutput) {
            state[`assignTouchZeroOutput`] = false
            state[`valueTouchZeroOutput`] = 0

            rangeStoreVal['TouchZeroOutput'].isChecked = false
            rangeStoreVal['TouchZeroOutput'].value = 0
            this.setState({
                isClickTouchZeroOutput: false,
                curValTouchZeroOutput: 0,
            })
        } else {
            this.setState({
                isClickTouchZeroOutput: true,
            })
            state[`assignTouchZeroOutput`] = true
            rangeStoreVal['TouchZeroOutput'].isChecked = true
        }

        console.log(this.state.isClickTouchZeroOutput, 'BUZZER CLICK')

        onChange(state, 'hardware')
    }
    onChangeCheckboxTouchOneOutput = () => {
        const { state, onChange } = this.props

        if (this.state.isClickTouchOneOutput) {
            state[`assignTouchOneOutput`] = false
            state[`valueTouchOneOutput`] = 0

            rangeStoreVal['TouchOneOutput'].isChecked = false
            rangeStoreVal['TouchOneOutput'].value = 0
            this.setState({
                isClickTouchOneOutput: false,
                curValTouchOneOutput: 0,
            })
        } else {
            this.setState({
                isClickTouchOneOutput: true,
            })
            state[`assignTouchOneOutput`] = true
            rangeStoreVal['TouchOneOutput'].isChecked = true
        }

        console.log(this.state.isClickTouchOneOutput, 'BUZZER CLICK')

        onChange(state, 'hardware')
    }

    onChangeCheckboxTouchTwoOutput = () => {
        const { state, onChange } = this.props

        if (this.state.isClickTouchTwoOutput) {
            state[`assignTouchTwoOutput`] = false
            state[`valueTouchTwoOutput`] = 0

            rangeStoreVal['TouchTwoOutput'].isChecked = false
            rangeStoreVal['TouchTwoOutput'].value = 0
            this.setState({
                isClickTouchTwoOutput: false,
                curValTouchTwoOutput: 0,
            })
        } else {
            this.setState({
                isClickTouchTwoOutput: true,
            })
            state[`assignTouchTwoOutput`] = true
            rangeStoreVal['TouchTwoOutput'].isChecked = true
        }

        onChange(state, 'hardware')
    }
    onChangeCheckboxTouchThreeOutput = () => {
        const { state, onChange } = this.props

        if (this.state.isClickTouchThreeOutput) {
            state[`assignTouchThreeOutput`] = false
            state[`valueTouchThreeOutput`] = 0

            rangeStoreVal['TouchThreeOutput'].isChecked = false
            rangeStoreVal['TouchThreeOutput'].value = 0
            this.setState({
                isClickTouchThreeOutput: false,
                curValTouchThreeOutput: 0,
            })
        } else {
            this.setState({
                isClickTouchThreeOutput: true,
            })
            state[`assignTouchThreeOutput`] = true
            rangeStoreVal['TouchThreeOutput'].isChecked = true
        }

        onChange(state, 'hardware')
    }

    onRangeValue_Buzzer_smile_Eyes = (name, portName, value) => {
        const { state, onChange } = this.props

        if (name == 'Touch 0 Output') {
            rangeStoreVal['TouchZeroOutput'].value = value
            state['valueTouchZeroOutput'] = value
        } else if (name == 'Touch 1 Output') {
            rangeStoreVal['TouchOneOutput'].value = value
            state['valueTouchOneOutput'] = value
        } else if (name == 'Touch 2 Output') {
            rangeStoreVal['TouchTwoOutput'].value = value
            state['valueTouchTwoOutput'] = value
        } else if (name == 'Touch 3 Output') {
            rangeStoreVal['TouchThreeOutput'].value = value
            state['valueTouchThreeOutput'] = value
        } else if (name == 'SmileOne') {
            rangeStoreVal[name].value = value
            state['valueSmileOne'] = value
        } else if (name == 'SmileTwo') {
            rangeStoreVal[name].value = value
            state['valueSmileTwo'] = value
        } else if (name == 'SmileThree') {
            rangeStoreVal[name].value = value
            state['valueSmileThree'] = value
        } else if (name == 'SmileFour') {
            rangeStoreVal[name].value = value
            state['valueSmileFour'] = value
        } else if (name == 'BICOUNTER1') {
            rangeStoreVal[name].value = value
            state['valueBic1'] = value
        } else if (name == 'BICOUNTER2') {
            rangeStoreVal[name].value = value
            state['valueBic2'] = value
        } else if (name == 'BIFLAG1') {
            rangeStoreVal[name].value = value
            state['valueBif1'] = value
        } else if (name == 'BIFLAG2') {
            rangeStoreVal[name].value = value
            state['valueBif2'] = value
        } else if (name == 'USBTX') {
            rangeStoreVal[name].value = value
            state['valueUsbtx'] = value
        } else if (name == 'BTTX') {
            rangeStoreVal[name].value = value
            state['valuebtTx'] = value
        } else if (name == 'Buzzer') {
            rangeStoreVal[`${name}${portName}`].value = value
            state[`valueBuzzer${portName}`] = value
        } else if (name == 'LeftEye') {
            this.setState({ setportName: portName })
            rangeStoreVal[name][portName].value = value
            state[`value${name}${portName}`] = value
        } else if (name == 'RightEye') {
            rangeStoreVal[name][portName].value = value
            state[`value${name}${portName}`] = value
        } else if (name.slice(0, 3) === 'RGB') {
            rangeStoreVal[name.replace(/\s+/g, '')][portName].value = value
            state[`valueEye${portName}${name.slice(4)}`] = value
        }

        sessionStorage.setItem(
            'rangeValCheckbox',
            JSON.stringify(rangeStoreVal)
        )
        onChange(state, 'hardware') //Important for Type change for hardware
    }

    // onChangeSliderValue =
    onOLED1HandleText = (e) => {
        console.log('OLED1', e)
        this.setState({ curValOLED1: e.target.value })
        const { state, onChange } = this.props
        state['valueOLEDOne'] = e.target.value
        onChange(state, 'hardware')
    }
    onOLED2HandleText = (e) => {
        this.setState({ curValOLED1: e.target.value })
        const { state, onChange } = this.props
        state['valueOLEDTwo'] = e.target.value
        onChange(state, 'hardware')
    }
    onOLED3HandleText = (e) => {
        this.setState({ curValOLED1: e.target.value })
        const { state, onChange } = this.props
        state['valueOLEDThree'] = e.target.value
        onChange(state, 'hardware')
    }
    onOLED4HandleText = (e) => {
        const { state, onChange } = this.props
        if (e == undefined) {
            this.setState({ curValOLED1: 'default' })

            state['valueOLEDFour'] = 'default'
        } else {
            this.setState({ curValOLED1: e.target.value })

            state['valueOLEDFour'] = e.target.value
        }
        onChange(state, 'hardware')
    }
    hexTypeCheck = () => {
        const { state, onChange } = this.props

        // onChange(state, "hardware");
    }

    renderSTPM = () => {
        if (this.props.PortConnections['STPM'] != null) {
            var type = this.props.PortConnections['STPM'].type
            var port = 'STPM'

            // console.log(type, "gesture1");
            var max, min
            var compName = type.toLowerCase()
            var range = PortValuesRangeMapping[port][compName](port)
            console.log('RANGE 003', range)
            min = range.min
            max = range.max
            const componentProps = this.props.concept.componentProps

            return (
                <SliderRow
                    type={type}
                    name={componentProps[type].name}
                    port={port}
                    value={this.props.state['value' + port]}
                    key={port}
                    assign={this.state.isClickSTMP}
                    onChangeSTPM={this.handleSTPM}
                    min={min}
                    max={max}
                />
            )
        } else {
            return null
        }
    }

    renderStepperToggle = () => {
        // return (
        //   <>
        //     <p>asd</p>
        //     <p>asd</p>
        //     <p>asd</p>
        //     <p>asd</p>
        //   </>
        // );
        if (this.props.PortConnections['STPM'] != null) {
            const componentProps = this.props.concept.componentProps
            return Object.keys(this.props.PortConnections).map((port) => {
                console.log(port)
                if (!this.props.PortConnections[port]) return null
                var type = this.props.PortConnections[port].type
                var max, min
                if (type == 'stepper_motor' && port !== 'STPM') {
                    return (
                        <>
                            <SliderRow
                                type={type}
                                name={componentProps[type].name}
                                port={port}
                                value={rangeStoreVal['STPM_SWITCH'][port].value}
                                assign={
                                    rangeStoreVal['STPM_SWITCH'][port].isChecked
                                }
                                key={port}
                                onChange={this.handleSTMPswitch}
                                min={min}
                                max={max}
                                isClickSTMP={this.state.isClickSTMP}
                            />
                        </>
                    )
                } else {
                    return null
                }
                // console.log(type, "gesture1");
            })
        } else {
            return null
        }
    }

    render() {
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        const { state, startState, bottomPanelDeleteKey } = this.props
        let { PortConnections } = this.props

        console.log(this.props, 'PROPS DATATA')

        const componentProps = this.props.concept.componentProps

        SelectOptionsOrder = ['edt' /*, 'bpr', 'irr'*/, 'btr', 'bts']

        const assemblyCheckbox = JSON.parse(
            sessionStorage.getItem('assemblyCheckbox')
        )

        const internalAccessories = JSON.parse(
            sessionStorage.getItem('concept')
        ).internalaccessories

        const startTypes = JSON.parse(sessionStorage.getItem('logic'))
            .program[0].state
        console.log(
            'YELO DATA',
            startTypes,
            internalAccessories,
            PortConnections
        )

        console.log(assemblyCheckbox, 'assemblyCheckbox')

        var portsConnectedArray = []
        for (var eachConnection in PortConnections) {
            portsConnectedArray.push(eachConnection)
        }

        const isHumanoidPortEmpty = () => {
            let humanoidPortDatas = JSON.parse(
                sessionStorage.getItem('humanoidPortData')
            )
            let result = []
            if (humanoidPortDatas === undefined || humanoidPortDatas === null)
                return
            Object.values(humanoidPortDatas).map((item, index) => {
                let data = item
                if (
                    data.name === 'Individual Servos' ||
                    data.name === 'MP3 Port' ||
                    data.name === 'Action Group' ||
                    data.name === 'Right RGB' ||
                    data.name === 'Left RGB' ||
                    data.name === 'Servo Head' ||
                    data.name === 'M1' ||
                    data.name === 'M2' ||
                    data.name === 'Servo'
                ) {
                    if (data.isPortSelected == true) {
                        result.push(true)
                    } else {
                        result.push(false)
                    }
                }
            })
            return result.every((val) => val === false)
        }

        const isPortConnectionEmpty = () => {
            let result = []
            Object.keys(PortConnections).map((item, index) => {
                let values = PortConnections[item]
                if (values != null) {
                    if (
                        [
                            'laser',
                            'led_1c',
                            'led',
                            'RGB',
                            'OLED',
                            'geared_motor',
                            'mini_geared_motor',
                            'dc_motor',
                            'dynamex_motor',
                            'servo_motor',
                            'servo_motor_270',
                            'servo_motor_360',
                        ].includes(values.type)
                    ) {
                        result.push(true)
                    } else {
                        result.push(false)
                    }
                }
            })
            return result.includes(true)
        }

        //for "outertabDiv-HardwareText" div codition check start panel select variabel or not
        const checkStartTypes = () => {
            let flag = false
            let startTVariabel = JSON.parse(sessionStorage.getItem('logic'))
                .program[0].state
            Object.keys(startTVariabel).forEach((ele) => {
                if (startTVariabel[ele]) {
                    flag = true
                    return true
                }
            })
            if (flag) {
                return true
            } else {
                return false
            }
        }

        //re arrange the PortConnections object when Pc v1.0 connect with pcatr and dc motor connect with M3 and M4
        if (
            connectedDevice == 'Ace' &&
            sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
            JSON.parse(sessionStorage.getItem('peeCeeATR'))
        ) {
            if (
                (PortConnections['E1'] !== null &&
                    [
                        'dc_motor',
                        'geared_motor',
                        'mini_geared_motor',
                        'dynamex_motor',
                    ].includes(PortConnections['E1'].type)) ||
                (PortConnections['F1'] !== null &&
                    [
                        'dc_motor',
                        'geared_motor',
                        'mini_geared_motor',
                        'dynamex_motor',
                    ].includes(PortConnections['F1'].type))
            ) {
                let rearrangedObj = {}
                for (let key in PortConnections) {
                    if (key === 'M4') {
                        rearrangedObj[key] = PortConnections[key]
                        rearrangedObj['E1'] = PortConnections['E1']
                        rearrangedObj['F1'] = PortConnections['F1']
                    } else if (!['E1', 'F1'].includes(key)) {
                        rearrangedObj[key] = PortConnections[key]
                    }
                }

                PortConnections = { ...rearrangedObj }
            }
        }

        return (
            <div className="outertabDiv-Hardware">
                {['Humanoid', 'Hexapod', 'Klaw'].includes(connectedDevice) &&
                isHumanoidPortEmpty() == true &&
                isPortConnectionEmpty() == false &&
                !checkStartTypes() ? (
                    <div className="outertabDiv-HardwareText">
                        <p>
                            Please enable or connect an ouput accessory to
                            &nbsp;{connectedDevice}
                            <br />
                            to see hardware property panel options
                        </p>
                    </div>
                ) : null}

                <table
                    width="85%"
                    height="100%"
                    style={{
                        marginLeft: '14.5%',
                        borderCollapse: 'collapse',
                    }}
                >
                    {['Humanoid', 'Hexapod', 'Klaw'].includes(
                        connectedDevice
                    ) &&
                        this.props.logic.bottomPanel === 'show' && (
                            <ActionPanel
                                check={this.props.nodeId}
                                isUpdateRedux={true}
                            />
                        )}

                    {['Ace', 'Roboki'].includes(connectedDevice) ? (
                        <>
                            {sessionStorage
                                .getItem('deviceVersion')
                                ?.startsWith('1') &
                            (connectedDevice === 'Ace') ? (
                                <tbody>
                                    {Object.keys(internalAccessories).map(
                                        (value, index) => {
                                            if (value == 'isSmileOne') {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    console.log(
                                                        PortValuesRangeMapping[
                                                            value
                                                        ]
                                                    )
                                                    return (
                                                        <SliderRow
                                                            name="smile"
                                                            port="S1"
                                                            value={
                                                                this.state
                                                                    .curValSmileOne
                                                            }
                                                            key="smileOne"
                                                            title="SmileOne"
                                                            assign={
                                                                this.state
                                                                    .isClickSmileOne
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onSmileOneHandle
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (value == 'isSmileTwo') {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    console.log(
                                                        PortValuesRangeMapping[
                                                            value
                                                        ]
                                                    )
                                                    return (
                                                        <SliderRow
                                                            name="smile"
                                                            port="S2"
                                                            value={
                                                                this.state
                                                                    .curValSmileTwo
                                                            }
                                                            key="smileTwo"
                                                            title="SmileTwo"
                                                            assign={
                                                                this.state
                                                                    .isClickSmileTwo
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onSmileTwoHandle
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (
                                                value == 'isSmileThree'
                                            ) {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    console.log(
                                                        PortValuesRangeMapping[
                                                            value
                                                        ]
                                                    )
                                                    return (
                                                        <SliderRow
                                                            name="smile"
                                                            port="S3"
                                                            value={
                                                                this.state
                                                                    .curValSmileThree
                                                            }
                                                            key="smileThree"
                                                            title="SmileThree"
                                                            assign={
                                                                this.state
                                                                    .isClickSmileThree
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onSmileThreeHandle
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (value == 'isSmileFour') {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    console.log(
                                                        PortValuesRangeMapping[
                                                            value
                                                        ]
                                                    )
                                                    return (
                                                        <SliderRow
                                                            name="smile"
                                                            port="S4"
                                                            value={
                                                                this.state
                                                                    .curValSmileFour
                                                            }
                                                            key="smileFour"
                                                            title="SmileFour"
                                                            assign={
                                                                this.state
                                                                    .isClickSmileFour
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onSmileFourHandle
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (value == 'isbuzzer') {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    console.log(
                                                        PortValuesRangeMapping[
                                                            value
                                                        ]
                                                    )
                                                    return (
                                                        <SliderRow
                                                            changeState={
                                                                this.props
                                                            }
                                                            name="Buzzer"
                                                            // port="buzzer"
                                                            valueBuzzerFrequency={
                                                                this.state
                                                                    .curValBuzzerFrequency
                                                            }
                                                            valueBuzzerTone={
                                                                this.state
                                                                    .curValBuzzerTone
                                                            }
                                                            key="Buzzer"
                                                            title="Buzzer"
                                                            assign={
                                                                this.state
                                                                    .isClickBuzzer
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onChangeCheckboxBuzzer
                                                            }
                                                            min={0}
                                                            max={9000}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (
                                                value == 'isTouchZeroOutput'
                                            ) {
                                                console.log(
                                                    'plode assign',
                                                    this.state
                                                        .isClickTouchZeroOutpu
                                                )

                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    return (
                                                        <SliderRow
                                                            name="touchPadOutput"
                                                            port=""
                                                            value={
                                                                this.state
                                                                    .curValTouchZeroOutput
                                                            }
                                                            key="TouchZeroOutput"
                                                            title="Touch 0 Output"
                                                            assign={
                                                                this.state
                                                                    .isClickTouchZeroOutput
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onChangeCheckboxTouchZeroOutput
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (
                                                value == 'isTouchOneOutput'
                                            ) {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    return (
                                                        <SliderRow
                                                            name="touchPadOutput"
                                                            port=""
                                                            value={
                                                                this.state
                                                                    .curValTouchOneOutput
                                                            }
                                                            key="TouchOneOutput"
                                                            title="Touch 1 Output"
                                                            assign={
                                                                this.state
                                                                    .isClickTouchOneOutput
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onChangeCheckboxTouchOneOutput
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (
                                                value == 'isTouchTwoOutput'
                                            ) {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    return (
                                                        <SliderRow
                                                            name="touchPadOutput"
                                                            port=""
                                                            value={
                                                                this.state
                                                                    .curValTouchTwoOutput
                                                            }
                                                            key="TouchTwoOutput"
                                                            title="Touch 2 Output"
                                                            assign={
                                                                this.state
                                                                    .isClickTouchTwoOutput
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onChangeCheckboxTouchTwoOutput
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (
                                                value == 'isTouchThreeOutput'
                                            ) {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    return (
                                                        <SliderRow
                                                            name="touchPadOutput"
                                                            port=""
                                                            value={
                                                                this.state
                                                                    .curValTouchThreeOutput
                                                            }
                                                            key="TouchThreeOutput"
                                                            title="Touch 3 Output"
                                                            assign={
                                                                this.state
                                                                    .isClickTouchThreeOutput
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onChangeCheckboxTouchThreeOutput
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (value == 'isEYES') {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    console.log(
                                                        PortValuesRangeMapping[
                                                            value
                                                        ]
                                                    )
                                                    return (
                                                        <>
                                                            {[
                                                                1, 2, 3, 4, 5,
                                                                6, 7, 8, 9, 10,
                                                                11, 12,
                                                            ].map((value) => {
                                                                return (
                                                                    <>
                                                                        <SliderRow
                                                                            name="RightEye"
                                                                            RGBkey={
                                                                                value
                                                                            }
                                                                            port={
                                                                                this
                                                                                    .state
                                                                                    .setportName
                                                                            }
                                                                            value={
                                                                                this
                                                                                    .state[
                                                                                    `curValEye${this.state.portName}`
                                                                                ]
                                                                                //state[`valueLeftEye${this.state.setportName}`]
                                                                            }
                                                                            valR={
                                                                                this
                                                                                    .state[
                                                                                    `curValEyeR${value}`
                                                                                ]
                                                                            }
                                                                            valB={
                                                                                this
                                                                                    .state[
                                                                                    `curValEyeB${value}`
                                                                                ]
                                                                            }
                                                                            valG={
                                                                                this
                                                                                    .state[
                                                                                    `curValEyeG${value}`
                                                                                ]
                                                                            }
                                                                            key={
                                                                                'RGB' +
                                                                                String(
                                                                                    value
                                                                                )
                                                                            }
                                                                            title={
                                                                                'RGB ' +
                                                                                String(
                                                                                    value
                                                                                )
                                                                            }
                                                                            assign={
                                                                                this
                                                                                    .state[
                                                                                    `isClickEye${value}`
                                                                                ]
                                                                            }
                                                                            handlecheckbox={
                                                                                this
                                                                                    .onHandleEye
                                                                            }
                                                                            min={
                                                                                0
                                                                            }
                                                                            max={
                                                                                100
                                                                            }
                                                                            onSetSliderPort={
                                                                                this
                                                                                    .onSetSliderPort
                                                                            }
                                                                            getRangeVal={
                                                                                this
                                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                                            }
                                                                        />
                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                    )
                                                }
                                            }
                                        }
                                    )}

                                    {Object.keys(startTypes).map(
                                        (value, index) => {
                                            if (value == 'bic2') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={
                                                                'BI COUNTER 2'
                                                            }
                                                            port={'Bic2'}
                                                            value={
                                                                state[
                                                                    'valueBic2'
                                                                ]
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignBic2'
                                                                ]
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={-1}
                                                            max={1}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'bic1') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={
                                                                'BI COUNTER 1'
                                                            }
                                                            port={'Bic1'}
                                                            value={
                                                                state[
                                                                    'valueBic1'
                                                                ]
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignBic1'
                                                                ]
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={-1}
                                                            max={1}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'bif1') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'BI FLAG 1'}
                                                            port={'Bif1'}
                                                            value={
                                                                state[
                                                                    'valueBif1'
                                                                ]
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignBif1'
                                                                ]
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={1}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'bif2') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'BI FLAG 2'}
                                                            port={'Bif2'}
                                                            value={
                                                                state[
                                                                    'valueBif2'
                                                                ]
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignBif2'
                                                                ]
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={1}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'usbtx') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'USB TX'}
                                                            port={'Usbtx'}
                                                            value={
                                                                state[
                                                                    'valueUsbtx'
                                                                ]
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignUsbtx'
                                                                ]
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={255}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'btTx') {
                                                if (startTypes[value] == true) {
                                                    console.log(
                                                        PortValuesRangeMapping[
                                                            value
                                                        ]
                                                    )
                                                    return (
                                                        <>
                                                            <SliderRow
                                                                name={'BT TX 1'}
                                                                port={'BtTx1'}
                                                                value={
                                                                    state[
                                                                        'valueBtTx1'
                                                                    ]
                                                                }
                                                                changeState={
                                                                    this.props
                                                                }
                                                                assign={
                                                                    state[
                                                                        'assignBtTx1'
                                                                    ]
                                                                }
                                                                handlecheckbox={
                                                                    this
                                                                        .onChangeCheckboxBiTx1
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                                min={0}
                                                                max={255}
                                                            />
                                                            <SliderRow
                                                                name={'BT TX 2'}
                                                                port={'BtTx2'}
                                                                value={
                                                                    state[
                                                                        'valueBtTx2'
                                                                    ]
                                                                }
                                                                changeState={
                                                                    this.props
                                                                }
                                                                assign={
                                                                    state[
                                                                        'assignBtTx2'
                                                                    ]
                                                                }
                                                                handlecheckbox={
                                                                    this
                                                                        .onChangeCheckboxBiTx2
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                                min={0}
                                                                max={255}
                                                            />
                                                            <SliderRow
                                                                name={'BT TX 3'}
                                                                port={'BtTx3'}
                                                                value={
                                                                    state[
                                                                        'valueBtTx3'
                                                                    ]
                                                                }
                                                                changeState={
                                                                    this.props
                                                                }
                                                                assign={
                                                                    state[
                                                                        'assignBtTx3'
                                                                    ]
                                                                }
                                                                handlecheckbox={
                                                                    this
                                                                        .onChangeCheckboxBiTx3
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                                min={0}
                                                                max={255}
                                                            />
                                                            <SliderRow
                                                                name={'BT TX 4'}
                                                                port={'BtTx4'}
                                                                value={
                                                                    state[
                                                                        'valueBtTx4'
                                                                    ]
                                                                }
                                                                changeState={
                                                                    this.props
                                                                }
                                                                assign={
                                                                    state[
                                                                        'assignBtTx4'
                                                                    ]
                                                                }
                                                                handlecheckbox={
                                                                    this
                                                                        .onChangeCheckboxBiTx4
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                                min={0}
                                                                max={255}
                                                            />
                                                            <SliderRow
                                                                name={'BT TX 5'}
                                                                port={'BtTx5'}
                                                                value={
                                                                    state[
                                                                        'valueBtTx5'
                                                                    ]
                                                                }
                                                                changeState={
                                                                    this.props
                                                                }
                                                                assign={
                                                                    state[
                                                                        'assignBtTx5'
                                                                    ]
                                                                }
                                                                handlecheckbox={
                                                                    this
                                                                        .onChangeCheckboxBiTx5
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                                min={0}
                                                                max={255}
                                                            />
                                                        </>
                                                    )
                                                }
                                            } else if (value == 'bid1') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'BI Data 1'}
                                                            port={'Bid1'}
                                                            value={
                                                                state[
                                                                    'valueBid1'
                                                                ]
                                                            }
                                                            changeState={
                                                                this.props
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignBid1'
                                                                ]
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onChangeCheckboxBidata1
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={255}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'bid2') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'BI Data 2'}
                                                            port={'Bid2'}
                                                            value={
                                                                state[
                                                                    'valueBid2'
                                                                ]
                                                            }
                                                            changeState={
                                                                this.props
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignBid2'
                                                                ]
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onChangeCheckboxBidata2
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={255}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'asgn1') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'Assign 1'}
                                                            port={'Asgn1'}
                                                            value={
                                                                state[
                                                                    'valueAsgn1'
                                                                ]
                                                            }
                                                            changeState={
                                                                this.props
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignAsgn1'
                                                                ]
                                                            }
                                                            handlecheckbox={() =>
                                                                this.onChangeCheckboxAsgn(
                                                                    '1'
                                                                )
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={255}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'asgn2') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'Assign 2'}
                                                            port={'Asgn2'}
                                                            value={
                                                                state[
                                                                    'valueAsgn2'
                                                                ]
                                                            }
                                                            changeState={
                                                                this.props
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignAsgn2'
                                                                ]
                                                            }
                                                            handlecheckbox={() =>
                                                                this.onChangeCheckboxAsgn(
                                                                    '2'
                                                                )
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={255}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'asgn3') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'Assign 3'}
                                                            port={'Asgn3'}
                                                            value={
                                                                state[
                                                                    'valueAsgn3'
                                                                ]
                                                            }
                                                            changeState={
                                                                this.props
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignAsgn3'
                                                                ]
                                                            }
                                                            handlecheckbox={() =>
                                                                this.onChangeCheckboxAsgn(
                                                                    '3'
                                                                )
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={255}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'asgn4') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'Assign 4'}
                                                            port={'Asgn4'}
                                                            value={
                                                                state[
                                                                    'valueAsgn4'
                                                                ]
                                                            }
                                                            changeState={
                                                                this.props
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignAsgn4'
                                                                ]
                                                            }
                                                            handlecheckbox={() =>
                                                                this.onChangeCheckboxAsgn(
                                                                    '4'
                                                                )
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={255}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'asgn5') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'Assign 5'}
                                                            port={'Asgn5'}
                                                            value={
                                                                state[
                                                                    'valueAsgn5'
                                                                ]
                                                            }
                                                            changeState={
                                                                this.props
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignAsgn5'
                                                                ]
                                                            }
                                                            handlecheckbox={() =>
                                                                this.onChangeCheckboxAsgn(
                                                                    '5'
                                                                )
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={255}
                                                        />
                                                    )
                                                }
                                            }
                                        }
                                    )}

                                    {Object.keys(PortConnections).map(
                                        (port) => {
                                            if (port === 'undefined') {
                                                return 0
                                            }

                                            let portName = port
                                            if (!PortConnections[port])
                                                return null

                                            const isArmShield =
                                                sessionStorage.getItem(
                                                    'armShield'
                                                ) == 'true' || false
                                            const isPlayShield =
                                                sessionStorage.getItem(
                                                    'shield'
                                                ) == 'true' || false
                                            const peeCeeATR =
                                                sessionStorage.getItem(
                                                    'peeCeeATR'
                                                ) == 'true' || false

                                            var type =
                                                PortConnections[port].type
                                            var max, min
                                            if (
                                                type == '4_in_1_sensor' ||
                                                type == 'microphone'
                                            ) {
                                                console.log(
                                                    '4_in_1_sensor OR microphone'
                                                )
                                                return 0
                                            }

                                            if (
                                                type !== 'dual_splitter' &&
                                                type !== 'servo_extender' &&
                                                type !== 'pc_motor_driver' &&
                                                type !== 'pcAiTool'
                                            ) {
                                                var compName =
                                                    type.toLowerCase()

                                                if (type != 'OLED') {
                                                    var range =
                                                        PortValuesRangeMapping[
                                                            port
                                                        ][compName](port)
                                                    console.log(
                                                        'RANGE 003',
                                                        range
                                                    )
                                                    min = range.min
                                                    max = range.max
                                                }
                                            }

                                            if (
                                                [
                                                    'servo_motor',
                                                    'servo_motor_270',
                                                    'servo_motor_360',
                                                ].includes(type)
                                            ) {
                                                if (
                                                    port == 'A1' &&
                                                    PortConnections['A1'] &&
                                                    PortConnections['P0']
                                                )
                                                    return 0
                                                if (
                                                    port == 'B1' &&
                                                    PortConnections['B1'] &&
                                                    PortConnections['P1']
                                                )
                                                    return 0
                                                if (
                                                    port == 'C1' &&
                                                    PortConnections['C1'] &&
                                                    PortConnections['P2']
                                                )
                                                    return 0
                                            }
                                            if (
                                                [
                                                    'mini_geared_motor',
                                                    'geared_motor',
                                                    'dc_motor',
                                                    'dynamex_motor',
                                                ].includes(type)
                                            ) {
                                                if (!peeCeeATR) {
                                                    if (
                                                        port == 'M3' &&
                                                        PortConnections[
                                                            'MOTOR1'
                                                        ]
                                                    )
                                                        return
                                                    if (
                                                        /^[ABCDEFM]2|M4$/.test(
                                                            port
                                                        )
                                                    )
                                                        return
                                                }

                                                let port1 = ''
                                                let port2 = ''
                                                let portName = ''
                                                if (peeCeeATR) {
                                                    if (port == 'M1') {
                                                        portName = 'M1'
                                                        port1 = 'M1'
                                                        port2 = 'M2'
                                                    } else if (port == 'M3') {
                                                        portName = 'M2'
                                                        port1 = 'M3'
                                                        port2 = 'M4'
                                                    } else if (port == 'E1') {
                                                        portName = 'M3'
                                                        port1 = 'E1'
                                                        port2 = 'E2'
                                                    } else if (port == 'F1') {
                                                        portName = 'M4'
                                                        port1 = 'F1'
                                                        port2 = 'F2'
                                                    } else {
                                                        return
                                                    }
                                                } else {
                                                    if (port == 'MOTOR1') {
                                                        portName = 'M1'
                                                        port1 = 'M2'
                                                        port2 = 'M3'
                                                    } else if (port == 'M3') {
                                                        port1 = 'M3'
                                                        port2 = 'M4'
                                                    } else {
                                                        port1 = port
                                                        port2 = port[0] + '2'
                                                    }

                                                    portName =
                                                        port[0] +
                                                        port1[1] +
                                                        port2[1]
                                                }
                                                return (
                                                    <SliderRow
                                                        name={
                                                            componentProps[type]
                                                                .name
                                                        }
                                                        port={portName}
                                                        key={port}
                                                        assign={
                                                            state[
                                                                `assign${port1}`
                                                            ]
                                                        }
                                                        onChange={this.onChange}
                                                        min={min}
                                                        max={max}
                                                        sliderType={
                                                            'motorSlider'
                                                        }
                                                        port1={port1}
                                                        port2={port2}
                                                        value1={
                                                            state[
                                                                `value${port1}`
                                                            ]
                                                        }
                                                        value2={
                                                            state[
                                                                `value${port2}`
                                                            ]
                                                        }
                                                    />
                                                )
                                            }

                                            // ----------- for pcAiTool outputs -----------
                                            if (
                                                port == 'A1' &&
                                                type === 'pcAiTool' &&
                                                this.props.assembly
                                                    .assemblyPageAi.aiItems
                                                    .ai_LED
                                            ) {
                                                return (
                                                    <AiMode_output
                                                        name={
                                                            componentProps[type]
                                                                .name
                                                        }
                                                        port={portName}
                                                        key={port}
                                                        value={
                                                            state[
                                                                'value' + port
                                                            ]
                                                        }
                                                        assign={
                                                            state[
                                                                'assign' + port
                                                            ]
                                                        }
                                                        onChange={this.onChange}
                                                    />
                                                )
                                            }

                                            if (
                                                type !== 'pcAiTool' &&
                                                IOComponents[type].output
                                            ) {
                                                // debugger;
                                                if (port === 'D') {
                                                    if (type == 'OLED') {
                                                        return (
                                                            <>
                                                                <TextRow
                                                                    name={
                                                                        componentProps[
                                                                            type
                                                                        ].name
                                                                    }
                                                                    port={port}
                                                                    assign={
                                                                        this
                                                                            .state
                                                                            .isClickOLED1
                                                                    }
                                                                    key={port}
                                                                    handlecheckbox={
                                                                        this
                                                                            .onOLED1Handle
                                                                    }
                                                                    // assign={state["assign" + port]}
                                                                    textValue={
                                                                        this
                                                                            .state
                                                                            .curValOLED1
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onChange
                                                                    }
                                                                    label={
                                                                        'OLED Line 1'
                                                                    }
                                                                    handleTextChange={
                                                                        this
                                                                            .onOLED1HandleText
                                                                    }
                                                                />
                                                                <TextRow
                                                                    name={
                                                                        componentProps[
                                                                            type
                                                                        ].name
                                                                    }
                                                                    port={port}
                                                                    assign={
                                                                        this
                                                                            .state
                                                                            .isClickOLED2
                                                                    }
                                                                    key={port}
                                                                    handlecheckbox={
                                                                        this
                                                                            .onOLED2Handle
                                                                    }
                                                                    // assign={state["assign" + port]}
                                                                    textValue={
                                                                        this
                                                                            .state
                                                                            .curValOLED2
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onChange
                                                                    }
                                                                    label={
                                                                        'OLED Line 2'
                                                                    }
                                                                    handleTextChange={
                                                                        this
                                                                            .onOLED2HandleText
                                                                    }
                                                                />
                                                                <TextRow
                                                                    name={
                                                                        componentProps[
                                                                            type
                                                                        ].name
                                                                    }
                                                                    port={port}
                                                                    assign={
                                                                        this
                                                                            .state
                                                                            .isClickOLED3
                                                                    }
                                                                    key={port}
                                                                    handlecheckbox={
                                                                        this
                                                                            .onOLED3Handle
                                                                    }
                                                                    // assign={state["assign" + port]}
                                                                    textValue={
                                                                        this
                                                                            .state
                                                                            .curValOLED3
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onChange
                                                                    }
                                                                    label={
                                                                        'OLED Line 3'
                                                                    }
                                                                    handleTextChange={
                                                                        this
                                                                            .onOLED3HandleText
                                                                    }
                                                                />
                                                                <SelectionRow
                                                                    name={
                                                                        componentProps[
                                                                            type
                                                                        ].name
                                                                    }
                                                                    port={port}
                                                                    assign={
                                                                        this
                                                                            .state
                                                                            .isClickOLED4
                                                                    }
                                                                    key={port}
                                                                    handlecheckbox={
                                                                        this
                                                                            .onOLED4Handle
                                                                    }
                                                                    // assign={state["assign" + port]}
                                                                    selected={
                                                                        this
                                                                            .state
                                                                            .curValOLED4
                                                                    }
                                                                    textValue={
                                                                        this
                                                                            .state
                                                                            .curValOLED4
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onChange
                                                                    }
                                                                    label={
                                                                        'OLED Line 4'
                                                                    }
                                                                    handleTextChange={
                                                                        this
                                                                            .onOLED4HandleText
                                                                    }
                                                                />
                                                            </>
                                                        )
                                                    }
                                                }
                                                if (port == 'B1') {
                                                    if (type == 'RGB') {
                                                        var totalSliders = []
                                                        for (
                                                            var i = 1;
                                                            i <=
                                                            this.state
                                                                .countRGBComp;
                                                            i++
                                                        ) {
                                                            console.log(
                                                                'loopRGB',
                                                                this.state
                                                                    .countRGBComp
                                                            )
                                                            var slidr = (
                                                                <>
                                                                    <SliderRow
                                                                        name="RGBComp"
                                                                        valR={
                                                                            this
                                                                                .state
                                                                                .curValRGBComp[
                                                                                `RGBComp${i}`
                                                                            ].R
                                                                        }
                                                                        valG={
                                                                            this
                                                                                .state
                                                                                .curValRGBComp[
                                                                                `RGBComp${i}`
                                                                            ].G
                                                                        }
                                                                        valB={
                                                                            this
                                                                                .state
                                                                                .curValRGBComp[
                                                                                `RGBComp${i}`
                                                                            ].B
                                                                        }
                                                                        key="LeftEye"
                                                                        title={`RGB ${i}`}
                                                                        assign={
                                                                            this
                                                                                .state
                                                                                .isClickRGBComp[
                                                                                `isClickRGBComp${i}`
                                                                            ]
                                                                        }
                                                                        handlecheckbox={
                                                                            this
                                                                                .onRGBCompHandle
                                                                        }
                                                                        count={
                                                                            i
                                                                        }
                                                                        min={0}
                                                                        max={
                                                                            100
                                                                        }
                                                                        getRangeVal={
                                                                            this
                                                                                .onRangeValueRGBComp
                                                                        }
                                                                    />
                                                                </>
                                                            )
                                                            totalSliders = [
                                                                ...totalSliders,
                                                                slidr,
                                                            ]
                                                        }
                                                        var styleAdd = {
                                                            backgroundImage: `url(${renderImage(
                                                                'add3x'
                                                            )}`,
                                                            backgroundPosition:
                                                                'center',
                                                            backgroundSize:
                                                                'cover',
                                                            backgroundRepeat:
                                                                'no-repeat',
                                                            height: 40,
                                                            width: 40,
                                                            position:
                                                                'relative',
                                                            margin: 'auto',
                                                            marginLeft: '60%',
                                                        }
                                                        var styleRemove = {
                                                            backgroundImage: `url(${renderImage(
                                                                'remove3x'
                                                            )}`,
                                                            backgroundPosition:
                                                                'center',
                                                            backgroundSize:
                                                                'cover',
                                                            backgroundRepeat:
                                                                'no-repeat',
                                                            height: 40,
                                                            width: 40,
                                                            position:
                                                                'relative',
                                                            margin: 'auto',
                                                            marginLeft: '70%',
                                                        }
                                                        if (
                                                            this.state
                                                                .countRGBComp ==
                                                            10
                                                        ) {
                                                            styleAdd = {
                                                                ...styleAdd,
                                                                backgroundImage: `url(${renderImage(
                                                                    'add3xIA'
                                                                )}`,
                                                            }
                                                        }
                                                        if (
                                                            this.state
                                                                .countRGBComp ==
                                                            1
                                                        ) {
                                                            styleRemove = {
                                                                ...styleRemove,
                                                                backgroundImage: `url(${renderImage(
                                                                    'remove3xIA'
                                                                )}`,
                                                            }
                                                        }
                                                        return (
                                                            <>
                                                                {totalSliders}
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'grid',
                                                                        gridTemplateColumns:
                                                                            ' repeat(2, 75px)',
                                                                        marginLeft:
                                                                            '50%',
                                                                        marginBottom:
                                                                            '5%',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={
                                                                            styleAdd
                                                                        }
                                                                        onClick={
                                                                            this
                                                                                .onRGBCompIncrease
                                                                        }
                                                                    ></div>
                                                                    <div
                                                                        style={
                                                                            styleRemove
                                                                        }
                                                                        onClick={
                                                                            this
                                                                                .onRGBCompDecrease
                                                                        }
                                                                    ></div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                }

                                                if (
                                                    port == 'MOTOR1' &&
                                                    isArmShield
                                                ) {
                                                    return (
                                                        <SliderRow
                                                            name={
                                                                componentProps[
                                                                    type
                                                                ].name
                                                            }
                                                            port={'M1'}
                                                            value={
                                                                state['valueM1']
                                                            }
                                                            key={port}
                                                            assign={
                                                                state[
                                                                    'assignM1'
                                                                ]
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={min}
                                                            max={max}
                                                        />
                                                    )
                                                } else if (
                                                    port !== 'undefined' &&
                                                    port.length !== 1 &&
                                                    type !== 'dual_splitter' &&
                                                    type !== 'servo_extender' &&
                                                    type !==
                                                        'pc_motor_driver' &&
                                                    type !== 'stepper_motor' &&
                                                    type !== 'OLED' &&
                                                    type !== 'RGB' &&
                                                    type !== 'pcAiTool' &&
                                                    type !==
                                                        'serial_servo_motor'
                                                ) {
                                                    return (
                                                        <SliderRow
                                                            name={
                                                                componentProps[
                                                                    type
                                                                ].name
                                                            }
                                                            port={portName}
                                                            value={
                                                                state[
                                                                    'value' +
                                                                        port
                                                                ]
                                                            }
                                                            key={port}
                                                            assign={
                                                                state[
                                                                    'assign' +
                                                                        port
                                                                ]
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={min}
                                                            max={max}
                                                        />
                                                    )
                                                } else if (
                                                    type ===
                                                    'serial_servo_motor'
                                                ) {
                                                    // const servoSlider =
                                                    return (
                                                        <>
                                                            <SliderRow
                                                                name={
                                                                    componentProps[
                                                                        type
                                                                    ].name
                                                                }
                                                                port={'SSTime'}
                                                                value={
                                                                    state[
                                                                        'valueSSTime'
                                                                    ]
                                                                }
                                                                key={'SSTime'}
                                                                assign={
                                                                    state[
                                                                        'assignSSTime'
                                                                    ]
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                                min={0}
                                                                max={5000}
                                                            />
                                                            {Array.from(
                                                                Array(5).keys()
                                                            ).map((index) => (
                                                                <SliderRow
                                                                    name={
                                                                        componentProps[
                                                                            type
                                                                        ].name
                                                                    }
                                                                    port={`SS${
                                                                        index +
                                                                        1
                                                                    }`}
                                                                    value={
                                                                        state[
                                                                            `valueSS${
                                                                                index +
                                                                                1
                                                                            }`
                                                                        ]
                                                                    }
                                                                    key={`SS${
                                                                        index +
                                                                        1
                                                                    }`}
                                                                    assign={
                                                                        state[
                                                                            `assignSS${
                                                                                index +
                                                                                1
                                                                            }`
                                                                        ]
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onChange
                                                                    }
                                                                    min={min}
                                                                    max={max}
                                                                />
                                                            ))}
                                                        </>
                                                    )
                                                } else return null
                                            }
                                        }
                                    )}
                                </tbody>
                            ) : (
                                <tbody>
                                    {/* New CheckBox */}
                                    {Object.keys(internalAccessories).map(
                                        (value, index) => {
                                            if (value == 'isSmileOne') {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    return (
                                                        <SliderRow
                                                            name="smile"
                                                            port="S1"
                                                            value={
                                                                this.state
                                                                    .curValSmileOne
                                                            }
                                                            key="smileOne"
                                                            title="SmileOne"
                                                            assign={
                                                                this.state
                                                                    .isClickSmileOne
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onSmileOneHandle
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                }
                                            } else if (value == 'isSmileTwo') {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    console.log(
                                                        PortValuesRangeMapping[
                                                            value
                                                        ]
                                                    )
                                                    return (
                                                        <SliderRow
                                                            name="smile"
                                                            port="S2"
                                                            value={
                                                                this.state
                                                                    .curValSmileTwo
                                                            }
                                                            key="smileTwo"
                                                            title="SmileTwo"
                                                            assign={
                                                                this.state
                                                                    .isClickSmileTwo
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onSmileTwoHandle
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (
                                                value == 'isSmileThree'
                                            ) {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    console.log(
                                                        PortValuesRangeMapping[
                                                            value
                                                        ]
                                                    )
                                                    return (
                                                        <SliderRow
                                                            name="smile"
                                                            port="S3"
                                                            value={
                                                                this.state
                                                                    .curValSmileThree
                                                            }
                                                            key="smileThree"
                                                            title="SmileThree"
                                                            assign={
                                                                this.state
                                                                    .isClickSmileThree
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onSmileThreeHandle
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (value == 'isSmileFour') {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    console.log(
                                                        PortValuesRangeMapping[
                                                            value
                                                        ]
                                                    )
                                                    return (
                                                        <SliderRow
                                                            name="smile"
                                                            port="S4"
                                                            value={
                                                                this.state
                                                                    .curValSmileFour
                                                            }
                                                            key="smileFour"
                                                            title="SmileFour"
                                                            assign={
                                                                this.state
                                                                    .isClickSmileFour
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onSmileFourHandle
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (value == 'isbuzzer') {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    console.log(
                                                        PortValuesRangeMapping[
                                                            value
                                                        ]
                                                    )
                                                    return (
                                                        <SliderRow
                                                            changeState={
                                                                this.props
                                                            }
                                                            name="Buzzer"
                                                            // port="buzzer"
                                                            valueBuzzerFrequency={
                                                                this.state
                                                                    .curValBuzzerFrequency
                                                            }
                                                            valueBuzzerTone={
                                                                this.state
                                                                    .curValBuzzerTone
                                                            }
                                                            key="Buzzer"
                                                            title="Buzzer"
                                                            assign={
                                                                this.state
                                                                    .isClickBuzzer
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onChangeCheckboxBuzzer
                                                            }
                                                            min={0}
                                                            max={9000}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (
                                                value == 'isTouchZeroOutput'
                                            ) {
                                                console.log(
                                                    'plode assign',
                                                    this.state
                                                        .isClickTouchZeroOutpu
                                                )

                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    return (
                                                        <SliderRow
                                                            name="touchPadOutput"
                                                            port=""
                                                            value={
                                                                this.state
                                                                    .curValTouchZeroOutput
                                                            }
                                                            key="TouchZeroOutput"
                                                            title="Touch 0 Output"
                                                            assign={
                                                                this.state
                                                                    .isClickTouchZeroOutput
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onChangeCheckboxTouchZeroOutput
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (
                                                value == 'isTouchOneOutput'
                                            ) {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    return (
                                                        <SliderRow
                                                            name="touchPadOutput"
                                                            port=""
                                                            value={
                                                                this.state
                                                                    .curValTouchOneOutput
                                                            }
                                                            key="TouchOneOutput"
                                                            title="Touch 1 Output"
                                                            assign={
                                                                this.state
                                                                    .isClickTouchOneOutput
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onChangeCheckboxTouchOneOutput
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (
                                                value == 'isTouchTwoOutput'
                                            ) {
                                                if (
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    return (
                                                        <SliderRow
                                                            name="touchPadOutput"
                                                            port=""
                                                            value={
                                                                this.state
                                                                    .curValTouchTwoOutput
                                                            }
                                                            key="TouchTwoOutput"
                                                            title="Touch 2 Output"
                                                            assign={
                                                                this.state
                                                                    .isClickTouchTwoOutput
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onChangeCheckboxTouchTwoOutput
                                                            }
                                                            min={0}
                                                            max={1}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                    // return <input type="range" />;
                                                }
                                            } else if (value == 'isLeftEye') {
                                                if (
                                                    sessionStorage.getItem(
                                                        'connectedDevice'
                                                    ) === 'Roboki' &&
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    return (
                                                        <SliderRow
                                                            name="RightEye"
                                                            RGBkey={1}
                                                            port={
                                                                this.state
                                                                    .setportName
                                                            }
                                                            value={
                                                                this.state[
                                                                    `curValEye${this.state.portName}`
                                                                ]
                                                                //state[`valueLeftEye${this.state.setportName}`]
                                                            }
                                                            valR={
                                                                this.state[
                                                                    `curValEyeR${1}`
                                                                ]
                                                            }
                                                            valB={
                                                                this.state[
                                                                    `curValEyeB${1}`
                                                                ]
                                                            }
                                                            valG={
                                                                this.state[
                                                                    `curValEyeG${1}`
                                                                ]
                                                            }
                                                            key={
                                                                'RGB' +
                                                                String(1)
                                                            }
                                                            title={
                                                                'RGB ' +
                                                                String(1)
                                                            }
                                                            assign={
                                                                this.state[
                                                                    `isClickEye${1}`
                                                                ]
                                                            }
                                                            handlecheckbox={
                                                                this.onHandleEye
                                                            }
                                                            min={0}
                                                            max={100}
                                                            onSetSliderPort={
                                                                this
                                                                    .onSetSliderPort
                                                            }
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                } else {
                                                    if (
                                                        internalAccessories[
                                                            value
                                                        ] == true
                                                    ) {
                                                        console.log(
                                                            'PROPS DATATA',
                                                            this.state
                                                        )
                                                        console.log(
                                                            'PROPS DATATA',
                                                            state[
                                                                `valueLeftEye${this.state.setportName}`
                                                            ]
                                                        )
                                                        return (
                                                            <>
                                                                <SliderRow
                                                                    name="LeftEye"
                                                                    port={
                                                                        this
                                                                            .state
                                                                            .setportName
                                                                    }
                                                                    value={
                                                                        //state[`valueLeftEye${this.state.setportName}`]
                                                                        this
                                                                            .state[
                                                                            `curValLeftEye${this.state.portName}`
                                                                        ]
                                                                        //state[`valueLeftEye${this.state.setportName}`]
                                                                    }
                                                                    valR={
                                                                        this
                                                                            .state
                                                                            .curValLeftEyeR
                                                                    }
                                                                    valB={
                                                                        this
                                                                            .state
                                                                            .curValLeftEyeB
                                                                    }
                                                                    valG={
                                                                        this
                                                                            .state
                                                                            .curValLeftEyeG
                                                                    }
                                                                    key="LeftEye"
                                                                    title="LeftEye"
                                                                    assign={
                                                                        this
                                                                            .state
                                                                            .isClickLeftEye
                                                                    }
                                                                    handlecheckbox={
                                                                        this
                                                                            .onLeftEeyHandle
                                                                    }
                                                                    min={0}
                                                                    max={100}
                                                                    onSetSliderPort={
                                                                        this
                                                                            .onSetSliderPort
                                                                    }
                                                                    getRangeVal={
                                                                        this
                                                                            .onRangeValue_Buzzer_smile_Eyes
                                                                    }
                                                                />
                                                            </>
                                                        )
                                                    }
                                                }
                                            } else if (value == 'isRightEye') {
                                                if (
                                                    sessionStorage.getItem(
                                                        'connectedDevice'
                                                    ) === 'Roboki' &&
                                                    internalAccessories[
                                                        value
                                                    ] == true
                                                ) {
                                                    return (
                                                        <SliderRow
                                                            name="RightEye"
                                                            RGBkey={2}
                                                            port={
                                                                this.state
                                                                    .setportName
                                                            }
                                                            value={
                                                                this.state[
                                                                    `curValEye${this.state.portName}`
                                                                ]
                                                                //state[`valueLeftEye${this.state.setportName}`]
                                                            }
                                                            valR={
                                                                this.state[
                                                                    `curValEyeR${2}`
                                                                ]
                                                            }
                                                            valB={
                                                                this.state[
                                                                    `curValEyeB${2}`
                                                                ]
                                                            }
                                                            valG={
                                                                this.state[
                                                                    `curValEyeG${2}`
                                                                ]
                                                            }
                                                            key={
                                                                'RGB' +
                                                                String(2)
                                                            }
                                                            title={
                                                                'RGB ' +
                                                                String(2)
                                                            }
                                                            assign={
                                                                this.state[
                                                                    `isClickEye${2}`
                                                                ]
                                                            }
                                                            handlecheckbox={
                                                                this.onHandleEye
                                                            }
                                                            min={0}
                                                            max={100}
                                                            onSetSliderPort={
                                                                this
                                                                    .onSetSliderPort
                                                            }
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValue_Buzzer_smile_Eyes
                                                            }
                                                        />
                                                    )
                                                } else {
                                                    if (
                                                        internalAccessories[
                                                            value
                                                        ] == true
                                                    ) {
                                                        console.log(
                                                            PortValuesRangeMapping[
                                                                value
                                                            ]
                                                        )
                                                        return (
                                                            <>
                                                                <SliderRow
                                                                    name="RightEye"
                                                                    port={
                                                                        this
                                                                            .state
                                                                            .setportName
                                                                    }
                                                                    value={
                                                                        this
                                                                            .state[
                                                                            `curValRightEye${this.state.portName}`
                                                                        ]
                                                                        //state[`valueLeftEye${this.state.setportName}`]
                                                                    }
                                                                    valR={
                                                                        this
                                                                            .state
                                                                            .curValRightEyeR
                                                                    }
                                                                    valB={
                                                                        this
                                                                            .state
                                                                            .curValRightEyeB
                                                                    }
                                                                    valG={
                                                                        this
                                                                            .state
                                                                            .curValRightEyeG
                                                                    }
                                                                    key="RightEye"
                                                                    title="RightEye"
                                                                    assign={
                                                                        this
                                                                            .state
                                                                            .isClickRightEye
                                                                    }
                                                                    handlecheckbox={
                                                                        this
                                                                            .onRightEeyHandle
                                                                    }
                                                                    min={0}
                                                                    max={100}
                                                                    onSetSliderPort={
                                                                        this
                                                                            .onSetSliderPort
                                                                    }
                                                                    getRangeVal={
                                                                        this
                                                                            .onRangeValue_Buzzer_smile_Eyes
                                                                    }
                                                                />
                                                            </>
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    )}
                                    {Object.keys(startTypes).map(
                                        (value, index) => {
                                            if (value == 'bic2') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={
                                                                'BI COUNTER 2'
                                                            }
                                                            port={'Bic2'}
                                                            value={
                                                                state[
                                                                    'valueBic2'
                                                                ]
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignBic2'
                                                                ]
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={-1}
                                                            max={1}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'bic1') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={
                                                                'BI COUNTER 1'
                                                            }
                                                            port={'Bic1'}
                                                            value={
                                                                state[
                                                                    'valueBic1'
                                                                ]
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignBic1'
                                                                ]
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={-1}
                                                            max={1}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'bif1') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'BI FLAG 1'}
                                                            port={'Bif1'}
                                                            value={
                                                                state[
                                                                    'valueBif1'
                                                                ]
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignBif1'
                                                                ]
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={1}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'bif2') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'BI FLAG 2'}
                                                            port={'Bif2'}
                                                            value={
                                                                state[
                                                                    'valueBif2'
                                                                ]
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignBif2'
                                                                ]
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={1}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'usbtx') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'USB TX'}
                                                            port={'Usbtx'}
                                                            value={
                                                                state[
                                                                    'valueUsbtx'
                                                                ]
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignUsbtx'
                                                                ]
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={255}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'btTx') {
                                                if (startTypes[value] == true) {
                                                    console.log(
                                                        PortValuesRangeMapping[
                                                            value
                                                        ]
                                                    )
                                                    return (
                                                        <>
                                                            <SliderRow
                                                                name={'BT TX 1'}
                                                                port={'BtTx1'}
                                                                value={
                                                                    state[
                                                                        'valueBtTx1'
                                                                    ]
                                                                }
                                                                changeState={
                                                                    this.props
                                                                }
                                                                assign={
                                                                    state[
                                                                        'assignBtTx1'
                                                                    ]
                                                                }
                                                                handlecheckbox={
                                                                    this
                                                                        .onChangeCheckboxBiTx1
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                                min={0}
                                                                max={255}
                                                            />
                                                            <SliderRow
                                                                name={'BT TX 2'}
                                                                port={'BtTx2'}
                                                                value={
                                                                    state[
                                                                        'valueBtTx2'
                                                                    ]
                                                                }
                                                                changeState={
                                                                    this.props
                                                                }
                                                                assign={
                                                                    state[
                                                                        'assignBtTx2'
                                                                    ]
                                                                }
                                                                handlecheckbox={
                                                                    this
                                                                        .onChangeCheckboxBiTx2
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                                min={0}
                                                                max={255}
                                                            />
                                                            <SliderRow
                                                                name={'BT TX 3'}
                                                                port={'BtTx3'}
                                                                value={
                                                                    state[
                                                                        'valueBtTx3'
                                                                    ]
                                                                }
                                                                changeState={
                                                                    this.props
                                                                }
                                                                assign={
                                                                    state[
                                                                        'assignBtTx3'
                                                                    ]
                                                                }
                                                                handlecheckbox={
                                                                    this
                                                                        .onChangeCheckboxBiTx3
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                                min={0}
                                                                max={255}
                                                            />
                                                            <SliderRow
                                                                name={'BT TX 4'}
                                                                port={'BtTx4'}
                                                                value={
                                                                    state[
                                                                        'valueBtTx4'
                                                                    ]
                                                                }
                                                                changeState={
                                                                    this.props
                                                                }
                                                                assign={
                                                                    state[
                                                                        'assignBtTx4'
                                                                    ]
                                                                }
                                                                handlecheckbox={
                                                                    this
                                                                        .onChangeCheckboxBiTx4
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                                min={0}
                                                                max={255}
                                                            />
                                                            <SliderRow
                                                                name={'BT TX 5'}
                                                                port={'BtTx5'}
                                                                value={
                                                                    state[
                                                                        'valueBtTx5'
                                                                    ]
                                                                }
                                                                changeState={
                                                                    this.props
                                                                }
                                                                assign={
                                                                    state[
                                                                        'assignBtTx5'
                                                                    ]
                                                                }
                                                                handlecheckbox={
                                                                    this
                                                                        .onChangeCheckboxBiTx5
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                                min={0}
                                                                max={255}
                                                            />
                                                        </>
                                                    )
                                                }
                                            } else if (value == 'bid1') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'BI Data 1'}
                                                            port={'Bid1'}
                                                            value={
                                                                state[
                                                                    'valueBid1'
                                                                ]
                                                            }
                                                            changeState={
                                                                this.props
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignBid1'
                                                                ]
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onChangeCheckboxBidata1
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={255}
                                                        />
                                                    )
                                                }
                                            } else if (value == 'bid2') {
                                                if (startTypes[value] == true) {
                                                    return (
                                                        <SliderRow
                                                            name={'BI Data 2'}
                                                            port={'Bid2'}
                                                            value={
                                                                state[
                                                                    'valueBid2'
                                                                ]
                                                            }
                                                            changeState={
                                                                this.props
                                                            }
                                                            assign={
                                                                state[
                                                                    'assignBid2'
                                                                ]
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onChangeCheckboxBidata2
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={0}
                                                            max={255}
                                                        />
                                                    )
                                                }
                                            }
                                        }
                                    )}
                                    {Object.keys(PortConnections).map(
                                        (port) => {
                                            if (!PortConnections[port])
                                                return null

                                            let portName = port

                                            const isArmShield =
                                                sessionStorage.getItem(
                                                    'armShield'
                                                ) == 'true' || false
                                            const peeCeeATR =
                                                sessionStorage.getItem(
                                                    'peeCeeATR'
                                                ) == 'true' || false

                                            var type =
                                                PortConnections[port].type
                                            var max, min
                                            if (
                                                type == '4_in_1_sensor' ||
                                                type == 'microphone'
                                            ) {
                                                console.log(
                                                    '4_in_1_sensor OR microphone'
                                                )
                                                return 0
                                            }

                                            if (
                                                port !== 'undefined' &&
                                                type !== 'dual_splitter' &&
                                                type !== 'servo_extender' &&
                                                type !== 'pc_motor_driver'
                                            ) {
                                                var compName =
                                                    type.toLowerCase()

                                                if (type != 'OLED') {
                                                    var range =
                                                        PortValuesRangeMapping[
                                                            port
                                                        ][compName](port)
                                                    console.log(
                                                        'RANGE 003',
                                                        range
                                                    )
                                                    min = range.min
                                                    max = range.max
                                                }
                                            }

                                            if (
                                                [
                                                    'servo_motor',
                                                    'servo_motor_270',
                                                    'servo_motor_360',
                                                ].includes(type)
                                            ) {
                                                if (
                                                    port == 'A1' &&
                                                    PortConnections['A1'] &&
                                                    PortConnections['P0']
                                                )
                                                    return 0
                                                if (
                                                    port == 'B1' &&
                                                    PortConnections['B1'] &&
                                                    PortConnections['P1']
                                                )
                                                    return 0
                                                if (
                                                    port == 'C1' &&
                                                    PortConnections['C1'] &&
                                                    PortConnections['P2']
                                                )
                                                    return 0
                                                if (
                                                    sessionStorage.getItem(
                                                        'connectedDevice'
                                                    ) === 'Roboki'
                                                ) {
                                                    if (
                                                        port == 'servo_motor1'
                                                    ) {
                                                        portName = 'S1'
                                                    }
                                                    if (
                                                        port == 'servo_motor2'
                                                    ) {
                                                        portName = 'S2'
                                                    }
                                                }

                                                if (peeCeeATR) {
                                                    if (
                                                        !['A1', 'C1'].includes(
                                                            port
                                                        )
                                                    ) {
                                                        return 0
                                                    } else {
                                                        if (port == 'A1')
                                                            portName = 'S1'
                                                        if (port == 'C1')
                                                            portName = 'S2'
                                                    }
                                                }
                                            }
                                            if (
                                                [
                                                    'dc_motor',
                                                    'geared_motor',
                                                    'mini_geared_motor',
                                                    'dynamex_motor',
                                                ].includes(type)
                                            ) {
                                                if (!peeCeeATR) {
                                                    if (
                                                        port == 'M3' &&
                                                        PortConnections[
                                                            'MOTOR1'
                                                        ]
                                                    )
                                                        return
                                                    if (
                                                        /^[ABCDFM]2|M4$/.test(
                                                            port
                                                        )
                                                    )
                                                        return
                                                }

                                                let port1 = ''
                                                let port2 = ''
                                                let portName = ''
                                                if (peeCeeATR) {
                                                    if (port == 'M1') {
                                                        portName = 'M1'
                                                        port1 = 'M1'
                                                        port2 = 'M2'
                                                    } else if (port == 'M3') {
                                                        portName = 'M2'
                                                        port1 = 'M3'
                                                        port2 = 'M4'
                                                    } else if (port == 'A2') {
                                                        portName = 'M3'
                                                        port1 = 'A2'
                                                        port2 = 'F2'
                                                    } else if (port == 'C2') {
                                                        portName = 'M4'
                                                        port1 = 'C2'
                                                        port2 = 'F1'
                                                    } else {
                                                        return
                                                    }
                                                } else if (
                                                    sessionStorage.getItem(
                                                        'connectedDevice'
                                                    ) === 'Roboki'
                                                ) {
                                                    if (port == 'M1') {
                                                        portName = 'M1'
                                                        port1 = 'M1'
                                                        port2 = 'M2'
                                                    } else if (port == 'M3') {
                                                        portName = 'M2'
                                                        port1 = 'M3'
                                                        port2 = 'M4'
                                                    } else if (port == 'A1') {
                                                        portName = 'A'
                                                        port1 = 'A1'
                                                        port2 = 'A2'
                                                    } else if (port == 'B1') {
                                                        portName = 'B'
                                                        port1 = 'B1'
                                                        port2 = 'B2'
                                                    }
                                                } else {
                                                    if (port == 'MOTOR1') {
                                                        portName = 'M1'
                                                        port1 = 'M2'
                                                        port2 = 'M3'
                                                    } else if (port == 'M3') {
                                                        port1 = 'M3'
                                                        port2 = 'M4'
                                                    } else {
                                                        port1 = port
                                                        port2 = port[0] + '2'
                                                    }

                                                    portName =
                                                        port[0] +
                                                        port1[1] +
                                                        port2[1]
                                                }
                                                return (
                                                    <SliderRow
                                                        name={
                                                            componentProps[type]
                                                                .name
                                                        }
                                                        port={portName}
                                                        key={port}
                                                        assign={
                                                            state[
                                                                `assign${port1}`
                                                            ]
                                                        }
                                                        onChange={this.onChange}
                                                        min={min}
                                                        max={max}
                                                        sliderType={
                                                            'motorSlider'
                                                        }
                                                        port1={port1}
                                                        port2={port2}
                                                        value1={
                                                            state[
                                                                `value${port1}`
                                                            ]
                                                        }
                                                        value2={
                                                            state[
                                                                `value${port2}`
                                                            ]
                                                        }
                                                    />
                                                )
                                            }

                                            if (IOComponents[type].output) {
                                                // debugger;

                                                if (port === 'D') {
                                                    if (type == 'OLED') {
                                                        return (
                                                            <>
                                                                <TextRow
                                                                    name={
                                                                        componentProps[
                                                                            type
                                                                        ].name
                                                                    }
                                                                    port={port}
                                                                    assign={
                                                                        this
                                                                            .state
                                                                            .isClickOLED1
                                                                    }
                                                                    key={port}
                                                                    handlecheckbox={
                                                                        this
                                                                            .onOLED1Handle
                                                                    }
                                                                    // assign={state["assign" + port]}
                                                                    textValue={
                                                                        this
                                                                            .state
                                                                            .curValOLED1
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onChange
                                                                    }
                                                                    label={
                                                                        'OLED Line 1'
                                                                    }
                                                                    handleTextChange={
                                                                        this
                                                                            .onOLED1HandleText
                                                                    }
                                                                />
                                                                <TextRow
                                                                    name={
                                                                        componentProps[
                                                                            type
                                                                        ].name
                                                                    }
                                                                    port={port}
                                                                    assign={
                                                                        this
                                                                            .state
                                                                            .isClickOLED2
                                                                    }
                                                                    key={port}
                                                                    handlecheckbox={
                                                                        this
                                                                            .onOLED2Handle
                                                                    }
                                                                    // assign={state["assign" + port]}
                                                                    textValue={
                                                                        this
                                                                            .state
                                                                            .curValOLED2
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onChange
                                                                    }
                                                                    label={
                                                                        'OLED Line 2'
                                                                    }
                                                                    handleTextChange={
                                                                        this
                                                                            .onOLED2HandleText
                                                                    }
                                                                />
                                                                <TextRow
                                                                    name={
                                                                        componentProps[
                                                                            type
                                                                        ].name
                                                                    }
                                                                    port={port}
                                                                    assign={
                                                                        this
                                                                            .state
                                                                            .isClickOLED3
                                                                    }
                                                                    key={port}
                                                                    handlecheckbox={
                                                                        this
                                                                            .onOLED3Handle
                                                                    }
                                                                    // assign={state["assign" + port]}
                                                                    textValue={
                                                                        this
                                                                            .state
                                                                            .curValOLED3
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onChange
                                                                    }
                                                                    label={
                                                                        'OLED Line 3'
                                                                    }
                                                                    handleTextChange={
                                                                        this
                                                                            .onOLED3HandleText
                                                                    }
                                                                />
                                                                <SelectionRow
                                                                    name={
                                                                        componentProps[
                                                                            type
                                                                        ].name
                                                                    }
                                                                    port={port}
                                                                    assign={
                                                                        this
                                                                            .state
                                                                            .isClickOLED4
                                                                    }
                                                                    key={port}
                                                                    handlecheckbox={
                                                                        this
                                                                            .onOLED4Handle
                                                                    }
                                                                    // assign={state["assign" + port]}
                                                                    selected={
                                                                        this
                                                                            .state
                                                                            .curValOLED4
                                                                    }
                                                                    textValue={
                                                                        this
                                                                            .state
                                                                            .curValOLED4
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onChange
                                                                    }
                                                                    label={
                                                                        'OLED Line 4'
                                                                    }
                                                                    handleTextChange={
                                                                        this
                                                                            .onOLED4HandleText
                                                                    }
                                                                />
                                                            </>
                                                        )
                                                    }
                                                }
                                                if (
                                                    (sessionStorage.getItem(
                                                        'connectedDevice'
                                                    ) === 'Roboki' &&
                                                        ['A1'].includes(
                                                            port
                                                        )) ||
                                                    port == 'B1'
                                                ) {
                                                    if (type == 'RGB') {
                                                        var totalSliders = []
                                                        for (
                                                            var i = 1;
                                                            i <=
                                                            this.state
                                                                .countRGBComp;
                                                            i++
                                                        ) {
                                                            console.log(
                                                                'loopRGB',
                                                                this.state
                                                                    .countRGBComp
                                                            )
                                                            var slidr = (
                                                                <>
                                                                    <SliderRow
                                                                        name="RGBComp"
                                                                        valR={
                                                                            this
                                                                                .state
                                                                                .curValRGBComp[
                                                                                `RGBComp${i}`
                                                                            ].R
                                                                        }
                                                                        valG={
                                                                            this
                                                                                .state
                                                                                .curValRGBComp[
                                                                                `RGBComp${i}`
                                                                            ].G
                                                                        }
                                                                        valB={
                                                                            this
                                                                                .state
                                                                                .curValRGBComp[
                                                                                `RGBComp${i}`
                                                                            ].B
                                                                        }
                                                                        key="LeftEye"
                                                                        title={`RGB ${i}`}
                                                                        assign={
                                                                            this
                                                                                .state
                                                                                .isClickRGBComp[
                                                                                `isClickRGBComp${i}`
                                                                            ]
                                                                        }
                                                                        handlecheckbox={
                                                                            this
                                                                                .onRGBCompHandle
                                                                        }
                                                                        count={
                                                                            i
                                                                        }
                                                                        min={0}
                                                                        max={
                                                                            100
                                                                        }
                                                                        getRangeVal={
                                                                            this
                                                                                .onRangeValueRGBComp
                                                                        }
                                                                    />
                                                                </>
                                                            )
                                                            totalSliders = [
                                                                ...totalSliders,
                                                                slidr,
                                                            ]
                                                        }
                                                        var styleAdd = {
                                                            backgroundImage: `url(${renderImage(
                                                                'add3x'
                                                            )}`,
                                                            backgroundPosition:
                                                                'center',
                                                            backgroundSize:
                                                                'cover',
                                                            backgroundRepeat:
                                                                'no-repeat',
                                                            height: 40,
                                                            width: 40,
                                                            position:
                                                                'relative',
                                                            margin: 'auto',
                                                            marginLeft: '60%',
                                                        }
                                                        var styleRemove = {
                                                            backgroundImage: `url(${renderImage(
                                                                'remove3x'
                                                            )}`,
                                                            backgroundPosition:
                                                                'center',
                                                            backgroundSize:
                                                                'cover',
                                                            backgroundRepeat:
                                                                'no-repeat',
                                                            height: 40,
                                                            width: 40,
                                                            position:
                                                                'relative',
                                                            margin: 'auto',
                                                            marginLeft: '70%',
                                                        }
                                                        if (
                                                            this.state
                                                                .countRGBComp ==
                                                            10
                                                        ) {
                                                            styleAdd = {
                                                                ...styleAdd,
                                                                backgroundImage: `url(${renderImage(
                                                                    'add3xIA'
                                                                )}`,
                                                            }
                                                        }
                                                        if (
                                                            this.state
                                                                .countRGBComp ==
                                                            1
                                                        ) {
                                                            styleRemove = {
                                                                ...styleRemove,
                                                                backgroundImage: `url(${renderImage(
                                                                    'remove3xIA'
                                                                )}`,
                                                            }
                                                        }
                                                        return (
                                                            <>
                                                                {totalSliders}
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'grid',
                                                                        gridTemplateColumns:
                                                                            ' repeat(2, 75px)',
                                                                        marginLeft:
                                                                            '50%',
                                                                        marginBottom:
                                                                            '5%',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={
                                                                            styleAdd
                                                                        }
                                                                        onClick={
                                                                            this
                                                                                .onRGBCompIncrease
                                                                        }
                                                                    ></div>
                                                                    <div
                                                                        style={
                                                                            styleRemove
                                                                        }
                                                                        onClick={
                                                                            this
                                                                                .onRGBCompDecrease
                                                                        }
                                                                    ></div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                }

                                                if (
                                                    port == 'MOTOR1' &&
                                                    isArmShield
                                                ) {
                                                    return (
                                                        <SliderRow
                                                            name={
                                                                componentProps[
                                                                    type
                                                                ].name
                                                            }
                                                            port={'M1'}
                                                            value={
                                                                state['valueM1']
                                                            }
                                                            key={port}
                                                            assign={
                                                                state[
                                                                    'assignM1'
                                                                ]
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={min}
                                                            max={max}
                                                        />
                                                    )
                                                } else if (
                                                    port !== 'undefined' &&
                                                    port.length !== 1 &&
                                                    type !== 'dual_splitter' &&
                                                    type !== 'servo_extender' &&
                                                    type !==
                                                        'pc_motor_driver' &&
                                                    type !== 'stepper_motor' &&
                                                    type !== 'OLED' &&
                                                    type !== 'RGB' &&
                                                    type !==
                                                        'serial_servo_motor'
                                                ) {
                                                    return (
                                                        <SliderRow
                                                            name={
                                                                componentProps[
                                                                    type
                                                                ].name
                                                            }
                                                            port={portName}
                                                            value={
                                                                state[
                                                                    'value' +
                                                                        port
                                                                ]
                                                            }
                                                            key={port}
                                                            assign={
                                                                state[
                                                                    'assign' +
                                                                        port
                                                                ]
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                            min={min}
                                                            max={max}
                                                        />
                                                    )
                                                } else if (
                                                    type ===
                                                    'serial_servo_motor'
                                                ) {
                                                    // const servoSlider =
                                                    return (
                                                        <>
                                                            <SliderRow
                                                                name={
                                                                    componentProps[
                                                                        type
                                                                    ].name
                                                                }
                                                                port={'SSTime'}
                                                                value={
                                                                    state[
                                                                        'valueSSTime'
                                                                    ]
                                                                }
                                                                key={'SSTime'}
                                                                assign={
                                                                    state[
                                                                        'assignSSTime'
                                                                    ]
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                                min={0}
                                                                max={5000}
                                                            />
                                                            {Array.from(
                                                                Array(5).keys()
                                                            ).map((index) => (
                                                                <SliderRow
                                                                    name={
                                                                        componentProps[
                                                                            type
                                                                        ].name
                                                                    }
                                                                    port={`SS${
                                                                        index +
                                                                        1
                                                                    }`}
                                                                    value={
                                                                        state[
                                                                            `valueSS${
                                                                                index +
                                                                                1
                                                                            }`
                                                                        ]
                                                                    }
                                                                    key={`SS${
                                                                        index +
                                                                        1
                                                                    }`}
                                                                    assign={
                                                                        state[
                                                                            `assignSS${
                                                                                index +
                                                                                1
                                                                            }`
                                                                        ]
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onChange
                                                                    }
                                                                    min={min}
                                                                    max={max}
                                                                />
                                                            ))}
                                                        </>
                                                    )
                                                } else return null
                                            }
                                        }
                                    )}
                                </tbody>
                            )}
                        </>
                    ) : (
                        <></>
                    )}

                    {/* {connectedDevice === 'Klaw2' && (
                        <tbody>
                            {Object.keys(startTypes).map((value, index) => {
                                if (value == 'bic2') {
                                    if (startTypes[value] == true) {
                                        return (
                                            <SliderRow
                                                name={'BI COUNTER 2'}
                                                port={'Bic2'}
                                                value={state['valueBic2']}
                                                assign={state['assignBic2']}
                                                onChange={this.onChange}
                                                min={-1}
                                                max={1}
                                            />
                                        )
                                    }
                                } else if (value == 'bic1') {
                                    if (startTypes[value] == true) {
                                        return (
                                            <SliderRow
                                                name={'BI COUNTER 1'}
                                                port={'Bic1'}
                                                value={state['valueBic1']}
                                                assign={state['assignBic1']}
                                                onChange={this.onChange}
                                                min={-1}
                                                max={1}
                                            />
                                        )
                                    }
                                } else if (value == 'bif1') {
                                    if (startTypes[value] == true) {
                                        return (
                                            <SliderRow
                                                name={'BI FLAG 1'}
                                                port={'Bif1'}
                                                value={state['valueBif1']}
                                                assign={state['assignBif1']}
                                                onChange={this.onChange}
                                                min={0}
                                                max={1}
                                            />
                                        )
                                    }
                                } else if (value == 'bif2') {
                                    if (startTypes[value] == true) {
                                        return (
                                            <SliderRow
                                                name={'BI FLAG 2'}
                                                port={'Bif2'}
                                                value={state['valueBif2']}
                                                assign={state['assignBif2']}
                                                onChange={this.onChange}
                                                min={0}
                                                max={1}
                                            />
                                        )
                                    }
                                } else if (value == 'usbtx') {
                                    if (startTypes[value] == true) {
                                        return (
                                            <SliderRow
                                                name={'USB TX'}
                                                port={'Usbtx'}
                                                value={state['valueUsbtx']}
                                                assign={state['assignUsbtx']}
                                                onChange={this.onChange}
                                                min={0}
                                                max={255}
                                            />
                                        )
                                    }
                                } else if (value == 'btTx') {
                                    if (startTypes[value] == true) {
                                        console.log(
                                            PortValuesRangeMapping[value]
                                        )
                                        return (
                                            <>
                                                <SliderRow
                                                    name={'BT TX 1'}
                                                    port={'BtTx1'}
                                                    value={state['valueBtTx1']}
                                                    assign={
                                                        state['assignBtTx1']
                                                    }
                                                    onChange={this.onChange}
                                                    min={0}
                                                    max={255}
                                                />
                                                <SliderRow
                                                    name={'BT TX 2'}
                                                    port={'BtTx2'}
                                                    value={state['valueBtTx2']}
                                                    assign={
                                                        state['assignBtTx2']
                                                    }
                                                    onChange={this.onChange}
                                                    min={0}
                                                    max={255}
                                                />
                                                <SliderRow
                                                    name={'BT TX 3'}
                                                    port={'BtTx3'}
                                                    value={state['valueBtTx3']}
                                                    assign={
                                                        state['assignBtTx3']
                                                    }
                                                    onChange={this.onChange}
                                                    min={0}
                                                    max={255}
                                                />
                                                <SliderRow
                                                    name={'BT TX 4'}
                                                    port={'BtTx4'}
                                                    value={state['valueBtTx4']}
                                                    assign={
                                                        state['assignBtTx4']
                                                    }
                                                    onChange={this.onChange}
                                                    min={0}
                                                    max={255}
                                                />
                                                <SliderRow
                                                    name={'BT TX 5'}
                                                    port={'BtTx5'}
                                                    value={state['valueBtTx5']}
                                                    assign={
                                                        state['assignBtTx5']
                                                    }
                                                    onChange={this.onChange}
                                                    min={0}
                                                    max={255}
                                                />
                                            </>
                                        )
                                    }
                                } else if (value == 'bid1') {
                                    if (startTypes[value] == true) {
                                        return (
                                            <SliderRow
                                                name={'BI Data 1'}
                                                port={'Bid1'}
                                                value={state['valueBid1']}
                                                changeState={this.props}
                                                assign={state['assignBid1']}
                                                handlecheckbox={
                                                    this.onChangeCheckboxBidata1
                                                }
                                                onChange={this.onChange}
                                                min={0}
                                                max={255}
                                            />
                                        )
                                    }
                                } else if (value == 'bid2') {
                                    if (startTypes[value] == true) {
                                        return (
                                            <SliderRow
                                                name={'BI Data 2'}
                                                port={'Bid2'}
                                                value={state['valueBid2']}
                                                changeState={this.props}
                                                assign={state['assignBid2']}
                                                handlecheckbox={
                                                    this.onChangeCheckboxBidata2
                                                }
                                                onChange={this.onChange}
                                                min={0}
                                                max={255}
                                            />
                                        )
                                    }
                                }
                            })}
                            {Object.keys(PortConnections).map((port) => {
                                if (!PortConnections[port]) return null
                                var type = PortConnections[port].type
                                var max, min
                                var compName = type.toLowerCase()
                                console.log(PortConnections)
                                var range =
                                    PortValuesRangeMapping[port][compName](port)
                                min = range.min
                                max = range.max
                                if (
                                    ['4_in_1_sensor', 'microphone'].includes(
                                        type
                                    )
                                ) {
                                    return 0
                                }

                                if (
                                    port !== 'undefined' &&
                                    ![
                                        'dual_splitter',
                                        'servo_extender',
                                        'pc_motor_driver',
                                        'serial_servo_motor',
                                        'PWM',
                                    ].includes(type)
                                ) {
                                    if (type != 'OLED') {
                                        var range =
                                            PortValuesRangeMapping[port][
                                                compName
                                            ](port)
                                        min = range.min
                                        max = range.max
                                    }
                                }

                                if (
                                    ['servo_motor', 'servo_motor_360'].includes(
                                        type
                                    )
                                ) {
                                    if (
                                        port == 'A1' &&
                                        PortConnections['A1'] &&
                                        PortConnections['P0']
                                    )
                                        return 0
                                    if (
                                        port == 'B1' &&
                                        PortConnections['B1'] &&
                                        PortConnections['P1']
                                    )
                                        return 0
                                    if (
                                        port == 'C1' &&
                                        PortConnections['C1'] &&
                                        PortConnections['P2']
                                    )
                                        return 0
                                }
                                if (
                                    [
                                        'dc_motor',
                                        'geared_motor',
                                        'mini_geared_motor',
                                    ].includes(type)
                                ) {
                                    if (
                                        port == 'M3' &&
                                        PortConnections['MOTOR1']
                                    )
                                        return
                                    if (/^[ABCDFM]2|M4$/.test(port)) return

                                    let port1 = ''
                                    let port2 = ''
                                    let portName = ''

                                    if (port == 'MOTOR1') {
                                        portName = 'M1'
                                        port1 = 'M2'
                                        port2 = 'M3'
                                    } else if (port == 'M3') {
                                        port1 = 'M3'
                                        port2 = 'M4'
                                    } else {
                                        port1 = port
                                        port2 = port[0] + '2'
                                    }

                                    portName = port[0] + port1[1] + port2[1]

                                    return (
                                        <SliderRow
                                            name={componentProps[type].name}
                                            port={portName}
                                            key={port}
                                            assign={state[`assign${port1}`]}
                                            onChange={this.onChange}
                                            min={min}
                                            max={max}
                                            sliderType={'motorSlider'}
                                            port1={port1}
                                            port2={port2}
                                            value1={state[`value${port1}`]}
                                            value2={state[`value${port2}`]}
                                        />
                                    )
                                }

                                if (IOComponents[type].output) {
                                    // debugger;

                                    if (port === 'D') {
                                        if (type == 'OLED') {
                                            return (
                                                <>
                                                    <TextRow
                                                        name={
                                                            componentProps[type]
                                                                .name
                                                        }
                                                        port={port}
                                                        assign={
                                                            this.state
                                                                .isClickOLED1
                                                        }
                                                        key={port}
                                                        handlecheckbox={
                                                            this.onOLED1Handle
                                                        }
                                                        // assign={state["assign" + port]}
                                                        textValue={
                                                            this.state
                                                                .curValOLED1
                                                        }
                                                        onChange={this.onChange}
                                                        label={'OLED Line 1'}
                                                        handleTextChange={
                                                            this
                                                                .onOLED1HandleText
                                                        }
                                                    />
                                                    <TextRow
                                                        name={
                                                            componentProps[type]
                                                                .name
                                                        }
                                                        port={port}
                                                        assign={
                                                            this.state
                                                                .isClickOLED2
                                                        }
                                                        key={port}
                                                        handlecheckbox={
                                                            this.onOLED2Handle
                                                        }
                                                        // assign={state["assign" + port]}
                                                        textValue={
                                                            this.state
                                                                .curValOLED2
                                                        }
                                                        onChange={this.onChange}
                                                        label={'OLED Line 2'}
                                                        handleTextChange={
                                                            this
                                                                .onOLED2HandleText
                                                        }
                                                    />
                                                    <TextRow
                                                        name={
                                                            componentProps[type]
                                                                .name
                                                        }
                                                        port={port}
                                                        assign={
                                                            this.state
                                                                .isClickOLED3
                                                        }
                                                        key={port}
                                                        handlecheckbox={
                                                            this.onOLED3Handle
                                                        }
                                                        // assign={state["assign" + port]}
                                                        textValue={
                                                            this.state
                                                                .curValOLED3
                                                        }
                                                        onChange={this.onChange}
                                                        label={'OLED Line 3'}
                                                        handleTextChange={
                                                            this
                                                                .onOLED3HandleText
                                                        }
                                                    />
                                                    <SelectionRow
                                                        name={
                                                            componentProps[type]
                                                                .name
                                                        }
                                                        port={port}
                                                        assign={
                                                            this.state
                                                                .isClickOLED4
                                                        }
                                                        key={port}
                                                        handlecheckbox={
                                                            this.onOLED4Handle
                                                        }
                                                        // assign={state["assign" + port]}
                                                        selected={
                                                            this.state
                                                                .curValOLED4
                                                        }
                                                        textValue={
                                                            this.state
                                                                .curValOLED4
                                                        }
                                                        onChange={this.onChange}
                                                        label={'OLED Line 4'}
                                                        handleTextChange={
                                                            this
                                                                .onOLED4HandleText
                                                        }
                                                    />
                                                </>
                                            )
                                        }
                                    }
                                    if (port == 'B1') {
                                        if (type == 'RGB') {
                                            var totalSliders = []
                                            for (
                                                var i = 1;
                                                i <= this.state.countRGBComp;
                                                i++
                                            ) {
                                                console.log(
                                                    'loopRGB',
                                                    this.state.countRGBComp
                                                )
                                                var slidr = (
                                                    <>
                                                        <SliderRow
                                                            name="RGBComp"
                                                            valR={
                                                                this.state
                                                                    .curValRGBComp[
                                                                    `RGBComp${i}`
                                                                ].R
                                                            }
                                                            valG={
                                                                this.state
                                                                    .curValRGBComp[
                                                                    `RGBComp${i}`
                                                                ].G
                                                            }
                                                            valB={
                                                                this.state
                                                                    .curValRGBComp[
                                                                    `RGBComp${i}`
                                                                ].B
                                                            }
                                                            key="LeftEye"
                                                            title={`RGB ${i}`}
                                                            assign={
                                                                this.state
                                                                    .isClickRGBComp[
                                                                    `isClickRGBComp${i}`
                                                                ]
                                                            }
                                                            handlecheckbox={
                                                                this
                                                                    .onRGBCompHandle
                                                            }
                                                            count={i}
                                                            min={0}
                                                            max={100}
                                                            getRangeVal={
                                                                this
                                                                    .onRangeValueRGBComp
                                                            }
                                                        />
                                                    </>
                                                )
                                                totalSliders = [
                                                    ...totalSliders,
                                                    slidr,
                                                ]
                                            }
                                            var styleAdd = {
                                                backgroundImage: `url(${renderImage(
                                                    'add3x'
                                                )}`,
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                height: 40,
                                                width: 40,
                                                position: 'relative',
                                                margin: 'auto',
                                                marginLeft: '60%',
                                            }
                                            var styleRemove = {
                                                backgroundImage: `url(${renderImage(
                                                    'remove3x'
                                                )}`,
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                height: 40,
                                                width: 40,
                                                position: 'relative',
                                                margin: 'auto',
                                                marginLeft: '70%',
                                            }
                                            if (this.state.countRGBComp == 10) {
                                                styleAdd = {
                                                    ...styleAdd,
                                                    backgroundImage: `url(${renderImage(
                                                        'add3xIA'
                                                    )}`,
                                                }
                                            }
                                            if (this.state.countRGBComp == 1) {
                                                styleRemove = {
                                                    ...styleRemove,
                                                    backgroundImage: `url(${renderImage(
                                                        'remove3xIA'
                                                    )}`,
                                                }
                                            }
                                            return (
                                                <>
                                                    {totalSliders}
                                                    <div
                                                        style={{
                                                            display: 'grid',
                                                            gridTemplateColumns:
                                                                ' repeat(2, 75px)',
                                                            marginLeft: '50%',
                                                            marginBottom: '5%',
                                                        }}
                                                    >
                                                        <div
                                                            style={styleAdd}
                                                            onClick={
                                                                this
                                                                    .onRGBCompIncrease
                                                            }
                                                        ></div>
                                                        <div
                                                            style={styleRemove}
                                                            onClick={
                                                                this
                                                                    .onRGBCompDecrease
                                                            }
                                                        ></div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    }

                                    if (
                                        port !== 'undefined' &&
                                        port.length !== 1 &&
                                        ![
                                            'dual_splitter',
                                            'servo_extender',
                                            'pc_motor_driver',
                                            'stepper_motor',
                                            'OLED',
                                            'RGB',
                                            'serial_servo_motor',
                                        ].includes(type)
                                    ) {
                                        return (
                                            <SliderRow
                                                name={componentProps[type].name}
                                                port={port}
                                                value={state['value' + port]}
                                                key={port}
                                                assign={state['assign' + port]}
                                                onChange={this.onChange}
                                                min={min}
                                                max={max}
                                            />
                                        )
                                    } else if (type === 'serial_servo_motor') {
                                        // const servoSlider =
                                        console.log(port, compName)

                                        return (
                                            <>
                                                <SliderRow
                                                    name={
                                                        componentProps[type]
                                                            .name
                                                    }
                                                    port={port}
                                                    value={
                                                        state['value' + port]
                                                    }
                                                    key={port}
                                                    assign={
                                                        state['assign' + port]
                                                    }
                                                    onChange={this.onChange}
                                                    min={min}
                                                    max={max}
                                                />
                                            
                                            </>
                                        )
                                    } else return null
                                }
                            })}
                        </tbody>
                    )} */}
                </table>
                {/* <button onClick={this.hexTypeCheck}>OK</button> */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state
}

// export default DragSource("data", cardSource, collect1)(Sidebar);

export default connect(mapStateToProps)(OutputPanel)
