import { title } from 'process'
import { connect } from 'react-redux'
import renderPrgImage from '../../../source/programImg'
import './AiModeSelect.css'

const pcAiAccessories = {
    row1: {
        ai_motion: {
            title: 'Motion',
            activeImg: 'ai_motionActive',
            inActiveImg: 'ai_motion',
        },
    },
    row2: {
        ai_color_detection: {
            title: 'Color Detection',
            activeImg: 'ai_color_detectionActive',
            inActiveImg: 'ai_color_detection',
        },
        ai_color_tracking: {
            title: 'Color Tracking',
            activeImg: 'ai_color_trackingActive',
            inActiveImg: 'ai_color_tracking',
        },
    },
    row3: {
        ai_face_detection: {
            title: 'face Detection',
            activeImg: 'ai_face_detectionActive',
            inActiveImg: 'ai_face_detection',
        },
        ai_face_recognition: {
            title: 'Face Recognition',
            activeImg: 'ai_face_recognitionActive',
            inActiveImg: 'ai_face_recognition',
        },
        ai_face_tracking: {
            title: 'Face Tracking',
            activeImg: 'ai_face_trackingActive',
            inActiveImg: 'ai_face_tracking',
        },
    },
    row4: {
        ai_mic: {
            title: 'Mic',
            activeImg: 'ai_micActive',
            inActiveImg: 'ai_mic',
        },
        ai_lidar: {
            title: 'Lidar',
            activeImg: 'ai_lidarActive',
            inActiveImg: 'ai_lidar',
        },
    },
    row5: {
        ai_LED: {
            title: 'LED',
            activeImg: 'ai_LEDActive',
            inActiveImg: 'ai_LED',
        },
    },
}

function AiModeSelect(props) {
    const assemblyPageAi = { ...props.assembly.assemblyPageAi }

    const handelClose = () => {
        let assemblyPageAi = { ...props.assembly.assemblyPageAi }
        assemblyPageAi.isShowAiSelectContainer = false
        props.setAssemblyPageAi(assemblyPageAi)
    }

    const handleClicks = (elementKey) => {
        let newAssemblyPageAi = { ...props.assembly.assemblyPageAi }

        let aiItems = { ...newAssemblyPageAi.aiItems }
        switch (elementKey) {
            case 'ai_motion':
                aiItems.ai_motion = !aiItems.ai_motion

                aiItems.ai_color_detection = false
                aiItems.ai_color_tracking = false
                aiItems.ai_face_detection = false
                aiItems.ai_face_recognition = false
                aiItems.ai_face_tracking = false
                break

            case 'ai_color_detection':
            case 'ai_color_tracking':
                aiItems[elementKey] = !aiItems[elementKey]

                aiItems.ai_motion = false
                aiItems.ai_face_detection = false
                aiItems.ai_face_recognition = false
                aiItems.ai_face_tracking = false
                break
            case 'ai_face_detection':
            case 'ai_face_recognition':
            case 'ai_face_tracking':
                aiItems[elementKey] = !aiItems[elementKey]

                aiItems.ai_motion = false
                aiItems.ai_color_detection = false
                aiItems.ai_color_tracking = false
                break
            default:
                aiItems[elementKey] = !aiItems[elementKey]
        }

        newAssemblyPageAi.aiItems = aiItems
        props.setAssemblyPageAi(newAssemblyPageAi)
    }

    return (
        <div className="aiModeSelectDiv">
            <img
                src={renderPrgImage('close')}
                style={{
                    position: 'absolute',
                    top: '-3%',
                    right: '-2%',
                    cursor: 'pointer',
                }}
                width="40px"
                alt="close"
                onClick={(e) => handelClose()}
            />
            <div className="aiModeSelect_container">
                {Object.keys(pcAiAccessories).map((rowKey, ind) => {
                    const row = pcAiAccessories[rowKey]
                    return (
                        <div key={rowKey} className="aiModeSelect_row">
                            {Object.keys(row).map((elementKey, ind) => {
                                return (
                                    <img
                                        key={ind}
                                        style={{ cursor: 'pointer' }}
                                        src={
                                            assemblyPageAi.aiItems[elementKey]
                                                ? renderPrgImage(
                                                      row[elementKey].activeImg
                                                  )
                                                : renderPrgImage(
                                                      row[elementKey]
                                                          .inActiveImg
                                                  )
                                        }
                                        // className="imgStyle_v1"
                                        width="20%"
                                        alt={row[elementKey].title}
                                        onClick={(e) =>
                                            handleClicks(elementKey)
                                        }
                                    />
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAssemblyPageAi: (data) => {
            dispatch({ type: 'ASSEMBLY_PAGE_AI', payload: data })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AiModeSelect)
