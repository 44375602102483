import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import './Control.css'

import selectbarY from '../../../Assets/Bisoft_UI/AppMode/selectBarfive.png'

import NavBar from '../NavBar'
import AiModeFooter from './AiModeFooter/AiModeFooter'

function Control() {
    const history = useHistory()

    const backButtonClick = () => {
        history.push('/train')
    }
    const nextButtonClick = () => {
        history.push('/control')
    }
    // const addButtonClick = () => {}

    return (
        <div>
            {/* NavBar */}
            <div>
                <NavBar
                    selectbar={selectbarY}
                    selectbarwidth="85%"
                    headers={[
                        { name: 'Choose Training Model', color: 'white' },
                        { name: 'Train', color: 'white' },
                        { name: 'Control', color: 'white' },
                    ]}
                    save={true}
                    showHelp={true}
                    // handleHelp={handleClickhelpbtn}
                />
            </div>

            {/* ------- Footer ------- */}
            <AiModeFooter
                isNextShow={true}
                isBackShow={true}
                // isAddShow={true}
                // nextClick={(e) => nextButtonClick()}
                backClick={(e) => backButtonClick()}
                // addClick={(e) => addButtonClick()}
            />
        </div>
    )
}

export default Control
