import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import selectbarY from '../../../Assets/Bisoft_UI/AppMode/selectbar@2.png'

import renderImage from '../../../source/importImg'
import NavBar from '../NavBar'
import AiModeFooter from './AiModeFooter/AiModeFooter'
import './AiTrain.css'
import { appAiBytesGeneration } from './AppModeAiBytesGeneration'
import DialogModal from '../../ReusableComponents/DialogModal/DialogModal'
// import { defaultAiTrainList } from '../../../source/assemblyPageAiData'

function AiTrain(props) {
    const history = useHistory()
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    // const [speechQueryArr, setSpeechQueryArr] = useState([{ value: '' }])
    const speechQueryArr = props.speechTrain.speechQueryArr
    const wakeWord = props.speechTrain.wakeWord
    const [uploadPopupText, setUploadPopupText] = useState('')
    const [uploadPopup, setUploadPopup] = useState(false)
    const [newSpeechAdd, setNewSpeechAdd] = useState(false)
    const lastSpeechRef = useRef(null)
    const sendDataAsPacket = useRef(false)

    const timeoutPromise = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log('HHHHHHHHHH Time Out')
                reject(new Error('Timeout exceeded'))
            }, ms)
        })
    }

    const checkOk = async () => {
        return new Promise(async (resolve, reject) => {
            while (true) {
                if (!sendDataAsPacket.current) {
                    return resolve()
                }

                await new Promise((reslove) => {
                    setTimeout(reslove, 100)
                })
            }
        })
    }

    const errorPopUp = async (message) => {
        setUploadPopup(true)
        setUploadPopupText(message)
        await new Promise((reslove) => setTimeout(reslove, 2000))

        setUploadPopup(false)
        setUploadPopupText('')
    }

    const backButtonClick = () => {
        history.push('/chooseTrainingDevice')
    }

    const addButtonClick = () => {
        if (speechQueryArr.length < 100) {
            let newSpeechQueryArr = props.speechTrain.speechQueryArr
            newSpeechQueryArr = [...newSpeechQueryArr, { value: '' }]
            props.setAppAiModeStorage({
                speechQueryArr: newSpeechQueryArr,
                type: 'speechQueryArr',
            })

            setNewSpeechAdd(true)
        }
    }

    useEffect(() => {
        if (
            newSpeechAdd &&
            speechQueryArr.length > 0 &&
            lastSpeechRef.current
        ) {
            lastSpeechRef.current.scrollIntoView({ behavior: 'smooth' })
            setNewSpeechAdd(false)
        }
    }, [props.speechTrain.speechQueryArr, newSpeechAdd])

    const deleteButtonClick = (ind) => {
        if (speechQueryArr.length > 1) {
            let newSpeechQueryArr = [...props.speechTrain.speechQueryArr]
            // let newTranQueryArr = [...speechQueryArr]
            newSpeechQueryArr.splice(ind, 1)
            props.setAppAiModeStorage({
                speechQueryArr: newSpeechQueryArr,
                type: 'speechQueryArr',
            })
        }
    }

    const handelWakeWardInput = (e) => {
        // let newWakeWord = e.target.value.trim()
        props.setAppAiModeStorage({
            wakeWord: e.target.value,
            type: 'wakeWord',
        })
    }

    const handleQueryInput = (e, ind) => {
        let newSpeechQueryArr = [...props.speechTrain.speechQueryArr]
        newSpeechQueryArr[ind].value = e.target.value
        props.setAppAiModeStorage({
            speechQueryArr: newSpeechQueryArr,
            type: 'speechQueryArr',
        })
    }

    //upload the bytes
    const uploadButtonClick = async () => {
        let wakeWord = props.speechTrain.wakeWord.trim()
        let speechQueryArr = props.speechTrain.speechQueryArr
        let listLength = speechQueryArr.length

        if (!wakeWord) {
            errorPopUp('Please set the default wake word.')
            return
        }
        if (wakeWord.length > 10) {
            errorPopUp(
                'Default wake word character length less-than or equal to 10.'
            )
            return
        }
        for (let ind = 0; ind < listLength; ind++) {
            if (speechQueryArr[ind].value > 16) {
                errorPopUp(
                    `For ID N0 ${
                        ind + 1
                    }. input speech word character length less-than or equal to 16.`
                )
                return
            }
        }

        setUploadPopup(true)
        setUploadPopupText('Program upload .....')

        let wakeBytes = appAiBytesGeneration('wakeByte', wakeWord, 0) //here last argument is the array index but it is not a array so send default index 0
        try {
            props.worker.postMessage({
                type: 'aiFormatWriteArray',
                value: wakeBytes,
            })
            // sessionStorage.setItem('sendDataAsPacket', 'true')
            sendDataAsPacket.current = true
            // await new Promise((reslove) => setTimeout(reslove, 1000))
            let replyOK = await Promise.race([
                checkOk(),
                timeoutPromise(5000), //
            ])
        } catch (e) {
            errorPopUp('Speech are not train, please tray again.')
            console.log('Error', e)
            return
        }

        //send the speech list one by one
        for (let ind = 0; ind < listLength; ind++) {
            if (!speechQueryArr[ind].value.trim()) {
                continue
            }
            let bytes = appAiBytesGeneration(
                'speechByte',
                speechQueryArr[ind],
                ind
            )

            if (props.webSerial) {
                try {
                    props.worker.postMessage({
                        type: 'aiFormatWriteArray',
                        value: bytes,
                    })
                    // sessionStorage.setItem('sendDataAsPacket', 'true')
                    sendDataAsPacket.current = true
                    // await new Promise((reslove) => setTimeout(reslove, 1000))
                    let replyOK = await Promise.race([
                        checkOk(),
                        timeoutPromise(5000), //
                    ])
                } catch (e) {
                    errorPopUp('Speech are not train, please tray again.')
                    console.log('Error', e)
                    return
                    // break
                }
            }
        }

        //set the speechQueryArr in aiTrainList to use in hex programming
        let newAiTrainList = {}
        if (localStorage.getItem('aiTrainList')) {
            newAiTrainList = JSON.parse(localStorage.getItem('aiTrainList'))
        } else {
            newAiTrainList = {
                speechTranList: [],
                colorTranList: [],
                faceTranList: [],
            }
        }
        newAiTrainList.speechTranList = [{ value: wakeWord }, ...speechQueryArr]
        localStorage.setItem('aiTrainList', JSON.stringify(newAiTrainList))

        //upload Successful popup
        // setUploadPopupText('Program upload successful.')
        // await new Promise((reslove) => setTimeout(reslove, 2000))

        // setUploadPopup(false)
        // setUploadPopupText('')
        errorPopUp('Speech train successful.')
    }

    // its save the createActionState data in local storage
    const saverecoveryData = () => {
        let newRecoveryData = {}
        let newAppAiModeStorage = {}
        try {
            newRecoveryData = JSON.parse(localStorage.getItem('recoveryData'))
        } catch (e) {
            console.log('ERROR', e)
            newRecoveryData = {}
        }
        try {
            newAppAiModeStorage = JSON.parse(
                sessionStorage.getItem('appAiModeStorage')
            )
        } catch (e) {
            console.log('ERROR', e)
            newAppAiModeStorage = {}
        }

        //create the recoveryData object
        function addData(baseType, connectedDevice, deviceVersion, data) {
            if (!newRecoveryData[baseType]) {
                newRecoveryData[baseType] = {}
            }
            if (!newRecoveryData[baseType][connectedDevice]) {
                newRecoveryData[baseType][connectedDevice] = {}
            }
            if (!newRecoveryData[baseType][connectedDevice][deviceVersion]) {
                newRecoveryData[baseType][connectedDevice][deviceVersion] = {}
            }
            newRecoveryData[baseType][connectedDevice][
                deviceVersion
            ].appAiModeStorage = data
        }

        addData(
            'appAiBase',
            connectedDevice,
            deviceVersion,
            newAppAiModeStorage
        )

        localStorage.setItem('recoveryData', JSON.stringify(newRecoveryData))
    }

    useEffect(() => {
        //if user close the window its save the createActionState data in local storage
        const handleBeforeUnload = (e) => {
            saverecoveryData()
        }

        //read data from serial worker
        const messageHandler = (e) => {
            if (
                e.data.type === 'read' &&
                (e.data.value.includes('AIWOK') ||
                    e.data.value.includes('AIVOK'))
            ) {
                // sessionStorage.setItem('sendDataAsPacket', 'false')
                sendDataAsPacket.current = false
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)
        props.worker.addEventListener('message', messageHandler)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
            props.worker.removeEventListener('message', messageHandler)
        }
    }, [])

    return (
        <div>
            {/* --------- upload  popup -------- */}
            <DialogModal
                show={uploadPopup}
                text={uploadPopupText}
                // showCloseBtn={true}
                // handleDialogModalClose={() => setUploadPopup(false)}
            />

            {/* NavBar */}
            <div>
                <NavBar
                    selectbar={selectbarY}
                    selectbarwidth="54%"
                    headers={[
                        { name: 'Choose Training Model', color: 'white' },
                        { name: 'Train', color: 'white' },
                        { name: '', color: 'black' },
                    ]}
                    save={true}
                    showHelp={true}
                    // handleHelp={handleClickhelpbtn}
                />
            </div>

            {/* -------- Body -------- */}
            <div className="aiTrainBody">
                <div className="wakeWordDiv">
                    <div
                        className="wakeWordInnerDiv"
                        style={{
                            backgroundImage: `url("${renderImage(
                                'cardBackground'
                            )}")`,
                            backgroundSize: '100% 100%',
                            backgroundRepeat: 'no-repeat',
                        }}
                    >
                        <p style={{ marginBottom: '0px' }}>Default Wake Word</p>

                        <input
                            style={{ paddingLeft: '10px' }}
                            type="text"
                            placeholder="Hey PeeCee"
                            value={wakeWord}
                            onChange={(e) => handelWakeWardInput(e)}
                        />
                    </div>
                </div>

                <div className="trainQueryDiv">
                    {speechQueryArr.map((ele, ind) => {
                        return (
                            <div
                                key={ind}
                                className="eachQueryDiv"
                                ref={
                                    ind === speechQueryArr.length - 1
                                        ? lastSpeechRef
                                        : null
                                }
                            >
                                <p>ID No {ind + 1}.</p>
                                <input
                                    type="text"
                                    value={ele.value}
                                    onChange={(e) => handleQueryInput(e, ind)}
                                />
                                {speechQueryArr.length > 1 && (
                                    <img
                                        src={renderImage('minusButton')}
                                        alt="delete"
                                        onClick={(e) => deleteButtonClick(ind)}
                                    />
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>

            {/* ------- Footer ------- */}
            <AiModeFooter
                isUploadShow={true}
                isBackShow={true}
                isAddShow={speechQueryArr.length < 100 ? true : false}
                uploadClick={(e) => uploadButtonClick()}
                backClick={(e) => backButtonClick()}
                addClick={(e) => addButtonClick()}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        // appAiModeStorage: state.appAiModeStorage,

        speechTrain: state.appAiModeStorage.speechTrain,
        worker: state.worker,
        webSerial: state.webSerial,
        // aiTrainList: state.assembly.aiTrainList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //AI_TRAIN_LIST
        setAppAiModeStorage: (data) => {
            dispatch({ type: 'APP_AI_MODE_STORAGE', payload: data })
        },
        // setAiTrainList: (data) => {
        //     dispatch({ type: 'AI_TRAIN_LIST', payload: data })
        // },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AiTrain)
