import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import NavBar from '../NavBar'
import './ChoseTrainingDevice.css'

import selectbarY from '../../../Assets/Bisoft_UI/AppMode/selectbar.png'
import renderImage from '../../../source/importImg'
import AiModeFooter from './AiModeFooter/AiModeFooter'
import DialogModal from '../../ReusableComponents/DialogModal/DialogModal'
import { appAiBytesGeneration } from './AppModeAiBytesGeneration'

const tabs = {
    camera: {
        id: 1,
        title: 'Camera',
        // defaultDevice: 'Ace',
        content: {
            // motion: {
            //     detectionMode: 'Motion',
            //     activeImage: 'aiObjectActive',
            //     InactiveImage: 'aiObjectInActive',
            // },
            color: {
                detectionMode: 'Color',
                activeImage: 'aiColorActive',
                InactiveImage: 'aiColorinActive',
            },
            face: {
                detectionMode: 'Face',
                activeImage: 'aiFaceActive',
                InactiveImage: 'aiFaceInActive',
            },
            // object: {
            //     detectionMode: 'Object',
            //     activeImage: 'aiObjectActive',
            //     InactiveImage: 'aiObjectInActive',
            // },
        },
    },
    speech: {
        id: 2,
        title: 'Speech',
        content: {
            speech: {
                detectionMode: 'Speech',
                activeImage: 'aiSpeechActive',
                InactiveImage: 'aiSpeechInActive',
            },
        },
    },
}
const nextPageUrl = {
    face: '/faceTrain',
    color: '/colorTrain',
    speech: '/train',
}
const localStorageAiTrainListKey = {
    face: 'faceTranList',
    color: 'colorTranList',
    speech: 'speechTranList',
}

function ChoseTrainingDevice(props) {
    // const [tabFocus, setTabFocus] = useState('camera')
    // const [detectionModeFocus, setDetectionModeFocus] = useState(-1)
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    // const [erasePopup, setErasePopup] = useState(false)
    // const [isSelectPopup, setIsSelectPopup] = useState(false)
    // const [isClean, setIsClean] = useState(false)
    const [showPopup, setShowPopup] = useState({
        isShow: false,
        message: '',
        action: '',
    })
    const [tabFocusModeContent, setTabFocusModeContent] = useState(
        tabs['camera'].content
    )
    const detectionModeFocus = props.chooseTrainingDevice.detectionModeFocus
    const tabFocus = props.chooseTrainingDevice.tabFocus
    const history = useHistory()
    const sendDataAsPacket = useRef({ dataSend: false, replay: '' })

    const timeoutPromise = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log('HHHHHHHHHH Time Out')
                sendDataAsPacket.current = false
                reject(new Error('Timeout exceeded'))
            }, ms)
        })
    }

    const checkOk = async () => {
        return new Promise(async (resolve, reject) => {
            while (true) {
                if (!sendDataAsPacket.current) {
                    return resolve()
                }

                await new Promise((reslove) => {
                    setTimeout(reslove, 100)
                })
            }
        })
    }

    //get the contents of each tabs
    useEffect(() => {
        Object.keys(tabFocus).map((objKey) => {
            if (tabFocus[objKey]) {
                setTabFocusModeContent(tabs[objKey].content)
            }
        })
    }, [tabFocus])

    const backButtonClick = () => {
        setShowPopup({
            isShow: true,
            message:
                'All Unsaved program will be Erased. Are you sure want to continue ?',
            action: 'erase',
        })
    }
    const nextButtonClick = () => {
        try {
            let optionSelect = false
            let aiTrainList =
                localStorage.getItem('aiTrainList') ||
                JSON.stringify({
                    colorTranList: [],
                    faceTranList: [],
                    speechTranList: [],
                })
            aiTrainList = JSON.parse(aiTrainList)

            let selectedDevice = ''
            Object.keys(detectionModeFocus).map((objKey) => {
                if (detectionModeFocus[objKey]) {
                    optionSelect = true
                    selectedDevice = objKey
                }
            })

            //if any option not selected show popup
            if (!optionSelect) {
                setShowPopup({
                    isShow: true,
                    message:
                        'Please select the option before click on next button.',
                    action: 'selectDevice',
                })
                return
            }

            if (['color', 'face'].includes(selectedDevice)) {
                let reduxTrainList = []
                if (selectedDevice == 'color') {
                    reduxTrainList = props.colorTranList
                } else {
                    reduxTrainList = props.faceTranList
                }
                let redux_isUpload_true_check = reduxTrainList.some(
                    (item) => item.isUpload === true
                )

                if (
                    !redux_isUpload_true_check &&
                    aiTrainList[localStorageAiTrainListKey[selectedDevice]]
                        ?.length > 0
                ) {
                    setShowPopup({
                        isShow: true,
                        message: `You want train new ${selectedDevice} if yes old ${selectedDevice} will erase.`,
                        action: 'clean',
                    })
                    return
                }
            }

            history.push(nextPageUrl[selectedDevice])
        } catch (e) {
            console.log('ERROR', e)
        }
    }

    //handel click on tabs mode and update thr redux
    const handelTabClick = (ele) => {
        // setDetectionModeFocus(ele)
        let newTabFocus = {
            ...props.chooseTrainingDevice.tabFocus,
        }
        Object.keys(newTabFocus).map((objKey) => (newTabFocus[objKey] = false))
        newTabFocus[ele] = true
        props.setAppAiModeStorage({
            tabFocus: newTabFocus,
            type: 'tabFocus',
        })
    }

    //handel click on detection mode and update thr redux
    const detectionModeClick = (ele) => {
        // setDetectionModeFocus(ele)
        let newDetectionModeFocus = {
            ...props.chooseTrainingDevice.detectionModeFocus,
        }
        Object.keys(newDetectionModeFocus).map(
            (objKey) => (newDetectionModeFocus[objKey] = false)
        )
        newDetectionModeFocus[ele] = true
        props.setAppAiModeStorage({
            detectionModeFocus: newDetectionModeFocus,
            type: 'detectionModeFocus',
        })
    }

    //erased the data
    const shouldEraseYes = () => {
        // sessionStorage.removeItem('appAiModeStorage')
        let newRecoveryData = JSON.parse(
            localStorage.getItem('recoveryData') || JSON.stringify({})
        )

        //delete from redux and session storage
        props.setAppAiModeStorage({
            setAppAiModeStorage: '',
            type: 'RemoveAppAiModeStorage',
        })

        //delete from recovery data
        deleteRecoveryData(
            newRecoveryData,
            'appAiBase',
            connectedDevice,
            deviceVersion
        )
        history.push('/appMode/selectHardware')
    }

    //clean the previous ai trains
    const shouldCleanYes = async () => {
        let selectedDevice = ''
        try {
            let aiTrainList =
                localStorage.getItem('aiTrainList') ||
                JSON.stringify({
                    colorTranList: [],
                    faceTranList: [],
                    speechTranList: [],
                })
            aiTrainList = JSON.parse(aiTrainList)

            Object.keys(detectionModeFocus).map((objKey) => {
                if (detectionModeFocus[objKey]) {
                    selectedDevice = objKey
                }
            })

            let clearBytes = appAiBytesGeneration('cleanByte', selectedDevice)
            sendDataAsPacket.current = true

            setShowPopup({
                isShow: true,
                message: `Erasing....`,
                action: 'errorMessage',
            })

            props.worker.postMessage({
                type: 'aiFormatWriteArray',
                value: clearBytes,
            })
            // await new Promise((reslove) => setTimeout(reslove, 2000))
            let replyOK = await Promise.race([
                checkOk(),
                timeoutPromise(1000), //
            ])

            aiTrainList[localStorageAiTrainListKey[selectedDevice]] = []
            localStorage.setItem('aiTrainList', JSON.stringify(aiTrainList))
            history.push(nextPageUrl[selectedDevice])
        } catch (e) {
            console.log('ERROR', e)
            setShowPopup({
                isShow: true,
                message: `Old ${selectedDevice} does not erase, please tray again.`,
                action: 'errorMessage',
            })
            setTimeout(() => {
                setShowPopup({
                    isShow: false,
                    message: ``,
                    action: '',
                })
            }, 1500)
        }
    }

    const getDialogModelOption = (actinName) => {
        try {
            if (actinName === 'clean') {
                return [
                    {
                        text: 'YES',
                        do: () => shouldCleanYes(),
                    },
                    {
                        text: 'NO',
                        do: () =>
                            setShowPopup({
                                isShow: false,
                                message: '',
                                action: '',
                            }),
                    },
                ]
            } else if (actinName === 'erase') {
                return [
                    {
                        text: 'YES',
                        do: () => shouldEraseYes(),
                    },
                    {
                        text: 'NO',
                        do: () =>
                            setShowPopup({
                                isShow: false,
                                message: '',
                                action: '',
                            }),
                    },
                ]
            } else return []
        } catch (e) {
            console.log('ERROR', e)
        }
    }

    // its save the setAppAiModeStorage data in local storage
    const saverecoveryData = () => {
        let newAppAiModeStorage = {}
        let newRecoveryData = JSON.parse(
            localStorage.getItem('recoveryData') || JSON.stringify({})
        )
        try {
            newAppAiModeStorage = JSON.parse(
                sessionStorage.getItem('appAiModeStorage')
            )
        } catch (e) {
            console.log('ERROR', e)
        }

        //create the recoveryData object
        function addData(baseType, connectedDevice, deviceVersion, data) {
            if (!newRecoveryData[baseType]) {
                newRecoveryData[baseType] = {}
            }
            if (!newRecoveryData[baseType][connectedDevice]) {
                newRecoveryData[baseType][connectedDevice] = {}
            }
            if (!newRecoveryData[baseType][connectedDevice][deviceVersion]) {
                newRecoveryData[baseType][connectedDevice][deviceVersion] = {}
            }
            newRecoveryData[baseType][connectedDevice][
                deviceVersion
            ].appAiModeStorage = data
        }

        addData(
            'appAiBase',
            connectedDevice,
            deviceVersion,
            newAppAiModeStorage
        )

        localStorage.setItem('recoveryData', JSON.stringify(newRecoveryData))
    }

    //delete the data from recovery object
    function deleteRecoveryData(newRecoveryData, baseType, device, version) {
        if (newRecoveryData[baseType] && newRecoveryData[baseType][device]) {
            delete newRecoveryData[baseType][device][version]

            // If the device has no more versions, delete the device
            if (Object.keys(newRecoveryData[baseType][device]).length === 0) {
                delete newRecoveryData[baseType][device]
            }

            // If the baseType has no more devices, delete the baseType
            if (Object.keys(newRecoveryData[baseType]).length === 0) {
                delete newRecoveryData[baseType]
            }
        }
        localStorage.setItem('recoveryData', JSON.stringify(newRecoveryData))
    }

    //if user close the window its save the createActionState data in local storage
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            saverecoveryData()
        }

        const messageHandler = (e) => {
            if (e.data.type === 'read' && e.data.value.includes('AIXOK')) {
                sendDataAsPacket.current = false
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)
        props.worker.addEventListener('message', messageHandler)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
            props.worker.removeEventListener('message', messageHandler)
        }
    }, [])

    return (
        <div>
            {/* --------- Erased popup -------- */}
            {/* <DialogModal
                show={erasePopup}
                text="All Unsaved program will be Erased. Are you sure want to continue ?"
                showCloseBtn={false}
                // handleDialogModalClose={handleDialogModalClose}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => shouldEraseYes(),
                    },
                    {
                        text: 'NO',
                        do: () => setErasePopup(false),
                    },
                ]}
            /> */}
            {/* --------- not select mode  popup -------- */}
            {/* <DialogModal
                show={isSelectPopup}
                text="Please select the option before click on next button."
                showCloseBtn={true}
                handleDialogModalClose={() => setIsSelectPopup(false)}
            /> */}
            {/* --------- not select mode  popup -------- */}
            <DialogModal
                show={showPopup.isShow}
                text={showPopup.message}
                showCloseBtn={true}
                handleDialogModalClose={() =>
                    setShowPopup({
                        isShow: false,
                        message: '',
                        action: '',
                    })
                }
                optionsToSelect={getDialogModelOption(showPopup.action)}
            />

            {/* NavBar */}
            <div>
                <NavBar
                    selectbar={selectbarY}
                    selectbarwidth="24%"
                    headers={[
                        { name: 'Choose Training Model', color: 'white' },
                        { name: 'Train', color: 'black' },
                        { name: '', color: 'black' },
                    ]}
                    save={true}
                    showHelp={true}
                    // handleHelp={handleClickhelpbtn}
                />
            </div>
            <div style={{ display: 'flex' }}>
                <aside className="trainingDeviceSidebar">
                    <div className="sidebarDeviceNameDiv">
                        {Object.keys(tabs).map((ele) => {
                            return (
                                <div
                                    style={{
                                        background:
                                            tabFocus[ele] == true
                                                ? 'linear-gradient(90deg, rgb(161, 160, 160), rgb(241, 241, 241))   '
                                                : '',
                                    }}
                                    key={tabs[ele].id}
                                    className="sidebarDeviceName"
                                    onClick={(e) => handelTabClick(ele)}
                                >
                                    <p
                                        style={{
                                            marginBottom: '0px',
                                            marginLeft: '30px',
                                            fontSize: '1.3rem',
                                        }}
                                    >
                                        {tabs[ele].title}
                                    </p>
                                    <div
                                        style={{
                                            display:
                                                tabFocus[ele] == true
                                                    ? 'block'
                                                    : 'none',
                                            marginRight: '30px',
                                        }}
                                    >
                                        <img src={renderImage('arrowRight')} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </aside>

                <div className="choseTrainingDeviceBody">
                    <div className="detectionModeDiv">
                        {Object.keys(tabFocusModeContent).map((ele, ind) => {
                            return (
                                <div
                                    key={ind}
                                    className="detectionModeEachContent"
                                >
                                    <img
                                        className="detectionModeImg"
                                        src={
                                            detectionModeFocus[ele] === true
                                                ? renderImage(
                                                      tabFocusModeContent[ele]
                                                          .activeImage
                                                  )
                                                : renderImage(
                                                      tabFocusModeContent[ele]
                                                          .InactiveImage
                                                  )
                                        }
                                        alt={ele.detectionMode}
                                        onClick={(e) => detectionModeClick(ele)}
                                    />
                                    <p
                                        className="detectionModeTitel"
                                        onClick={(e) => detectionModeClick(ele)}
                                    >
                                        {tabFocusModeContent[ele].detectionMode}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            {/* ------- Footer ------- */}
            <AiModeFooter
                isNextShow={true}
                isBackShow={true}
                nextClick={(e) => nextButtonClick()}
                backClick={(e) => backButtonClick()}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        // appAiModeStorage: state.appAiModeStorage,

        chooseTrainingDevice: state.appAiModeStorage.chooseTrainingDevice,
        worker: state.worker,
        colorTranList: state.appAiModeStorage.colorTrain.tranList,
        faceTranList: state.appAiModeStorage.faceTrain.tranList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAppAiModeStorage: (data) => {
            dispatch({ type: 'APP_AI_MODE_STORAGE', payload: data })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChoseTrainingDevice)
