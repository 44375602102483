import React, { Component } from 'react'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import Assembly from '../src/Components/Assembly'
import HomePage from './Components/Home'
import PrivacyPolicy from './Components/Home/privacyPolicy.jsx'
import TermsAndConditions from './Components/Home/termsAndConditions.jsx'

import './App.css'

// import Login from './Components/Login';
import saveTutorials from '../src/Components/tutorials'
import ComingSoon from './Components/Login/ComingSoon'

//flowchart dont change the order of import of unused myflowchart (css conflict)
// import Myflowchart from "../src/Components/FlowchartConnections/Myflowchart/Myflowchart";
import Flow from '../src/Components/flowchart_port/'

// concept/ select Screen
import SelectScreen from './Components/selectScreen/SelectScreen'
import CenterMode from './Components/concept'
import Level from './Components/level'
import Logic from '../src/Components/logic'
import { connect } from 'react-redux'
import Sizes from './helpers/Sizes'
import Login from '../src/Components/Login/index'
// import Hexaboard from '../src/Components/Hexaboard/index'
import BiboxSelection from './Components/Login/BiboxSelect.js'
import SelectDevice from './Components/Login/SelectDevice'
import deviceSelection from '../src/Components/Login/deviceSelect.js'
import Selection from '../src/Components/Login/Selection.js'
import ProgramSelection from '../src/Components/Login/programSelection.js'
import { createBrowserHistory } from 'history'

import ConceptHelp from '../src/Components/help/concept_help'
import Simulate from './Components/Simulate'

import SavePage from '../src/Components/SavePage/index'
import SavedProgram from '../src/Components/SavePage/savedProgram'
import Project from '../src/Components/SavePage/project'

import AdvanceSettingV from '../src/Components/AdvanceSetting/AdvanceSetting'
import AdvanceSettingV1 from '../src/Components/AdvanceSetting/AdvanceSettingV1'
import AdvanceSettingZing from '../src/Components/AdvanceSetting/AdvanceSettingZing'
import UserAccount from '../src/Components/AdvanceSetting/UserAccount'

import PlaymodeScreen from '../src/Components/play/PlayMode'
import Speak from '../src/Components/play/Speak/index'
import Piano from '../src/Components/play/Piano/tern+/piano'
import Camera from '../src/Components/play/Camera/tern+/Camera.js'
import Traceme from '../src/Components/play/TraceMe/Traceme'
import Model3D from '../src/Components/play/3dModel/3dModel'

// Humanoid
import HumanoidRemote from './Components/play/Remote/humanoid-hexapod/remote'
import HumanoidSpeak from '../src/Components/play/Speak/humanoid/speak'
import HumanoidPiano from '../src/Components/play/Piano/humanoid/piano'

////Itern Modules

import Input from './Pages/InputOutput/Input'
import Ports from './Pages/Ports/Port'
import Digital from './Pages/Digital/Digital'
import FlowchartPage from './Pages/FlowChartPage/FlowchartPage'
import Header from './Components/Header/Header'

import Mid from './Components/humanoidFlowChart/Mid_level/Mid'
import FlowChart from './Components/humanoidFlowChart/FlowChart/FlowChart'
import EnableServos from './Components/humanoidFlowChart/EnableServos/EnableServos'

import AdjustTilt from './Components/humanoidFlowChart/AdjustTilt/AdjustTilt'
import SelectPort from './Components/humanoidFlowChart/SelectPort/SelectPort'
import InputOutput from './Components/humanoidFlowChart/InputOutput/InputOutput'
import Digital_Analog from './Components/humanoidFlowChart/Digital_Analog/Digital_Analog'
import MainFlowChart from './Components/humanoidFlowChart/MainFlowChart/MainFlowChart'
import ScratchProgramming from './Components/humanoidFlowChart/ScratchLogicFlow/ScratchProgramming'

// PLAY_COMPUTER
import ExternalAccessoriesScreen from './Components/selectScreen/ExternalAccessoriesScreen'
import InternalAccessoriesScreen from './Components/selectScreen/InternalAccessoriesScreen'
// import ConformationPopup from "./Components/Login/ConformationPopup";
import Learn from '../src/Components/Login/learn'

import Code from './Components/Code'

// HUMANOID
import HumanoidRemoteSection from './Components/play/Remote/humanoid-hexapod/Remote/Remote'
import WebSerialConnection from './Components/WebSerialConnection/WebSerialConnection'
import TestingWebSerial from './Components/ReusableComponents/ByteTransfer/TestingWebSerial'
import KlawRemoteSection from './Components/play/Remote/Klaw/Remote/Remote'

// PLAY_COMPUTER v1.0
import InternalAccessoriesScreen_v1 from './playComputer_v1.0/Components/selectScreen/InternalAccessoriesScreen'

// PLAY_COMPUTER v1.0 PLAY_MODE
import Play from './playComputer_v1.0/Components/PlayMode/index'

// AppMode
import Remote from './Components/AppMode/Remote'
import AppModeSelection from './Components/AppMode/AppModeSeletion'
import DeviceSelection from './Components/AppMode/DeviceSelection'
import KitSelection from './Components/Login/KitSelection'
import SelectDetection from './Components/AppMode/SelectDetection'
import ProgrammingPage from './Components/AppMode/ProgrammingPage'
import UploadImage from './Components/AppMode/FaceDetection/UploadImage'
import EnterName from './Components/AppMode/FaceDetection/EnterName'
import SavedFaces from './Components/AppMode/FaceDetection/SavedFaces'
import SpeechRecognitionPage from './Components/AppMode/SpeechRecognition'
import PlayComputer from './Components/AppMode/PlayComputer'
import ObjectDetection from './Components/AppMode/FaceDetection/ObjectDetection'
import TeachableMachine from './Components/AppMode/FaceDetection/TeachableMachine'
import DialogModal from './Components/ReusableComponents/DialogModal/DialogModal'
import PopUp from './Components/ReusableComponents/PopUps/PopUp'

// import Graph plotter
import GraphPlotter from './Components/Assembly/GraphPlotter/GraphPlotter'
import GraphSensor from './Components/Assembly/GraphPlotter/Graph'
import GraphTime from './Components/Assembly/GraphPlotter/GraphTime'
import GraphPorts from './Components/Assembly/GraphPlotter/GraphPorts/GraphPorts'
import LineGraph from './Components/Assembly/GraphPlotter/LineGraph/LineGraph'
import BarGraph from './Components/Assembly/GraphPlotter/BarGraph/BarGraph'
import MeterGraph from './Components/Assembly/GraphPlotter/Meter/Meter'
import SelectCategory from './Components/AppMode/SelectCategory'
import ChoseTrainingDevice from './Components/AppMode/AppModeAI/ChoseTrainingDevice'
import Control from './Components/AppMode/AppModeAI/Control'
import AiTrain from './Components/AppMode/AppModeAI/AiTrain'
import ColorFaceTrain from './Components/AppMode/AppModeAI/ColorFaceTrain'

// import musick from "./Assets/music group button.svg";

//const socket = socketIOClient("http://localhost:3008");

const history = createBrowserHistory()

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.AssemblyWrap = this.AssemblyWrap.bind(this)
        // socket.on("Alive", (e) => {
        //   console.log("Alive is Getting Called ...");
        //   socket.emit("_Alive", {"info" : window.location.href})
        // })
        // socket.on("Alive", (e) => {
        //   socket.emit("_Alive", (e) => {});
        // });
        this.props.worker()
        window.addEventListener('resize', (e) => {
            const height = document.body.clientHeight
            const width = document.body.clientWidth
            Sizes._update(width, height)
            this.props.sizesHelper({ width, height })
        })

        window.addEventListener('load', () => {
            const height = document.body.clientHeight
            const width = document.body.clientWidth
            Sizes._update(document.body.clientWidth, document.body.clientHeight)
            this.props.sizesHelper({ width, height })
        })

        window.addEventListener(
            'beforeunload',
            (e) => {
                // console.log("refresh", e.returnValue)
                // e.returnValue = `Are you sure you want to leave?`;
                // e.preventDefault()
                // if (window.location.href.includes("scratch-tool")) {
                //   socket.emit("Browserclose", "scratch-tool");
                // } else {
                //   socket.emit("Browserclose", "hye");
                // }
                // return "Leaving this page will reset the wizard";
                // if (performance.navigation.type == 1) {
                //   console.info("This page is reloaded");
                //   socket.emit("Browserclose", "refresh")
                // } else {
                //   // e.preventDefault()
                //   console.info("This page is not reloaded");
                //   socket.emit("Browserclose", "HYE")
                //   return "Do you really want to leave our brilliant application?";
                // }
            },
            false
        )
        window.addEventListener('touchstart', (e) => {
            if (
                window.location.pathname == '/Assembly' &&
                sessionStorage.getItem('isTouchEnabled') != 'true'
            ) {
                sessionStorage.setItem('isTouchEnabled', true)
                window.location.reload()
            }
        })
        window.addEventListener('mousedown', (e) => {
            if (
                window.location.pathname == '/Assembly' &&
                !e.sourceCapabilities.firesTouchEvents &&
                sessionStorage.getItem('isTouchEnabled') == 'true'
            ) {
                sessionStorage.setItem('isTouchEnabled', false)
                window.location.reload()
            }
        })
    }

    AssemblyWrap() {
        return (
            <Assembly
                height={this.props.state.assembly.height}
                width={Sizes.width}
            ></Assembly>
        )
    }

    componentDidUpdate() {
        // if a device is not selected, every other page redirects to /biboxselection
        const location = window.location
        const connnectedDevice = sessionStorage.getItem('connectedDevice')

        if (
            connnectedDevice === null &&
            location.pathname !== '/deviceSelection' &&
            location.pathname !== '/kitSelection' &&
            location.pathname !== '/' &&
            location.pathname !== '/login' &&
            location.pathname !== '/accountSetting' &&
            location.pathname !== '/advanceSettingv1.0' &&
            location.pathname !== '/advanceSettingv0.1' &&
            location.pathname !== '/advanceSettingZingv0.1'
        )
            window.location.replace('/kitSelection')

        console.log(location.pathname)
        document.addEventListener('isConnecting', (e) => {
            if (e.detail) this.props.isConnecting(true)
            else this.props.isConnecting(false)
        })
        // if(sessionStorage.getItem('connectedDevice')=='Unknown'){
        //     let lastDevice=sessionStorage.getItem('lastConnectedDevice');
        //     sessionStorage.setItem('connectedDevice',lastDevice)
        //    }
        //    console.log("root",sessionStorage.getItem('connectedDevice'))
    }

    render() {
        return (
            <>
                <PopUp />
                <WebSerialConnection worker={this.props.state.worker} />
                <Route
                    exact
                    path="/testingwebserial"
                    component={TestingWebSerial}
                />
                <BrowserRouter history={history}>
                    {/* {sessionStorage.getItem("pgmode")=="flow-chart" 
          ?
        <Header prev="/"/>
          :
         null
         } */}

                    {/* <img src={musick}></img> */}
                    <Switch>
                        <Route exact path="/serve-flow" component={Ports} />

                        <Route exact path="/input-output" component={Input} />
                        <Route
                            exact
                            path="/digital-analog"
                            component={Digital}
                        />
                        <Route
                            exact
                            path="/flowchart"
                            component={FlowchartPage}
                        />

                        {/* <Route path="/" component={Home} exact /> */}
                        <Route
                            path="/learn-mid"
                            render={(props) => <Mid {...props} />}
                            exact
                        />
                        <Route
                            path="/learn-mid/flowChart"
                            render={(props) => <FlowChart {...props} />}
                            exact
                        />
                        <Route
                            path="/learn-mid/flowChart/LetCode/Enable-Servos"
                            render={(props) => <EnableServos {...props} />}
                            exact
                        />
                        <Route
                            path="/learn-mid/flowChart/LetCode/Adjust-Tilt"
                            render={(props) => <AdjustTilt {...props} />}
                            exact
                        />
                        <Route
                            path="/learn-mid/flowChart/LetCode/Adjust-Tilt/selectPort"
                            render={(props) => <SelectPort {...props} />}
                            exact
                        />
                        <Route
                            path="/learn-mid/flowChart/LetCode/Adjust-Tilt/input-output"
                            render={(props) => <InputOutput {...props} />}
                            exact
                        />
                        <Route
                            path="/learn-mid/flowChart/LetCode/Adjust-Tilt/digital-analog"
                            render={(props) => <Digital_Analog {...props} />}
                            exact
                        />
                        <Route
                            path="/learn-mid/flowChart/LetCode/Adjust-Tilt/flow-chart"
                            component={MainFlowChart}
                            exact
                        />

                        <Route
                            path="/learn-mid/flowChart/LetCode/Adjust-Tilt/logic-flow/scratch"
                            component={ScratchProgramming}
                            exact
                        />

                        <Route path="/login" exact component={Login} />
                        <Route path="/" exact component={HomePage} />
                        <Route
                            path="/privacyPolicy"
                            component={PrivacyPolicy}
                            exact
                        />
                        <Route
                            path="/TermsAndConditions"
                            component={TermsAndConditions}
                            exact
                        />
                        <Route
                            path="/comingSoon"
                            exact
                            component={ComingSoon}
                        />
                        <Route
                            history={history}
                            path="/Assembly"
                            component={this.AssemblyWrap}
                        />

                        <Route path="/saveprogram" exact component={SavePage} />
                        <Route
                            path="/advanceSettingv0.1"
                            exact
                            component={AdvanceSettingV}
                        />
                        <Route
                            path="/advanceSettingv1.0"
                            exact
                            component={AdvanceSettingV1}
                        />
                        <Route
                            path="/advanceSettingZingv0.1"
                            exact
                            component={AdvanceSettingZing}
                        />
                        <Route
                            path="/accountSetting"
                            exact
                            component={UserAccount}
                        />
                        <Route
                            path="/savedprogram"
                            exact
                            component={SavedProgram}
                        />
                        <Route path="/project/:id" exact component={Project} />
                        <Route
                            path="/advanceSettingv0.1"
                            exact
                            component={AdvanceSettingV}
                        />

                        <Route path="/Level" component={Level} />
                        <Route
                            path="/selectScreen/InternalAccessories"
                            component={InternalAccessoriesScreen}
                        />
                        <Route
                            path="/selectScreen/ExternalAccessories"
                            component={ExternalAccessoriesScreen}
                        />

                        {/* <Route path="/Concept" component={CenterMode} /> */}
                        <Route path="/logic" component={Logic} />
                        <Route path="/Learn" component={Learn}></Route>
                        <Route path="/Program" component={Learn} />
                        <Route path="/concepthelp" component={ConceptHelp} />
                        <Route
                            path="/programSelection"
                            component={ProgramSelection}
                        ></Route>
                        <Route path="/flow" component={Flow}></Route>
                        <Route path="/simulate" component={Simulate} />

                        <Route path="/code" component={Code} />

                        <Route
                            exact
                            path="/biboxSelection"
                            component={BiboxSelection}
                        />
                        <Route
                            exact
                            path="/selectdevice"
                            component={SelectDevice}
                        />
                        <Route
                            exact
                            path="/kitSelection"
                            component={KitSelection}
                        />
                        <Route
                            exact
                            path="/deviceSelection"
                            component={deviceSelection}
                        />
                        <Route exact path="/Selection" component={Selection} />
                        {/* <Route exact path="/conformation" component={ConformationPopup} /> */}
                        <Route
                            exact
                            path="/saveTutorials"
                            component={saveTutorials}
                        />

                        <Route exact path="/play">
                            {' '}
                            <Model3D />
                        </Route>
                        <Route exact path="/introduction">
                            {' '}
                            <PlaymodeScreen />
                        </Route>

                        <Route exact path="/SelectMode" />

                        {/* Give path to tern+ and humanoid */}

                        {/* Tern + */}
                        {/* <Route path="/remote" component={Remote}></Route> */}
                        <Route path="/speak" component={Speak}></Route>
                        <Route path="/music" component={Piano}></Route>
                        <Route path="/camera" component={Camera}></Route>
                        <Route path="/trace-me" component={Traceme}></Route>

                        {/* the component Route change to AceRemote */}
                        {/* <Route path="/remote-Ace" component={AceRemote}></Route> */}

                        {/* PLAY_COMPUTER CHNAGE BY: SOUMITYA */}
                        <Route path="/remote-Ace" component={Play}></Route>
                        <Route path="/v1/remote-Ace" component={Play}></Route>
                        <Route
                            path="/remote-humanoid"
                            component={HumanoidRemoteSection}
                        ></Route>
                        <Route
                            path="/remote-hexapod"
                            component={HumanoidRemoteSection}
                        ></Route>
                        <Route
                            path="/remote-Klaw"
                            component={KlawRemoteSection}
                        ></Route>

                        {/* --PlayComputer--------Version--------1.0.03-- */}
                        <Route
                            path="/v1/selectScreen/InternalAccessories"
                            component={InternalAccessoriesScreen_v1}
                        />

                        {/* Humanoid  */}
                        <Route path="/Humanoid-Remote">
                            {' '}
                            <HumanoidRemote />
                        </Route>
                        <Route path="/Humanoid-Speak">
                            {' '}
                            <HumanoidSpeak />
                        </Route>
                        <Route path="/Humanoid-Music">
                            {' '}
                            <HumanoidPiano />
                        </Route>

                        {/* App Mode */}
                        <Route
                            path="/appMode/selectCategory"
                            component={SelectCategory}
                        />
                        <Route
                            path="/appMode/selectHardware"
                            component={AppModeSelection}
                        />
                        <Route
                            path="/appMode/selection"
                            component={AppModeSelection}
                        />
                        <Route path="/appMode/remote" component={Remote} />
                        <Route
                            path="/chooseDevice"
                            component={DeviceSelection}
                        />
                        <Route
                            path="/chooseTrainingDevice"
                            component={ChoseTrainingDevice}
                        />
                        <Route path="/train" component={AiTrain} />
                        <Route path="/colorTrain" component={ColorFaceTrain} />
                        <Route path="/faceTrain" component={ColorFaceTrain} />
                        <Route path="/control" component={Control} />
                        <Route
                            path="/selectDetection"
                            component={SelectDetection}
                        />
                        <Route
                            path="/programmingPage"
                            component={ProgrammingPage}
                        />
                        <Route path="/uploadImage" component={UploadImage} />
                        <Route path="/enterName" component={EnterName} />
                        <Route path="/savedFaces" component={SavedFaces} />
                        <Route
                            path="/speechRecognition"
                            component={SpeechRecognitionPage}
                        />
                        <Route path="/playComputer" component={PlayComputer} />
                        <Route
                            path="/faceRecognition"
                            component={TeachableMachine}
                        />
                        <Route
                            path="/emotionDetection"
                            component={ObjectDetection}
                        />
                        <Route
                            path="/objectDetection"
                            component={ObjectDetection}
                        />
                        <Route
                            path={'/graphPlotter'}
                            component={GraphPlotter}
                        />
                        <Route path={'/graphSensor'} component={GraphSensor} />
                        <Route path={'/graphPorts'} component={GraphPorts} />
                        <Route path={'/graphTime'} component={GraphTime} />

                        <Route path={'/lineGraph'} component={LineGraph} />

                        <Route path="/barGraph">
                            {' '}
                            <LineGraph />
                        </Route>

                        <Route path={'/meterGraph'} component={MeterGraph} />
                    </Switch>
                </BrowserRouter>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isConnecting: (data) => {
            dispatch({ type: 'CONNECTING', payload: data })
        },
        sizesHelper: (data) => {
            dispatch({ type: 'sizesHelper', payload: data })
        },
        worker: () => {
            dispatch({ type: 'SERIAL_WORKER' })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
