import './AiColorSlider.css'

function AiColorSlider(props) {
    const {
        min,
        max,
        background,
        value,
        sliderKey,
        onChangeSliderHandel,
        onMouseUpHandel = () => {},
        isTexInputReadOnly = false,
        isDisableSlider = false,
    } = props
    return (
        <div
            className="colorInputDiv"
            style={{ opacity: isDisableSlider ? '0.5' : '1' }}
        >
            <input
                className="colorTrainInputNum"
                type="number"
                value={value}
                onChange={(e) => {
                    onChangeSliderHandel(sliderKey, e.target.value)
                }}
                readOnly={isTexInputReadOnly}
            />

            <div>
                <input
                    style={{ backgroundColor: background }}
                    type="range"
                    class="sliderr"
                    min={min}
                    max={max}
                    value={value}
                    onChange={(e) => {
                        if (isDisableSlider) return
                        onChangeSliderHandel(sliderKey, e.target.value)
                    }}
                    onMouseUp={() => {
                        if (isDisableSlider) return
                        setTimeout(() => {
                            onMouseUpHandel(sliderKey)
                        }, 200)
                    }}
                ></input>
                <div className="colorSliderMinMax">
                    <p style={{ marginBottom: '0px' }}>{min}</p>
                    <p style={{ marginBottom: '0px' }}>{max}</p>
                </div>
            </div>
        </div>
    )
}

export default AiColorSlider
